import { Button } from '@headlessui/react';
import { useEffect, useRef, ChangeEvent, KeyboardEventHandler } from 'react';
import { FiX } from 'react-icons/fi';

const TextArea = ({ 
  name, 
  placeHolder, 
  rows, 
  autoFocus, 
  className = "min-h-[5rem] bg-transparent py-3", 
  autoComplete, 
  onKeyPress, 
  title, 
  onChange, 
  onCancel,
  value, 
  id,
  maxLength = 500,
}: Props) => {
  const adjust: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    e.currentTarget.style.height = "1px";
    e.currentTarget.style.height = (4+e.currentTarget.scrollHeight)+"px";
  };
  const el = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!value && el.current) {
      el.current.style.height = "1px";
    }
  }, [value]);
  return (
    <div className="flex flex-col">
      {!!maxLength && <div className="flex flex-row items-center justify-between mb-4">
        <span className="text-xs bg-blue text-white rounded p-1 flex">{title} <Button type='button' onClick={onCancel}><FiX className='ml-2' /></Button></span>
        <span className="text-xs text-blue">
          {`${value.length ? value.length : 0}/${maxLength}`}
        </span>
      </div>}
      <textarea
        ref={el}
        id={id}
        onChange={onChange}
        maxLength={maxLength && maxLength !== true ? maxLength : undefined}
        value={value}
        name={name}
        rows={rows}
        autoFocus={autoFocus}
        placeholder={placeHolder}
        autoComplete={autoComplete}
        onKeyPress={onKeyPress}
        onKeyUp={adjust}
        className={`
          w-full 
          rounded-lg
          px-4
          outline-none
          resize-none
          ${className}
        `}
      >
        {value}
      </textarea>
    </div>
  );
};

interface Props {
  title?: string;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onCancel?: () => void;
  value: string;
  maxLength?: boolean | number;
  name?: string;
  placeHolder?: string;
  autoComplete?: string;
  className?: string;
  rows?: number;
  autoFocus?: boolean;
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
}

export default TextArea;
