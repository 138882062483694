/* eslint-disable import/named */
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Modal } from '../../../components/molecules/Modal';
import { Controls } from './Controls';

import ReactCrop, { PixelCrop } from 'react-image-crop';
import { Cursor } from './Cursor';
import { BlurCoordinate, BlurredImage, BlurredImageRef } from './BlurredImage';
import { BiUndo } from 'react-icons/bi';
import { Button } from '@headlessui/react';
import { Loader } from '../../atoms/Loader';
import { useUser } from '../../../context/AuthContext';

const getRotatedImage = (image: HTMLImageElement, degrees: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = degrees % 180 === 0 ? image.naturalWidth : image.naturalHeight;
  canvas.height =
    degrees % 180 === 0 ? image.naturalHeight : image.naturalWidth;
  const ctx = canvas.getContext('2d');

  ctx?.translate(canvas.width / 2, canvas.height / 2);
  ctx?.rotate(degrees * (Math.PI / 180));
  ctx?.drawImage(image, image.naturalWidth / -2, image.naturalHeight / -2);

  return canvas.toDataURL();
};

const getCroppedImg = (image: HTMLImageElement, crop: PixelCrop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  const ctx = canvas.getContext('2d');

  // New lines to be added
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;
  ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

  if (ctx) ctx.imageSmoothingQuality = 'high';

  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    canvas.width,
    canvas.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvas.toDataURL();
};

interface Props<T> {
  file: T;
  onClose: () => void;
  onSave: (image: Omit<T, 'src'> & { src: string }) => void;
  getFileBlob: (file: T) => Promise<Blob | null>;
}

type Stage = 'blur' | 'crop' | 'rotate';

const defaultCrop: PixelCrop = {
  unit: 'px',
  width: 30,
  x: 0,
  y: 0,
  height: 30,
};

interface TWIthSrc {
  src?: string;
  originalSrc?: string;
}

export const EditImageModal = <T extends TWIthSrc>({
  file,
  onClose,
  onSave,
  getFileBlob,
}: Props<T>) => {
  const [newImage, setNewImage] = useState<T>(file);
  const prevNewImage = useRef<T>(file);
  useEffect(() => {
    prevNewImage.current = newImage;
  }, [newImage]);
  const [stage, setStage] = useState<Stage | undefined>(undefined);
  const [saving, setSaving] = useState(false);

  const [crop, setCrop] = useState<PixelCrop>(defaultCrop);

  const [rotation, setRotation] = useState(0);

  const [blurSize, setBlurSize] = useState(33);
  const [blurDegree, setBlurDegree] = useState(45);
  const [blurCoordinates, setBlurCoordinates] = useState<BlurCoordinate[]>([]);
  const [lastCoord, setLastCoord] = useState<BlurCoordinate | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setNewImage(() => ({
      ...file,
      src: file.src ?? '',
      originalSrc: file.src ?? '',
    }));
    if (!file.src?.includes('data:')) {
      setSaving(true);
      void getFileBlob(file).then((blob) => {
        if (blob) {
          setNewImage((f) => ({
            ...f,
            src: URL.createObjectURL(blob),
            originalSrc: URL.createObjectURL(blob),
          }));
        }
        setSaving(false);
      });
    }
  }, [file, getFileBlob]);

  const imgStyles = useMemo(
    () => ({
      transform: `rotate(${rotation}deg)`,
    }),
    [rotation]
  );

  const onCrop = useCallback(() => {
    if (stage === 'crop') {
      setStage(undefined);
    } else {
      setStage('crop');
    }
  }, [stage]);

  const onBlur = useCallback(() => {
    if (stage === 'blur') {
      setStage(undefined);
    } else {
      setStage('blur');
    }
  }, [stage]);

  const onReset = useCallback(() => {
    setCrop(defaultCrop);
    setNewImage((newImg) => ({
      ...file,
      src: newImg.originalSrc ?? '',
      originalSrc: newImg.originalSrc ?? '',
    }));
    setBlurSize(33);
    setBlurDegree(45);
    setBlurCoordinates([]);
    setLastCoord(null);
    setStage(undefined);
    setRotation(0);
  }, [file]);

  const {
    setConfirmModalMessage
  } = useUser();

  const onCancel = useCallback(() => {
    if (newImage.src === prevNewImage.current.src) {
      onClose();
    } else {
      setConfirmModalMessage({
        mounted: true,
        content: 'Are you sure you want to discard changes?',
        title: 'Discard changes',
        confirmText: 'Yes',
        declineText: 'No, go back',
        onConfirm: onReset,
      });
    }
  }, [newImage.src, onClose, setConfirmModalMessage, onReset]);

  //Rotation

  const onRotate = useCallback(() => {
    setRotation((r) => r - 90);
    setStage('rotate');
  }, []);

  //Blur

  const onTrash = useCallback(() => {
    setBlurCoordinates([]);
    setLastCoord(null);
    setStage(undefined);
    setBlurSize(33);
    setBlurDegree(45);
  }, []);

  const blurredRef = useRef<BlurredImageRef>(null);

  const onSaveProgress = useCallback(() => {
    setSaving(true);
    if (stage === 'rotate') {
      if (imageRef.current) {
        const src = getRotatedImage(imageRef.current, rotation);
        setNewImage((f) => ({
          ...f,
          src,
        }));
      }
      setRotation(0);
    } else if (stage === 'crop') {
      const image: HTMLImageElement | null = document.querySelector(
        '.ReactCrop__child-wrapper img'
      );
      if (image) {
        const src = getCroppedImg(image, crop);
        setNewImage((f) => ({
          ...f,
          src,
        }));
      }
      setBlurCoordinates([]);
      setLastCoord(null);
    } else if (stage === 'blur') {
      if (blurredRef.current) {
        const src = blurredRef.current.onSave();
        if (src) {
          setNewImage((f) => ({
            ...f,
            src,
          }));
        }
      }
    } else {
      onSave({
        ...newImage,
        src: newImage.src ?? '',
      });
    }
    setStage(undefined);
    setSaving(false);
  }, [stage, newImage, crop, rotation, onSave, blurredRef]);

  return (
    <>
      <Modal
        title="Photo information"
        onClose={onClose}
        onBack={onClose}
        mounted
        width="43.75rem"
      >
        <div className="box-container w-full h-full flex">
          <div className="left w-[70%] h-[34.188rem] bg-darkestGray flex flex-col justify-center rounded-bl-md rounded-tl-none rounded-tr-none rounded-br-none relative py-0 px-[0.938rem]">
            {stage === 'blur' && (
              <div className="blur-controls absolute top-4 left-4 ">
                <div className="flex items-center">
                  <Button
                    onClick={() =>
                      setBlurCoordinates((coords) => {
                        const lastCoords = coords[coords.length - 1];
                        setLastCoord(lastCoords);
                        return [...coords.slice(0, -1)];
                      })
                    }
                    type="button"
                    className="undo text-white w-[0.938rem] h-[0.938rem] cursor-pointer mb-5 mr-3"
                  >
                    <BiUndo size={15} />
                  </Button>
                  <Button
                    onClick={() =>
                      setBlurCoordinates((coords) =>
                        lastCoord ? [...coords, lastCoord] : coords
                      )
                    }
                    type="button"
                    className="undo  text-white w-[0.938rem] h-[0.938rem] cursor-pointer mb-5 flip transform scale-x-[-1]"
                  >
                    <BiUndo size={15} />
                  </Button>
                </div>
              </div>
            )}
            <div className="preview h-[31.25rem] w-[26.25rem] relative overflow-hidden flex justify-center items-center">
              {stage !== 'crop' && stage !== 'blur' && (
                <img
                  className="w-full object-contain select-none"
                  ref={imageRef}
                  src={newImage.src}
                  draggable={false}
                  style={imgStyles}
                  alt="preview"
                />
              )}
              {stage === 'crop' && (
                <div>
                  <ReactCrop crop={crop} onChange={setCrop}>
                    <img src={newImage.src} alt="crop" />
                  </ReactCrop>
                </div>
              )}
              {stage === 'blur' && newImage.src && (
                <>
                  <BlurredImage
                    blurDegree={blurDegree}
                    blurSize={blurSize}
                    blurCoordinates={blurCoordinates}
                    setBlurCoordinates={setBlurCoordinates}
                    ref={blurredRef}
                    src={newImage.src}
                  />
                  <Cursor blurSize={blurSize} blurDegree={blurDegree} />
                </>
              )}
            </div>
            {!!saving && (
              <div className="loader absolute top-0 left-0 w-full h-full bg-black/[0.9] flex justify-center items-center">
                <Loader />
              </div>
            )}
          </div>
          <Controls
            stage={stage}
            onRotate={onRotate}
            onCrop={onCrop}
            onBlur={onBlur}
            onTrash={onTrash}
            setBlurDegree={setBlurDegree}
            blurDegree={blurDegree}
            onCancel={onCancel}
            blurSize={blurSize}
            setBlurSize={setBlurSize}
            onSave={onSaveProgress}
          />
        </div>
      </Modal>
    </>
  );
};
