export type DeepNonNullable<T> = {
  [P in keyof T]: T[P] extends object
    ? DeepNonNullable<T[P]>
    : NonNullable<T[P]>;
};

export type DeepRequired<T> = {
  [P in keyof Required<T>]: Required<T[P]>;
};

export function omitUndefined<T extends Record<string, unknown>>(
  obj: T
): DeepNonNullable<T> {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      result[key] = value;
    }
  }
  return result as DeepNonNullable<T>;
}

export function omitEmpty<T extends Record<string, unknown>>(obj: T): T {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value != null && (Array.isArray(value) ? value.length > 0 : true)) {
      result[key] = value;
    }
  }

  return result as T;
}

