import { createFileRoute } from '@tanstack/react-router';
import { PageLoader } from '../../../components/atoms/PageLoader';
import Messages from '../../../components/organisms/Messages';
import { Suspense } from 'react';

export const Route = createFileRoute('/_authenticated/conversations/')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <Suspense fallback={<PageLoader />}>
        <Messages />
    </Suspense>
  );
}
