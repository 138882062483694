import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ProtectedApp } from './AppShell';
import { PageLoader } from './components/atoms/PageLoader';
import { QueryProvider } from './context/QueryContext';
import { CatchBoundary, redirect } from '@tanstack/react-router';
import { toaster } from './util/toast';
import './styles.css';
import { logout } from './util/request';
import { GqlProvider } from './context/ApolloContext';
import './util/date';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <QueryProvider>
      <Suspense fallback={<PageLoader />}>
        <GqlProvider>
          <CatchBoundary
            getResetKey={() => 'reset'}
            onCatch={(error) => {
              toaster.error(
                {
                  title: 'Error',
                  text: error.message,
                },
                {
                  autoClose: 5000,
                }
              );

              logout();
              
              redirect({
                to: '/login',
              });
            }}
          >
            <ProtectedApp />
          </CatchBoundary>
        </GqlProvider>
      </Suspense>
    </QueryProvider>
  </StrictMode>,
);