import { Button } from '@headlessui/react';
import classNames from 'classnames';

interface DotProps {
  onClick: () => void;
  selected: boolean;
}

const Dot = ({ onClick, selected }: DotProps) => (
  <Button
    type="button"
    className={classNames(
      'dot rounded-full transition-colors  duration-500 ease-in-out  w-2.5  h-2.5 cursor-pointer',
      { 'bg-blue': selected, 'bg-white': !selected }
    )}
    onClick={onClick}
  />
);

interface Props {
  count: number;
  index: number;
  setIndex: (index: number) => void;
}

export const Dots = ({ count, index, setIndex }: Props) => (
  <div className="flex items-center">
    {[...Array(count).keys()].map((i) => (
      <div className="mr-2 last:mr-0" key={i}>
        <Dot selected={i === index} onClick={() => setIndex(i)} />
      </div>
    ))}
  </div>
);
