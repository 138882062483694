import { fetchIndividualThumbnail } from "@board/common";
import { useQuery } from "@tanstack/react-query";
import { useEnvVars } from "./useEnvVars";
import { getBaseToken } from "../context/AuthContext";



export const useUserPhoto = ({userUuid}: {
    userUuid: string;
}) => {
    const {baseApiUrl} = useEnvVars();
    const {data} = useQuery({
        queryKey: ['userPhoto', userUuid],
        queryFn: async () => {
            const res = await fetchIndividualThumbnail([userUuid], {
                baseUrl: baseApiUrl,
                token: getBaseToken(),
            });
            return res?.find(Boolean)?.thumbnail ?? null;
        }
    });
    return data ?? undefined;
};