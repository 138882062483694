import { useEnvVars } from '../../../hooks/useEnvVars';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  description?: string;
  title?: ReactNode;
}

export const DefaultHead = ({ description = '', title = '' }: Props) => {
  const { geoPlacename, geoRegion } = useEnvVars();
  return (
    <Helmet>
      <meta name="geo.region" content={geoRegion} />
      <meta name="geo.placename" content={geoPlacename} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <title>{title} | My BSquared</title>
    </Helmet>
  );
};
