import { useState, memo, useEffect, useMemo } from 'react';

import Message from '../Message';
import TextArea from '../../../../components/atoms/TextArea';

import { GqlConversation, GqlFindConversation, GqlGetConversationActions, GqlIndexConversations, GqlMessage, GqlNextAction, GqlSendMessage } from '@board/resources';
import { useFragment, useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from '@tanstack/react-router';
import { ConversationActionFragmentFragment, ConversationFragmentFragment, getFragmentData, Message as MessageType, RoleEnum } from '@board/graphql';
import { Button } from '@headlessui/react';
import { FiSend } from 'react-icons/fi';
import { Form, Formik, useFormikContext } from 'formik';
import { Loader } from '../../../atoms/Loader';

const threshold = 60 * 60 * 1000; // 60 minutes

function groupMessages(messages: MessageType[]): MessageType[][] {
  const result: MessageType[][] = [];
  let last: MessageType | undefined;

  for (const msg of messages) {
    if (
      last != null &&
      msg.role === last.role &&
      new Date(msg.createdAt).getTime() - new Date(last.createdAt).getTime() <= threshold
    ) {
      last = msg;
      result[result.length - 1] = [...result[result.length - 1], last];
    } else {
      last = msg;
      result.push([last]);
    }
  }

  return result;
}

const MessagesView = memo(
  ({
    messages,
    uuid
  }: {
    messages: MessageType[];
    uuid: string;
  }) => {
    const formik = useFormikContext();
    useEffect(() => {
      if (uuid.startsWith('draft-')) {
        formik.setSubmitting(true);
      } else {
        formik.setSubmitting(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);
    return (
      <>
        {groupMessages(messages).map((group, i, m) => (
          <div key={i}>
            {group.map((message, x) => (
              <>
                {((!i && !x) || (i > 0 && (new Date(m[i][x].createdAt).getTime() - new Date(m[i-1][m[i-1].length-1].createdAt).getTime()) > threshold) || (x > 0 && (new Date(group[x].createdAt).getTime() - new Date(group[x-1].createdAt).getTime()) > threshold)) && <p className="text-center text-xs text-midGray mb-4">
                  {new Date(group[x].createdAt).toNiceDateTimeFormat()}
                </p>}
                <Message
                  message={message}
                  key={message.uuid}
                  first={!x}
                  last={x === group.length - 1}
                />
              </>
            ))}
          </div>
        ))}
      </>
    );
});

interface Props {
  uuid?: string;
}

const ChatView = ({
  uuid,
}: Props) => {
  const data = useFragment({
    from: `Conversation:${uuid}`,
    fragment: GqlConversation,
    fragmentName: 'ConversationFragment'
  });

  const {data: chat} = data;

  const [overflowElm, setOverflowElm] = useState<HTMLElement | null>(null);

  const router = useRouter();

  const messages = useMemo(() => data.complete ? data.data.messages.map(m => getFragmentData(GqlMessage, m)) : [], [data]);

  const [sendDraft] = useMutation(GqlSendMessage, {
    optimisticResponse: (vars) => ({
      sendMessage: {
        __typename: 'Conversation',
        uuid: chat.uuid ?? `draft-${new Date().getTime()}`,
        createdAt: chat.createdAt ?? new Date().toISOString(), 
        updatedAt: new Date().toISOString(),
        deletedAt: chat.deletedAt ?? null,
        title: chat.title ?? vars.input.text,
        messages: [
          ...messages,
          {
            __typename: 'Message',
            uuid: `draft-${new Date().getTime()}`,
            text: vars.input.text,
            role: RoleEnum.user,
            createdAt: new Date().toISOString(),
            toolCallId: null,
            toolCalls: null,
            conversationUuid: chat.uuid ?? '',
          } as MessageType
        ]
      } as ConversationFragmentFragment
    }) 
  });

  const [actions, setActions] = useState<ConversationActionFragmentFragment[]>([]);

  const [isActionsFetching, setIsActionsFetching] = useState(false);

  const [fetchActions] = useLazyQuery(GqlGetConversationActions, {
    onCompleted: (data) => {
      setActions(data.getConversationActions.map(action => getFragmentData(GqlNextAction, action)));
      setIsActionsFetching(false);
    }
  });

  useEffect(() => {
    if (overflowElm) {
      overflowElm.scrollTo(0, overflowElm.scrollHeight);
    }
  }, [chat.messages, actions, overflowElm]);

  const mostRecentMessageUuid = messages.find((m, i) => i === (messages.length - 1))?.uuid ?? null;

  useEffect(() => {
    if (uuid && !uuid.startsWith('draft-') && !mostRecentMessageUuid?.startsWith('draft-')) {
      setIsActionsFetching(true);
      void fetchActions({
        variables: {
          conversationUuid: uuid,
          messageId: mostRecentMessageUuid
        }
      });
    }
  }, [fetchActions, mostRecentMessageUuid, uuid]);

  return (
      <Formik
        initialValues={{ message: '', prefix: '' }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          await sendDraft({
            variables: {
              input: {
                conversationUuid: uuid,
                text: values.prefix ? `${values.prefix} - ${values.message}` : values.message
              }
            },
            onCompleted: (data) => {
              const chat = getFragmentData(GqlConversation, data.sendMessage);
              router.history.push(`/conversations/${chat.uuid}`);
              actions.setSubmitting(false);
            },
            update: (cache, { data }) => {
              if (data?.sendMessage) {
                const chat = data.sendMessage;
                setActions([]);
                cache.writeQuery({
                  query: GqlIndexConversations,
                  data: {
                    indexConversations: {
                      items: [
                        chat,
                        ...cache.readQuery({ query: GqlIndexConversations })?.indexConversations.items.filter(
                          (c) => c.uuid !== chat.uuid
                        ) ?? [],
                      ],
                      pagination: cache.readQuery({ query: GqlIndexConversations })?.indexConversations.pagination ?? {
                        __typename: 'PaginationResponse',
                        total: 1,
                        perPage: 10,
                        currentPage: 1,
                        lastPage: 1,
                        from: 1,
                        to: 1,
                      },
                    },
                  },
                });
                cache.writeQuery({
                  query: GqlFindConversation,
                  data: {
                    findConversation: chat
                  },
                  variables: {
                    uuid: chat.uuid,
                  },
                });
                cache.gc();

                void actions.setFieldValue('message', '');
                void actions.setFieldValue('prefix', '');

                router.history.push(`/conversations/${chat.uuid}`);
              }
            },
          });
        }}
        >
        {(formik) => (
          <div className="flex flex-col grow">
            <div
              className="px-4 grow flex flex-col overflow-auto"
              ref={setOverflowElm}
              style={{ flexBasis: 0 }}
            >
              {data.complete ? (
                <>
                  <div className="flex flex-col items-center justify-center py-8">
                    {data.data.messages.length > 0 && (
                      <p className="text-xs">
                        Beginning of conversation
                      </p>
                    )}
                  </div>

                  <MessagesView messages={messages.filter(m => m.role === RoleEnum.assistant || m.role === RoleEnum.user)} uuid={data.data.uuid} />
                  
                  {formik.isSubmitting && (
                    <div className='flex mb-8'>
                      <Loader size='1.5rem' />
                    </div>
                  )}

                  {isActionsFetching ? (
                    <div className='flex items-center justify-center mb-8'>
                      <Loader size='1.5rem' />
                    </div>
                  ) : !!actions.length && (
                    <div className='flex flex-col items-center mb-8'>
                      <h5 className='text-sm text-blue font-bold mb-2'>Suggested Actions</h5>
                      <div className='flex items-center justify-center flex-wrap -mx-1'>
                        {actions.map((action) => (
                          <div className='px-1' key={action.label} >
                            <Button onClick={() => {
                              if (action.properties.length) {
                                void formik.setFieldValue('prefix', action.label);

                                void formik.setFieldValue('message', '').then(() => document.getElementById('message')?.focus());
                              } else {
                                void formik.setFieldValue('message', action.label).then(() => formik.handleSubmit());
                              }
                            }} type='button' className='rounded-lg text-sm border-blue border bg-lightBlue text-blue px-4 py-2'>
                              {action.label}
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex grow flex-col items-center justify-center text-center">
                  <img src="/images/chat.svg" alt="Chat" />
                  <p className="font-bold text-xl text-blue mt-3 mb-4">
                    Start a conversation
                  </p>
                  <p className="text-sm text-blue">
                    Let's start a conversation, what are you trying to achieve?
                  </p>
                </div>
              )}
            </div>
            <Form className="px-4 py-3 border-t border-gray">
              <TextArea
                value={formik.values.message}
                id="message"
                onChange={(event) => void formik.setFieldValue('message', event.target.value)}
                onCancel={() => {
                  void formik.setFieldValue('prefix', '');
                  void formik.setFieldValue('message', '');
                  document.getElementById('message')?.focus();
                }}
                title={formik.values.prefix}
                maxLength={formik.values.prefix ? 50 : false}
                placeHolder="Type a message..."
                name="message"
                autoFocus
                autoComplete="off"
                rows={1}
                onKeyPress={(e) => {
                  // if enter  then submit form
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    formik.handleSubmit();
                  }
                }}
              />
              <div className="flex">
                <Button
                  type='submit'
                  disabled={!formik.values.message.trim().length || formik.isSubmitting}
                  className="rounded-lg bg-blue text-white flex items-center justify-center py-3 grow disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <FiSend />
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
  );
};

export default ChatView;
