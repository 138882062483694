import { createFileRoute, notFound } from '@tanstack/react-router';
import { GqlReadStudent, GqlStudent } from '@board/resources';
import { buildApolloClient } from '../../../context/ApolloContext';
import { queryClient } from '../../../context/QueryContext';
import { EnvVars } from '../../../util/request';
import { getFragmentData } from '@board/graphql';
import { buildLogError } from '../../../hooks/useLogError';
import CreateOrEditStudent from '../../../components/organisms/CreateOrEditStudent';
import Error404 from '../../../components/atoms/Page404';

const EditStudentComponent = () => {
  const { student } = Route.useLoaderData();
  
    return (
      <CreateOrEditStudent
        title="Edit Student"
        student={student}
        subtitle="Edit the student below"
      />
    );
  };

export const Route = createFileRoute('/_authenticated/students/$uuid')({
notFoundComponent: () => <Error404 to="/students" />,
component: EditStudentComponent,
  loader: async ({ params }) => {
    const envVars = queryClient.getQueryData<EnvVars>(['envVars']);

    // eslint-disable-next-line @typescript-eslint/only-throw-error
    if (!envVars) throw notFound();

    const logError = buildLogError({
        apiUrl: envVars.apiUrl,
        baseApiUrl: envVars.baseApiUrl,
        softwareId: envVars.softwareId
    });

    const apolloClient = buildApolloClient({
        apiUrl: envVars.apiUrl,
        logError
    });

    const { data } = await apolloClient.query({
        query: GqlReadStudent,
        variables: {
            uuid: params.uuid
        }
    });

    const fragment = getFragmentData(GqlStudent, data.findStudent);
    
    if (!fragment.uuid) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw notFound();
    }

    return {
      student: fragment
    };
  }
});