import { createFileRoute, useNavigate } from '@tanstack/react-router';
import CreateOrEditStudent from '../../../components/organisms/CreateOrEditStudent';
import { useCallback } from 'react';

const AddStudentComponent = () => {
  const navigate = useNavigate();

  const onAfterSubmit = useCallback(
    (uuid?: string) => {
      if (uuid) {
        void navigate({
          to: `/students/${uuid}`,
        });
      }
    },
    [navigate],
  );

  return (
    <CreateOrEditStudent
      title="Add Student"
      subtitle="Add the student below"
      onAfterAdd={onAfterSubmit}
    />
  );
};

export const Route = createFileRoute('/_authenticated/students/add')({
  component: AddStudentComponent,
});
