import { Loader } from '../Loader';

interface Props {
  className?: string;
  loaderClassName?: string;
}

export const PageLoader = ({ className, loaderClassName }: Props) => (
  <div
    className={`w-full h-full top-0 left-0 flex items-center justify-center z-20 ${className ?? 'bg-white absolute'}`}
  >
    <Loader className={loaderClassName} />
  </div>
);
