import { Resolver, StudentStatusEnum } from './__generated__/_/graphql-mapped';

export * from './__generated__/_/graphql-mapped';
export * from './__generated__/_/fragment-masking';
export * from './__generated__/_/gql';

export interface BcasUserInterface {
    uuid: string;
    schoolUuid: string;
    programUuid: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    schoolName: string;
    roleUuid: string;
    roleName: string | null;
    title: string | null;
}

export interface JwtJsonRefreshTokenPayloadInterface {
    exp: number;
    data: {
      token: string;
    };
}

export interface JwtJsonAccessTokenPayloadInterface extends JwtJsonRefreshTokenPayloadInterface {
    data: JwtJsonRefreshTokenPayloadInterface['data'] & {
      user: BcasUserInterface;
    };
  }
  

export interface InvocationContextInterface {
    bcasToken?: string;
    user?: BcasUserInterface;
    accessTokenPayload?: JwtJsonAccessTokenPayloadInterface;
}

export type MapperDiffType<T, X> = 
  Omit<T, keyof X> 
  & {
    [K in keyof T & keyof X as 
      Resolver<X[K], X, InvocationContextInterface> extends T[K]
        ? never
        : K
    ]: T[K]
};

export const studentStatuses = [
  {
    value: StudentStatusEnum.active,
    label: 'Active',
  },
  {
    value: StudentStatusEnum.future,
    label: 'Future',
  },
  {
    value: StudentStatusEnum.historic,
    label: 'Historic',
  },
  {
    value: StudentStatusEnum.notInGroup,
    label: 'Not In Group',
  },
  {
    value: StudentStatusEnum.pending,
    label: 'Pending',
  },
];