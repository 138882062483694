import { FormEvent, useCallback, useRef } from 'react';

interface Props {
  setValue: (value: number) => void;
  value: number;
  position?: number;
  valueColor?: string;
  barColor?: string;
  circleColor?: string;
  width?: string;
}

export const Slider = ({
  setValue,
  value,
  position = 40,
  valueColor = 'white',
  barColor = 'white',
  circleColor = 'white',
  width = '4.375rem',
}: Props) => {
  const slider = useRef<HTMLDivElement>(null);

  const updateValue = useCallback(
    (e: Event | FormEvent<HTMLDivElement>) => {
      setValue(
        Math.max(
          0,
          Math.min(
            80,
            Math.round(
              (
                e as unknown as {
                  pageX: number;
                }
              ).pageX - (slider.current?.getBoundingClientRect().left ?? 0)
            )
          )
        )
      );
    },
    [setValue]
  );

  const onMouseUp = () => {
    window.removeEventListener('mousemove', updateValue);
    window.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    window.addEventListener('mousemove', updateValue);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div
      className="slider h-[0.188rem] absolute rounded-[3.125rem] flex items-center"
      style={{ left: `${position}%`, width, backgroundColor: barColor }}
      ref={slider}
    >
      <div
        className="toggle absolute w-[0.938rem] h-[0.938rem] rounded-full bg-white cursor-pointer z-[1]"
        style={{ left: `${value}%`, backgroundColor: circleColor }}
        onChange={updateValue}
        onMouseDown={onMouseDown}
      />
      <div
        style={{ width: `${value}%`, backgroundColor: valueColor }}
        className="bar absolute top-0 left-0 h-full w-full rounded-[3.125rem]"
      />
    </div>
  );
};
