/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n    fragment MessageFragment on Message {\n        uuid\n        text\n        role\n        toolCallId\n        toolCalls\n        conversationUuid\n        createdAt\n    }\n": typeof types.MessageFragmentFragmentDoc,
    "\n    fragment ConversationActionFragment on ConversationAction {\n        label\n        properties {\n            key\n            type\n            required\n        }\n    }\n": typeof types.ConversationActionFragmentFragmentDoc,
    "\n    fragment ConversationFragment on Conversation {\n        uuid\n        title\n        createdAt\n        updatedAt\n        deletedAt\n        messages {\n            ...MessageFragment\n        }\n    }\n": typeof types.ConversationFragmentFragmentDoc,
    "\n    query IndexConversations($input: ConversationFilterInput) {\n        indexConversations(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...ConversationFragment\n            }\n        }\n    }\n": typeof types.IndexConversationsDocument,
    "\n    query FindConversation($uuid: ID!) {\n        findConversation(uuid: $uuid) {\n            ...ConversationFragment\n        }\n    }\n": typeof types.FindConversationDocument,
    "\n    mutation SendMessage($input: SendMessageInput!) {\n        sendMessage(input: $input) {\n            ...ConversationFragment\n        }\n    }\n": typeof types.SendMessageDocument,
    "\n    mutation EditConversation($input: EditConversationInput!) {\n        editConversation(input: $input) {\n            ...ConversationFragment\n        }\n    }\n": typeof types.EditConversationDocument,
    "\n    mutation DeleteConversation($uuid: ID!) {\n        deleteConversation(uuid: $uuid)\n    }\n": typeof types.DeleteConversationDocument,
    "\n    query GetConversationActions($conversationUuid: ID!, $messageId: ID) {\n        getConversationActions(conversationUuid: $conversationUuid, messageId: $messageId) {\n            ...ConversationActionFragment\n        }\n    }\n": typeof types.GetConversationActionsDocument,
    "\n  fragment PaginationFragment on PaginationResponse {\n    total\n    perPage\n    currentPage\n    lastPage\n    from\n    to\n  }\n": typeof types.PaginationFragmentFragmentDoc,
    "\n    fragment StudentFragment on Student {\n        uuid\n        email\n        firstName\n        middleName\n        lastName\n        registrationGroup\n        admissionDate\n        doB\n        evisenseBandId\n        evisenseBandName\n        evisenseLastLogin\n        gender\n        guardians\n        idFields {\n            baseIDName\n            baseIDValue\n            baseIdUid\n            isPrimary\n        }\n        software {\n            hasCSAccess\n            hasCsamAccess\n            hasEviAccess\n            showInCs\n            showInEvi\n        }\n        isSelected\n        suspended\n        upn\n        year\n        analytics\n        deleted\n        deletedDate\n        leftDate\n    }\n": typeof types.StudentFragmentFragmentDoc,
    "\n    query IndexStudents($input: StudentFilterInput) {\n        indexStudents(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...StudentFragment\n            }\n        }\n    }\n": typeof types.IndexStudentsDocument,
    "\n    query ReadStudent($uuid: ID!) {\n        findStudent(uuid: $uuid) {\n            ...StudentFragment\n        }\n    }\n": typeof types.ReadStudentDocument,
    "\n    mutation CreateStudent($input: CreateStudentInput!) {\n        createStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n": typeof types.CreateStudentDocument,
    "\n    mutation EditStudent($input: EditStudentInput!) {\n        editStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n": typeof types.EditStudentDocument,
    "\n    mutation DeleteStudent($uuid: ID!) {\n        deleteStudent(uuid: $uuid)\n    }\n": typeof types.DeleteStudentDocument,
    "\n  fragment User on User {\n    uuid\n    schoolUuid\n    emailAddress\n    firstName\n    lastName\n    schoolName\n    roleUuid\n    roleName\n    title\n  }\n": typeof types.UserFragmentDoc,
    "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      accessToken\n      baseToken\n      refreshToken\n      isForcePasswordChange\n      dueToPolicyChange\n    }\n  }\n": typeof types.LoginDocument,
    "\n  mutation LogError($input: LogErrorInput!) {\n    logError(input: $input)\n  }\n": typeof types.LogErrorDocument,
    "\n  query Me {\n    me {\n      ...User\n    }\n  }\n": typeof types.MeDocument,
};
const documents: Documents = {
    "\n    fragment MessageFragment on Message {\n        uuid\n        text\n        role\n        toolCallId\n        toolCalls\n        conversationUuid\n        createdAt\n    }\n": types.MessageFragmentFragmentDoc,
    "\n    fragment ConversationActionFragment on ConversationAction {\n        label\n        properties {\n            key\n            type\n            required\n        }\n    }\n": types.ConversationActionFragmentFragmentDoc,
    "\n    fragment ConversationFragment on Conversation {\n        uuid\n        title\n        createdAt\n        updatedAt\n        deletedAt\n        messages {\n            ...MessageFragment\n        }\n    }\n": types.ConversationFragmentFragmentDoc,
    "\n    query IndexConversations($input: ConversationFilterInput) {\n        indexConversations(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...ConversationFragment\n            }\n        }\n    }\n": types.IndexConversationsDocument,
    "\n    query FindConversation($uuid: ID!) {\n        findConversation(uuid: $uuid) {\n            ...ConversationFragment\n        }\n    }\n": types.FindConversationDocument,
    "\n    mutation SendMessage($input: SendMessageInput!) {\n        sendMessage(input: $input) {\n            ...ConversationFragment\n        }\n    }\n": types.SendMessageDocument,
    "\n    mutation EditConversation($input: EditConversationInput!) {\n        editConversation(input: $input) {\n            ...ConversationFragment\n        }\n    }\n": types.EditConversationDocument,
    "\n    mutation DeleteConversation($uuid: ID!) {\n        deleteConversation(uuid: $uuid)\n    }\n": types.DeleteConversationDocument,
    "\n    query GetConversationActions($conversationUuid: ID!, $messageId: ID) {\n        getConversationActions(conversationUuid: $conversationUuid, messageId: $messageId) {\n            ...ConversationActionFragment\n        }\n    }\n": types.GetConversationActionsDocument,
    "\n  fragment PaginationFragment on PaginationResponse {\n    total\n    perPage\n    currentPage\n    lastPage\n    from\n    to\n  }\n": types.PaginationFragmentFragmentDoc,
    "\n    fragment StudentFragment on Student {\n        uuid\n        email\n        firstName\n        middleName\n        lastName\n        registrationGroup\n        admissionDate\n        doB\n        evisenseBandId\n        evisenseBandName\n        evisenseLastLogin\n        gender\n        guardians\n        idFields {\n            baseIDName\n            baseIDValue\n            baseIdUid\n            isPrimary\n        }\n        software {\n            hasCSAccess\n            hasCsamAccess\n            hasEviAccess\n            showInCs\n            showInEvi\n        }\n        isSelected\n        suspended\n        upn\n        year\n        analytics\n        deleted\n        deletedDate\n        leftDate\n    }\n": types.StudentFragmentFragmentDoc,
    "\n    query IndexStudents($input: StudentFilterInput) {\n        indexStudents(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...StudentFragment\n            }\n        }\n    }\n": types.IndexStudentsDocument,
    "\n    query ReadStudent($uuid: ID!) {\n        findStudent(uuid: $uuid) {\n            ...StudentFragment\n        }\n    }\n": types.ReadStudentDocument,
    "\n    mutation CreateStudent($input: CreateStudentInput!) {\n        createStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n": types.CreateStudentDocument,
    "\n    mutation EditStudent($input: EditStudentInput!) {\n        editStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n": types.EditStudentDocument,
    "\n    mutation DeleteStudent($uuid: ID!) {\n        deleteStudent(uuid: $uuid)\n    }\n": types.DeleteStudentDocument,
    "\n  fragment User on User {\n    uuid\n    schoolUuid\n    emailAddress\n    firstName\n    lastName\n    schoolName\n    roleUuid\n    roleName\n    title\n  }\n": types.UserFragmentDoc,
    "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      accessToken\n      baseToken\n      refreshToken\n      isForcePasswordChange\n      dueToPolicyChange\n    }\n  }\n": types.LoginDocument,
    "\n  mutation LogError($input: LogErrorInput!) {\n    logError(input: $input)\n  }\n": types.LogErrorDocument,
    "\n  query Me {\n    me {\n      ...User\n    }\n  }\n": types.MeDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment MessageFragment on Message {\n        uuid\n        text\n        role\n        toolCallId\n        toolCalls\n        conversationUuid\n        createdAt\n    }\n"): (typeof documents)["\n    fragment MessageFragment on Message {\n        uuid\n        text\n        role\n        toolCallId\n        toolCalls\n        conversationUuid\n        createdAt\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment ConversationActionFragment on ConversationAction {\n        label\n        properties {\n            key\n            type\n            required\n        }\n    }\n"): (typeof documents)["\n    fragment ConversationActionFragment on ConversationAction {\n        label\n        properties {\n            key\n            type\n            required\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment ConversationFragment on Conversation {\n        uuid\n        title\n        createdAt\n        updatedAt\n        deletedAt\n        messages {\n            ...MessageFragment\n        }\n    }\n"): (typeof documents)["\n    fragment ConversationFragment on Conversation {\n        uuid\n        title\n        createdAt\n        updatedAt\n        deletedAt\n        messages {\n            ...MessageFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query IndexConversations($input: ConversationFilterInput) {\n        indexConversations(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...ConversationFragment\n            }\n        }\n    }\n"): (typeof documents)["\n    query IndexConversations($input: ConversationFilterInput) {\n        indexConversations(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...ConversationFragment\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FindConversation($uuid: ID!) {\n        findConversation(uuid: $uuid) {\n            ...ConversationFragment\n        }\n    }\n"): (typeof documents)["\n    query FindConversation($uuid: ID!) {\n        findConversation(uuid: $uuid) {\n            ...ConversationFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SendMessage($input: SendMessageInput!) {\n        sendMessage(input: $input) {\n            ...ConversationFragment\n        }\n    }\n"): (typeof documents)["\n    mutation SendMessage($input: SendMessageInput!) {\n        sendMessage(input: $input) {\n            ...ConversationFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation EditConversation($input: EditConversationInput!) {\n        editConversation(input: $input) {\n            ...ConversationFragment\n        }\n    }\n"): (typeof documents)["\n    mutation EditConversation($input: EditConversationInput!) {\n        editConversation(input: $input) {\n            ...ConversationFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteConversation($uuid: ID!) {\n        deleteConversation(uuid: $uuid)\n    }\n"): (typeof documents)["\n    mutation DeleteConversation($uuid: ID!) {\n        deleteConversation(uuid: $uuid)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetConversationActions($conversationUuid: ID!, $messageId: ID) {\n        getConversationActions(conversationUuid: $conversationUuid, messageId: $messageId) {\n            ...ConversationActionFragment\n        }\n    }\n"): (typeof documents)["\n    query GetConversationActions($conversationUuid: ID!, $messageId: ID) {\n        getConversationActions(conversationUuid: $conversationUuid, messageId: $messageId) {\n            ...ConversationActionFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PaginationFragment on PaginationResponse {\n    total\n    perPage\n    currentPage\n    lastPage\n    from\n    to\n  }\n"): (typeof documents)["\n  fragment PaginationFragment on PaginationResponse {\n    total\n    perPage\n    currentPage\n    lastPage\n    from\n    to\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment StudentFragment on Student {\n        uuid\n        email\n        firstName\n        middleName\n        lastName\n        registrationGroup\n        admissionDate\n        doB\n        evisenseBandId\n        evisenseBandName\n        evisenseLastLogin\n        gender\n        guardians\n        idFields {\n            baseIDName\n            baseIDValue\n            baseIdUid\n            isPrimary\n        }\n        software {\n            hasCSAccess\n            hasCsamAccess\n            hasEviAccess\n            showInCs\n            showInEvi\n        }\n        isSelected\n        suspended\n        upn\n        year\n        analytics\n        deleted\n        deletedDate\n        leftDate\n    }\n"): (typeof documents)["\n    fragment StudentFragment on Student {\n        uuid\n        email\n        firstName\n        middleName\n        lastName\n        registrationGroup\n        admissionDate\n        doB\n        evisenseBandId\n        evisenseBandName\n        evisenseLastLogin\n        gender\n        guardians\n        idFields {\n            baseIDName\n            baseIDValue\n            baseIdUid\n            isPrimary\n        }\n        software {\n            hasCSAccess\n            hasCsamAccess\n            hasEviAccess\n            showInCs\n            showInEvi\n        }\n        isSelected\n        suspended\n        upn\n        year\n        analytics\n        deleted\n        deletedDate\n        leftDate\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query IndexStudents($input: StudentFilterInput) {\n        indexStudents(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...StudentFragment\n            }\n        }\n    }\n"): (typeof documents)["\n    query IndexStudents($input: StudentFilterInput) {\n        indexStudents(input: $input) {\n            pagination {\n                ...PaginationFragment\n            }\n            items {\n                ...StudentFragment\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ReadStudent($uuid: ID!) {\n        findStudent(uuid: $uuid) {\n            ...StudentFragment\n        }\n    }\n"): (typeof documents)["\n    query ReadStudent($uuid: ID!) {\n        findStudent(uuid: $uuid) {\n            ...StudentFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateStudent($input: CreateStudentInput!) {\n        createStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n"): (typeof documents)["\n    mutation CreateStudent($input: CreateStudentInput!) {\n        createStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation EditStudent($input: EditStudentInput!) {\n        editStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n"): (typeof documents)["\n    mutation EditStudent($input: EditStudentInput!) {\n        editStudent(input: $input) {\n            ...StudentFragment\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteStudent($uuid: ID!) {\n        deleteStudent(uuid: $uuid)\n    }\n"): (typeof documents)["\n    mutation DeleteStudent($uuid: ID!) {\n        deleteStudent(uuid: $uuid)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment User on User {\n    uuid\n    schoolUuid\n    emailAddress\n    firstName\n    lastName\n    schoolName\n    roleUuid\n    roleName\n    title\n  }\n"): (typeof documents)["\n  fragment User on User {\n    uuid\n    schoolUuid\n    emailAddress\n    firstName\n    lastName\n    schoolName\n    roleUuid\n    roleName\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      accessToken\n      baseToken\n      refreshToken\n      isForcePasswordChange\n      dueToPolicyChange\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      accessToken\n      baseToken\n      refreshToken\n      isForcePasswordChange\n      dueToPolicyChange\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LogError($input: LogErrorInput!) {\n    logError(input: $input)\n  }\n"): (typeof documents)["\n  mutation LogError($input: LogErrorInput!) {\n    logError(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Me {\n    me {\n      ...User\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      ...User\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;