import Parser from 'rss-parser';

const parser = new Parser();

export interface NewsItemType {
  id: string;
  title: string;
  isoDate: string;
  summary: string;
  link: string;
}

export const fetchRssFeed = async (
  url: string,
  limit = 10
): Promise<NewsItemType[]> => {
  try {
    const feeds = (await parser.parseURL(url)).items;
    if (limit) {
      return feeds.slice(0, limit) as NewsItemType[];
    }
    return feeds as NewsItemType[];
  } catch (err) {
    console.log(err);
    return [];
  }
};
