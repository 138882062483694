import { Modal } from '../Modal';

export interface ConfirmModalProps {
  mounted: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  onDecline?: () => void;
  declineText?: string;
  title?: string;
  content: string;
}

export const ConfirmModal = ({
  mounted,
  onConfirm,
  confirmText = 'Yes',
  onDecline,
  declineText = 'Cancel',
  title,
  content,
}: ConfirmModalProps) => {
  const contentSplitByNewLine = content.split('\n');
  return (
    <Modal
      title={title}
      mounted={mounted}
      onNegative={onDecline}
      negativeButton={declineText}
      negativeButtonWidth="7rem"
      onPositive={onConfirm}
      positiveButton={confirmText}
      footerBorder="0"
    >
      <div className="py-4 px-6 text-sm">
        {contentSplitByNewLine.map((line, index) => (
          <p key={index} className="mb-2">
            {line}
          </p>
        ))}
      </div>
    </Modal>
  );
};
