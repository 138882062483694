import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import { ThemeConfig, ResolvableTo } from 'tailwindcss/types/config.js';

type UnwrapResolvables<T> = {
  [K in keyof T]: T[K] extends ResolvableTo<infer R> ? R : T[K];
};

type Theme = UnwrapResolvables<
  Partial<ThemeConfig & { extend: Partial<ThemeConfig> }> | undefined
>;

export const fullConfig: { theme: Theme } = resolveConfig(tailwindConfig);

const multiIndex = (obj: Theme, properties: string[]): string => {
  const [property] = properties;
  if (properties.length === 1) return (obj ? obj[property] : '') as string;
  return multiIndex(
    (obj ? obj[property] : {}) as Record<string, unknown>,
    properties.slice(1)
  );
};

export const theme = (propertiesTrack: string): string => {
  const properties = propertiesTrack.split('.');
  return multiIndex(fullConfig.theme, properties);
};

export const getColor = (color: string): string => theme(`colors.${color}`);

export const elements = {
  button: {
    primary:
      'transition-all duration-300 bg-blue border-blue font-medium text-white border px-2 rounded hover:bg-darkBlue flex justify-center items-center h-11',
    secondary:
      'transition-all duration-300 border-2 px-2 border-blue rounded text-blue font-medium h-11 flex items-center justify-center hover:bg-blue/10',
    tertiary:
      'transition-all duration-300 border py-2 px-2 border-lightGray rounded bg-white text-dark font-medium flex items-center justify-center h-11 hover:bg-black/5',
    disabled:
      'transition-all duration-300 text-midGray px-2 rounded font-medium h-11 flex items-center justify-center bg-gray',
  },
  input:
    'border text-sm border-lightGray h-11 flex items-center rounded-md outline-none focus:border-dark focus:bg-dark/5 min-w-12 max-w-full mb-4',
  inputLabel: 'text-sm font-semibold mb-2 block',
};
