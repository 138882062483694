/* eslint-disable import/named */
import { IconType } from 'react-icons';
import { Field } from 'formik';
import { useState } from 'react';
import classNames from 'classnames';
import { elements } from '../../../util/styles';

export interface InputProps {
  label?: string;
  id?: string;
  Icon?: IconType;
  type?: string;
  required?: boolean;
  name: string;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number | string;
  defaultValue?: number | string;
  autoFocus?: boolean;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  pattern?: RegExp;
  useFormik?: boolean;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  invalidPatternMessage?: string;
  iconToEdge?: boolean;
  disabled?: boolean;
}

export const TextInput = ({
  label,
  id,
  Icon,
  type = 'string',
  max,
  name,
  required,
  disabled,
  onBlur,
  onChange,
  onInput,
  onKeyDown,
  value,
  defaultValue,
  autoFocus,
  placeholder,
  className,
  containerClassName,
  useFormik,
  pattern,
  min,
  step,
  invalidPatternMessage,
  iconToEdge,
}: InputProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={containerClassName}>
      {!!label && (
        <label className={elements.inputLabel} htmlFor={id ?? name}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          elements.input,
          'overflow-hidden',
          {
            'pl-3': !!Icon && !iconToEdge,
            'border-dark': focused,
            'bg-dark/5': focused,
          },
          className
        )}
      >
        {!!Icon && <Icon size={24} className="mr-2" />}
        {useFormik ? (
          <Field
            type={type}
            className={classNames(
              'h-11 w-full outline-none rounded-md bg-transparent pr-3',
              {
                'pl-3': !Icon,
              }
            )}
            name={name}
            id={id ?? name}
            required={required}
            onInput={onInput ?? onChange}
            autoFocus={!!autoFocus || focused}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              onBlur?.();
            }}
            onKeyDown={onKeyDown}
            min={min}
            step={step}
            pattern={pattern?.source}
            max={max}
          />
        ) : (
          <input
            type={type}
            className={classNames(
              'h-11 w-full outline-none rounded-md bg-transparent pr-3',
              {
                'pl-3': !Icon,
              }
            )}
            name={name}
            id={id ?? name}
            required={required}
            onInput={(e) => {
              if (invalidPatternMessage) {
                e.currentTarget.setCustomValidity('');
              }
              if (onInput) {
                onInput(e);
              }
            }}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              onBlur?.();
            }}
            pattern={pattern?.source}
            onInvalid={
              invalidPatternMessage
                ? (e) =>
                    e.currentTarget.setCustomValidity(invalidPatternMessage)
                : undefined
            }
            onKeyDown={onKeyDown}
            min={min}
            step={step}
            max={max}
          />
        )}
      </div>
    </div>
  );
};