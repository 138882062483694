import {
  Modal,
  ModalBody,
} from '../../../../../../molecules/Modal';
import { TextInput } from '../../../../../../atoms/TextInput';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { UserAvatar } from '../../../../../../molecules/UserAvatar';
import { EditImageModal } from '../../../../../../organisms/EditImageModal';
import { PasswordInput } from '../../../../../../molecules/PasswordInput';
import { FiPlus } from 'react-icons/fi';
import { getBaseToken, useUser } from '../../../../../../../context/AuthContext';
import { useMutation } from '@tanstack/react-query';
import { addUserPhoto, setUserDetails, SetUserDetailsParams } from '@board/common';
import { toaster } from '../../../../../../../util/toast';
import { prepareBase64, prepareFile } from '../../../../../../../util/request';
import { useUserPhoto } from '../../../../../../../hooks/useUserPhoto';
import { useEnvVars } from '../../../../../../../hooks/useEnvVars';

interface ChangePassProps {
  onClose: () => void;
  mounted: boolean;
}

// TODO: Implement Formik, use it with <Form> (pass as={Form} to Modal) component and pass useFormik as a prop to TextInput and PasswordInput and other inputs you find within Components map above.
export const EditProfileModal = ({ onClose, mounted }: ChangePassProps) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {user} = useUser();

  const photo = useUserPhoto({ userUuid: user.uuid });

  const [title, setTitle] = useState(user.title ?? '');
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName ?? '');
  const [newEmail, setNewEmail] = useState(user.emailAddress);
  const [avatar, setAvatar] = useState(photo);

  const resetForm = useCallback(() => {
    setCurrentPassword('');
    setConfirmPassword('');
  }, [setCurrentPassword, setConfirmPassword]);

  const {baseApiUrl} = useEnvVars();

  const setUserDetailsMutation = useMutation({
    mutationFn: async (args: SetUserDetailsParams) => setUserDetails(args, {
      baseUrl: baseApiUrl,
      token: getBaseToken(),
    })
  });

  const onPositive = useCallback(async () => {
    const userDetails = await setUserDetailsMutation.mutateAsync({
      email: newEmail,
      password: currentPassword,
      firstName,
      lastName,
      title,
    });
    if (userDetails.incorrectPasswordError) {
      toaster.error({
        title: 'Error',
        text: 'Old password is incorrect',
      }, {
        autoClose: 5000,
      });
    } else if (userDetails.newEmailInUseError) {
      toaster.error({
        title: 'Error',
        text: 'New email is already in use',
      }, {
        autoClose: 5000,
      });
    } else if (userDetails.newEmailInvalidError) {
      toaster.error({
        title: 'Error',
        text: 'New email is invalid',
      }, {
        autoClose: 5000,
      });
    } else if (userDetails.adminNotAllowedToChangeEmailError) {
      toaster.error({
        title: 'Error',
        text: 'Admin is not allowed to change email',
      }, {
        autoClose: 5000,
      });
    } else {
      onClose();
      resetForm();
    }
  }, [
    newEmail,
    currentPassword,
    firstName,
    lastName,
    title,
    onClose,
    resetForm,
    setUserDetailsMutation
  ]);

  const [fileBase64, setFileBase64] = useState<string | undefined>();

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newFile = Object.values(event.target.files as FileList);
    void prepareFile(newFile[0]).then((file) => {
      setFileBase64(file);
    });
  }, []);

  const getFileBlob = useCallback(
    async (file: { src: string | undefined }) =>
      file.src ? prepareBase64(file.src) : null,
    []
  );

  const file = useMemo(() => ({ src: fileBase64 }), [fileBase64]);

  const addUserPhotoMutation = useMutation({
    mutationFn: async (args: string) => addUserPhoto(args, {
      baseUrl: baseApiUrl,
      token: getBaseToken(),
    })
  });

  const onSaveEditedAvatar = useCallback(
    async (f: { src: string }) => {
      setFileBase64(undefined);
      const avatarWithoutPrefix = f.src.includes('data:image')
        ? f.src.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
        : f.src;
      await addUserPhotoMutation.mutateAsync(avatarWithoutPrefix);
      setAvatar(f.src);
    },
    [addUserPhotoMutation]
  );

  return (
    <Modal
      mounted={mounted}
      title="Edit Profile"
      positiveButton="Save changes"
      onClose={onClose}
      onNegative={onClose}
      onPositive={onPositive}
      negativeButton="Cancel"
      positiveButtonDisabled={
        !currentPassword ||
        !confirmPassword ||
        confirmPassword !== currentPassword
      }
    >
      <ModalBody padding>
        <div className="form flex w-full flex-col">
          <div className="flex items-center justify-between mb-4 relative">
            <div className="flex items-center justify-center">
              <UserAvatar
                size="3rem"
                src={avatar}
                name={`${firstName} ${lastName}`}
              />
              <div className="flex flex-col ml-4">
                <span className="text-sm">
                  {firstName} {lastName}
                </span>
                <span className="text-xs text-bGray">
                  Your profile photo will be visible to all users
                </span>
              </div>
            </div>
            <div className="actions h-10 rounded bg-lightBlue relative flex justify-between items-center px-3 w-40">
              <div className="flex items-center justify-center text-acceptGreen w-4 h-4">
                <FiPlus size={16} />
              </div>
              <span className="text-[0.8125rem] text-dGreen">
                Upload new photo
              </span>
            </div>
            <input
              onChange={onInputChange}
              id="upload-image"
              name="upload-image"
              className="w-full h-full opacity-0 cursor-pointer absolute top-0 right-0"
              type="file"
              accept="image/png, image/jpeg"
            />
          </div>

          <TextInput
            id="title"
            label="Title"
            name="title"
            placeholder="-"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />

          <TextInput
            id="firstName"
            label="First Name"
            name="firstName"
            placeholder="-"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />

          <TextInput
            id="lastName"
            label="Last Name"
            name="lastName"
            placeholder="-"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />

          <TextInput
            id="email"
            label="E-mail"
            name="email"
            type="email"
            placeholder="-"
            onChange={(e) => setNewEmail(e.target.value)}
            value={newEmail}
          />

          <PasswordInput
            id="newPass"
            name="newPass"
            placeholder="-"
            onChange={(e) => setCurrentPassword(e.target.value)}
            value={currentPassword}
            label="Enter your password"
          />

          <div className="mb-2">
            <PasswordInput
              id="newPass2"
              name="newPass2"
              placeholder="-"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              label="Re-enter your password"
            />
          </div>
        </div>
      </ModalBody>
      {!!fileBase64 && (
        <EditImageModal<typeof file>
          file={file}
          onClose={() => {
            setFileBase64(undefined);
          }}
          onSave={onSaveEditedAvatar}
          getFileBlob={getFileBlob}
        />
      )}
    </Modal>
  );
};
