import { Button } from '@headlessui/react';
import classNames from 'classnames';
import { ReactElement, useMemo } from 'react';

interface Props {
  id: string;
  label: string;
  onClick?: () => void;
  width?: string;
  enabled?: boolean;
  disabled?: boolean;
}

export const ToggleSwitch = ({
  id,
  onClick,
  width = '50',
  enabled,
  disabled,
  label
}: Props): ReactElement => {
  const toggleIndiccatorSize = 22;
  const trackPadding = 2;
  const trackHeight = toggleIndiccatorSize + trackPadding * 2;

  const translate = useMemo(
    () =>
      enabled ? Number(width) - toggleIndiccatorSize - trackPadding * 2 : 0,
    [enabled, width, toggleIndiccatorSize, trackPadding]
  );

  return (
    <div className="flex items-center">
      <label className="text-sm font-semibold block mr-3">
        {label}
      </label>
    <label
      className={`toggle items-center rounded-[12.5rem] flex ${disabled ? 'opacity-20' : ''}`}
      htmlFor={id}
    >
      <Button
        type="button"
        onClick={!disabled ? onClick : undefined}
        className={classNames(
          'track rounded-[100px] cursor-pointer flex relative items-center',
          {
            'bg-success': enabled,
            'bg-lightGray': !enabled,
          }
        )}
        style={{
          width: `${width}px`,
          height: `${trackHeight}px`,
          padding: `${trackPadding}px`,
        }}
      >
        <span
          className="indicator group-peer-checked:bg-white items-center bg-white rounded-full flex justify-center outline outline-2 outline-transparent absolute transition-all duration-300 ease-in-out"
          style={{
            width: `${toggleIndiccatorSize}px`,
            height: `${toggleIndiccatorSize}px`,
            transform: `translateX(${translate}px)`,
          }}
        />
      </Button>
    </label>
    </div>
  );
};
