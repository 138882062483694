import { FiChevronRight } from 'react-icons/fi';

interface NewsItemType {
  title?: string;
  isoDate?: string;
  content?: string;
  link?: string;
}

interface Props {
  item: NewsItemType;
}

export const NewsItem = ({ item }: Props) => {
  const { title, content, isoDate, link } = item;
  return (
    <div className="item mb-8 last:mb-4">
      {!!title && (
        <h4
          className="text-base mx-0 mt-0 mb-2.5 "
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {!!content && (
        <div className="content mb-[0.938rem]">
          <span className="text-sm text-darkGray">{content}</span>
        </div>
      )}
      <div className="footer flex justify-between mb-[1.813rem]">
        {!!isoDate && (
          <span className="text-xs text-darkGray">
            {new Date(isoDate).toNiceFormat()}
          </span>
        )}
        {!!link && (
          <a
            target="_blank"
            className="text-sm leading-[1.36] text-blue no-underline"
            href={link}
          >
            Read more <FiChevronRight size={20} className="inline" />
          </a>
        )}
      </div>
    </div>
  );
};
