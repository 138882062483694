import { Option, Settings } from './Settings';
import { useCallback, useState } from 'react';
import { ChangePassModal } from './ChangePassModal';
import { EditProfileModal } from './Settings/Modals/EditProfileModal';
import { NewsModal } from './Settings/Modals/NewsModal';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { useUser } from '../../../../context/AuthContext';
import { UserAvatar } from '../../../molecules/UserAvatar';
import { useUserPhoto } from '../../../../hooks/useUserPhoto';

interface Props {
  newsFeedUrl: string;
}

export const AvatarItem = ({ newsFeedUrl }: Props) => {
  const [displayChangePassModal, setDisplayChangePassModal] = useState(false);
  const closePasswordModal = useCallback(() => {
    setDisplayChangePassModal(false);
  }, []);
  const {user} = useUser();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined
  );
  const closeModal = () => {
    setSelectedOption(undefined);
  };
  const photo = useUserPhoto({ userUuid: user.uuid });
  return (
    <div>
      <Popover className="relative">
        {({close}) => (
          <>
          <PopoverButton>
            <UserAvatar src={photo} name={[user.firstName, user.lastName].filter(Boolean).join(' ')} size="2.875rem" />
          </PopoverButton>
          <PopoverPanel
            transition
            anchor={{
              to: 'right',
              padding: 30,
            }}
            className="divide-y divide-white/5 rounded bg-white shadow-lg transition duration-200 ease-in-out  data-[closed]:-translate-y-1 data-[closed]:opacity-0 z-20"
          >
            <Settings setSelectedOption={setSelectedOption} close={close} />
          </PopoverPanel>
          </>
        )}
      </Popover>
      <ChangePassModal
        mounted={displayChangePassModal}
        onClose={closePasswordModal}
      />
      <EditProfileModal
        mounted={selectedOption === 'profile'}
        onClose={closeModal}
      />

      <NewsModal
        display={selectedOption === 'news'}
        onClose={closeModal}
        newsFeedUrl={newsFeedUrl}
      />
    </div>
  );
};
