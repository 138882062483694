import { TfiSave } from 'react-icons/tfi';
import FloatingButton from '../FloatingButton';

interface Props {
  showIfNotDirty?: boolean;
  message?: string;
  afterSubmit?: () => void;
}

const SubmitButton = ({ showIfNotDirty, message, afterSubmit }: Props) => (
  <FloatingButton
    showIfNotDirty={showIfNotDirty}
    afterSubmit={afterSubmit}
    message={message}
  >
    <TfiSave size={24} />
  </FloatingButton>
);

export default SubmitButton;
