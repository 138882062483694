import { AvatarItem } from './AvatarItem';
import { Tip } from '../../atoms/Tip';

import classNames from 'classnames';

import { ReactElement, Suspense, useCallback, useState } from 'react';
import { FiFile, FiHelpCircle, FiHome, FiMessageCircle, FiUsers } from 'react-icons/fi';
import { Loader } from '../../atoms/Loader';
import { Button, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ContactModal } from './ContactModal';
import { Link, useRouterState } from '@tanstack/react-router';
import { useEnvVars } from '../../../hooks/useEnvVars';
import { BsquaredIcon } from '../../atoms/BsquaredIcon';

export const SideNav = (): ReactElement => {
  const router = useRouterState();

  const {
    supportUrl,
    newsFeedUrl,
  } = useEnvVars();

    const [displayContactModal, setDisplayContactModal] = useState(false);

    const openContactModal = () => {
      setDisplayContactModal(true);
    };
  
    const closeContactModal = useCallback(() => { 
      setDisplayContactModal(false);
    }, []);

  return (
    <>
    <aside className="flex flex-col justify-between items-center bg-blue w-20 pt-6 px-0 pb-8 z-10">
      <div className="top w-full flex flex-col justify-center">
        <div className="logo mb-3 flex justify-center">
          <BsquaredIcon size={40} />
        </div>
        <ul className="menu list-none m-0 p-0 w-full">
          <li
            className={classNames(
              'w-full flex justify-center relative my-5 mx-0 py-2.5 px-0',
              {
                'active bg-[rgba(255,255,255,0.11)] relative before:bg-white before:w-1 before:absolute before:left-0 before:top-0 before:h-full before:rounded-tr before:rounded-br before:content-[""]':
                router.location.pathname === '/'
              }
            )}
          >
            <Link to="/" className="text-white">
              <FiHome size={24} strokeWidth={1} />
            </Link>
          </li>

          <li
            className={classNames(
              'flex items-center relative w-full justify-center my-5 mx-0 py-2.5 px-0',
              {
                'active bg-[rgba(255,255,255,0.11)] relative before:bg-white before:w-1 before:absolute before:left-0 before:top-0 before:h-full before:rounded-tr before:rounded-br before:content-[""]':
                router.location.pathname.startsWith('/students')
              }
            )}
            data-tooltip-id="view-students"
          >
            <Link
              to="/students"
              className="text-white"
            >
              <FiUsers size={24} strokeWidth={1} />
            </Link>
            <Tip text="View Students" id="view-students" />
          </li>

          <li
            className={classNames(
              'flex items-center relative w-full justify-center my-5 mx-0 py-2.5 px-0',
              {
                'active bg-[rgba(255,255,255,0.11)] relative before:bg-white before:w-1 before:absolute before:left-0 before:top-0 before:h-full before:rounded-tr before:rounded-br before:content-[""]':
                router.location.pathname.startsWith('/conversations')
              }
            )}
            data-tooltip-id="view-insights"
          >
            <Link
              to="/conversations"
              className="text-white"
            >
              <FiMessageCircle size={24} strokeWidth={1} />
            </Link>
            <Tip text="View Insights" id="view-insights" />
          </li>
          
        </ul>
      </div>
      <ul className="menu list-none m-0 p-0 w-full last">
        <li className="item relative w-full flex justify-center my-1 mx-0 py-2.5 px-0 group/nav-item">
          <Popover>
            <PopoverButton className="icon text-lightGray flex justify-center w-full">
              <FiHelpCircle size={24} strokeWidth={1.5} />
            </PopoverButton>
            <PopoverPanel
              transition
              anchor={{
                to: 'right end',
                gap: '20px',
              }}
              className="divide-y divide-white/5 rounded-xl bg-white text-sm/6 transition duration-200 ease-in-out data-[closed]:-translate-y-1 data-[closed]:opacity-0 z-10 shadow-lg py-[1.125rem] px-2.5 w-52"
            >
              <h5 className="m-0 mb-4 text-sm">Help</h5>
              <div>
                <div>
                  <a
                    href={supportUrl}
                    target="_blank"
                    className="flex items-center w-full p-2.5 text-black hover:text-blue"
                  >
                    <FiFile size={20} />
                    <span className="ml-2">Knowledge Base</span>
                  </a>
                </div>
                <div>
                  <Button
                    type="button"
                    className="flex items-center w-full p-2.5 text-black hover:text-blue"
                    onClick={openContactModal}
                  >
                    <FiMessageCircle size={20} />
                    <span className="ml-2">Contact Support</span>
                  </Button>
                </div>
              </div>
            </PopoverPanel>
          </Popover>
        </li>
        <li className="w-full flex justify-center relative mt-5 mx-0 px-0">
          <Suspense fallback={<Loader className="text-white" />}>
            <AvatarItem newsFeedUrl={newsFeedUrl} />
          </Suspense>
        </li>
      </ul>
    </aside>
    <ContactModal
        mounted={displayContactModal}
        onClose={closeContactModal}
      />
      </>
  );
};
