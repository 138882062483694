/* eslint-disable import/named */
import { ApolloClient, createHttpLink } from "@apollo/client";
import { useEnvVars } from "./useEnvVars";
import { cache } from "../util/cache";
import { fetchSessionInfo } from "@board/common";
import { GqlLogErrorMutation } from "@board/resources";
import { ErrorLevel } from "@board/graphql";
import jsCookie from "js-cookie";
import { GraphQLFormattedError } from "graphql";
import { useMemo } from "react";

export const buildLogError = ({
    apiUrl,
    baseApiUrl,
    softwareId
}: {
    apiUrl: string;
    baseApiUrl: string;
    softwareId: number;
}) => {
    const unAuthClient = new ApolloClient({
        link: createHttpLink({
          uri: `${apiUrl}/api/graphql`,
        }),
        cache,
    });
    const logError = (error: Error | GraphQLFormattedError) =>
        void fetchSessionInfo({
            baseUrl: baseApiUrl,
            token: jsCookie.get('x-base-token') ?? '',
        }).then((sessionInfo) => void unAuthClient.mutate({
            mutation: GqlLogErrorMutation,
            errorPolicy: 'none',
            variables: {
                input: {
                softwareId,
                message: error.message,
                errorLevel: ErrorLevel.fatal,
                stack: error instanceof Error ? error.stack : undefined,
                screen: window.location.pathname,
                schoolUuid: sessionInfo.schoolUID,
                userUuid: sessionInfo.userUID,
                },
            }
        })).catch((e) => console.error(e));
    return logError;
};

export const useLogError = () => {
    const {
        apiUrl,
        softwareId,
        baseApiUrl
    } = useEnvVars();

    const logError = useMemo(() => buildLogError({
        apiUrl,
        softwareId,
        baseApiUrl
    }), [apiUrl, softwareId, baseApiUrl]);
    
    return logError;
};