/* eslint-disable import/named */
import classNames from 'classnames';
import { getColor } from '../../../../util/styles';
import { IconType } from 'react-icons';

const colors = {
  A: 'orange',
  B: 'orange',
  C: 'green',
  D: 'green',
  E: 'yellow',
  F: 'yellow',
  G: 'turquoise',
  H: 'turquoise',
  I: 'brown',
  J: 'brown',
  K: 'blue',
  L: 'blue',
  M: 'lime',
  N: 'lime',
  O: 'marine',
  P: 'marine',
  Q: 'canary',
  R: 'canary',
  S: 'cactus',
  T: 'cactus',
  U: 'pink',
  V: 'pink',
  W: 'mandarine',
  X: 'mandarine',
  Y: 'sky',
  Z: 'sky',
  0: 'sky',
  1: 'sky',
  2: 'sky',
  3: 'sky',
  4: 'sky',
  5: 'sky',
  6: 'sky',
  7: 'sky',
  8: 'sky',
  9: 'sky',
};

type ColorKey = keyof typeof colors;

const getBackgroundColor = (c: ColorKey) => {
  const color = colors[c];

  return getColor(color);
};

interface Props {
  size?: string;
  label: string;
  onClick?: () => void;
  Icon?: IconType;
  bgColor?: string;
  white?: boolean;
}

export const AvatarWithLabel = ({
  size = '3rem',
  label,
  onClick,
  Icon,
  bgColor,
  white,
}: Props) => (
  <div
    onClick={onClick}
    style={{
      backgroundColor:
        bgColor ??
        (label.length > 0
          ? getBackgroundColor(label[0].toUpperCase() as ColorKey)
          : undefined),
      width: size,
      height: size,
    }}
    className={classNames(
      'shrink-0 flex justify-center items-center rounded-full',
      {
        'cursor-pointer relative': !!onClick,
        'border border-white': white,
      }
    )}
  >
    <div className="wrapper flex flex-col justify-center items-center">
      {!!Icon && (
        <div className="icon text-white">
          <Icon />
        </div>
      )}
      <span
        className="text-white text-xs"
        style={{ fontSize: `calc( ${size} * 0.4 )` }}
      >
        {label}
      </span>
    </div>
  </div>
);
