import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    if (responseObject.uuid)
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      return `${responseObject.__typename}:${String(responseObject.uuid)}`;

    return defaultDataIdFromObject(responseObject);
  },
});
