interface Props {
  img?: string;
  text?: string;
  hideText?: boolean;
}

export const EmptyState = ({
  img = '',
  text = '',
  hideText = false,
}: Props) => (
  <div className="flex flex-col items-center justify-center text-center mx-auto max-w-[30rem]">
    {img && <img src={img} alt={text} className="mb-4 max-w-sm" />}
    {!hideText && <p className="text-lg text-dark font-medium">{text}</p>}
  </div>
);
