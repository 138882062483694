import { Modal, ModalBody } from '../../../../molecules/Modal';
import { TextInput } from '../../../../atoms/TextInput';
import { useCallback, useEffect, useState } from 'react';
import { Tip } from '../../../../atoms/Tip';
import { useMutation } from '@tanstack/react-query';
import { setUserPassword, SetUserPasswordParams } from '@board/common';
import { getBaseToken, useUser } from '../../../../../context/AuthContext';
import { toaster } from '../../../../../util/toast';
import { useEnvVars } from '../../../../../hooks/useEnvVars';

interface ChangePassProps {
  onClose: () => void;
  mounted: boolean;
}

const calcStrength = (password: string) => {
  let level = 0;
  level += password.length >= 12 ? 1 : 0;
  level +=
    /[!@#$%^&*?_~]{2,}/.test(password) || /[0-9]{2,}/.test(password) ? 1 : 0;
  level += /[a-z]{2,}/.test(password) ? 1 : 0;
  level += /[A-Z]{2,}/.test(password) ? 1 : 0;
  return level;
};

export const ChangePassModal = ({ onClose, mounted }: ChangePassProps) => {

  const {user} = useUser();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [complete, setComplete] = useState(false);
  const [forceMount, setForceMount] = useState<{dueToPolicy: boolean} | undefined>();

  const resetForm = useCallback(() => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }, []);

  useEffect(() => {
    const forcePasswordChange = localStorage.getItem('force-password-change');
    if (forcePasswordChange) {
      setForceMount(JSON.parse(forcePasswordChange) as {dueToPolicy: boolean});
    }
  }, [mounted]);

  const {baseApiUrl} = useEnvVars();

  const setUserPasswordMutation = useMutation({
    mutationFn: async (args: SetUserPasswordParams) => setUserPassword(args, {
      baseUrl: baseApiUrl,
      token: getBaseToken(),
    })
  });

  const onPositive = useCallback(async () => {
    const userPassword = await setUserPasswordMutation.mutateAsync({
      email: user.emailAddress,
      newPassword,
      password: currentPassword,
    });
    if (userPassword.hasChangeEmailFailed) {
      toaster.error({
        title: 'Failed to change email',
        text: 'Please try again later',
      }, {
        autoClose: 5000,
      });
    } else if (userPassword.hasChangePasswordFailed) {
      toaster.error({
        title: 'Failed to change password',
        text: 'Please try again later',
      }, {
        autoClose: 5000,
      });
    } else if (!userPassword.isOldPasswordCorrect) {
      toaster.error({
        title: 'Failed to change password',
        text: 'Old password is incorrect',
      }, {
        autoClose: 5000,
      });
    } else if (!userPassword.isNewPasswordFormatValid) {
      toaster.error({
        title: 'Failed to change password',
        text: 'New password is invalid',
      }, {
        autoClose: 5000,
      });
    } else if (userPassword.isNewEmailInUse) {
      toaster.error({
        title: 'Failed to change email',
        text: 'New email is already in use',
      }, {
        autoClose: 5000,
      });
    } else if (!userPassword.isNewEmailFormatValid) {
      toaster.error({
        title: 'Failed to change email',
        text: 'New email is invalid',
      }, {
        autoClose: 5000,
      });
    } else if (userPassword.isAdminAndNotAllowedToChangeEmail) {
      toaster.error({
        title: 'Failed to change email',
        text: 'Admin is not allowed to change email',
      }, {
        autoClose: 5000,
      });
    } else {
      setComplete(true);
      setForceMount(undefined);
      onClose();
      resetForm();
    }
  }, [
    currentPassword,
    user.emailAddress,
    newPassword,
    onClose,
    resetForm,
    setUserPasswordMutation,
  ]);

  const title = complete
    ? 'You have updated your password'
    : forceMount
      ? 'Please change your password'
      : 'Set a new password';

  const subtitle = complete
    ? null
    : forceMount
      ? forceMount.dueToPolicy
        ? 'You must change your password due to a password policy'
        : 'Before connecting to my bsquared you must create a unique password'
      : 'Choose a unique password to protect your account';

  const score = calcStrength(newPassword);

  const words = ['Weak', 'Weak', 'Okay', 'Good', 'Strong'];

  const strength = words[score];

  return (
    <Modal
      mounted={mounted || !!forceMount}
      title={title}
      positiveButton="Save changes"
      onClose={!forceMount ? onClose : undefined}
      onNegative={!forceMount ? onClose : undefined}
      onPositive={onPositive}
      negativeButton={!forceMount ? 'Cancel' : undefined}
      positiveButtonDisabled={
        !currentPassword ||
        !newPassword ||
        !confirmPassword ||
        newPassword.length < 12 ||
        confirmPassword !== newPassword
      }
    >
      <ModalBody padding>
        <div className="form flex w-full flex-col">
          <h4 className="leading-6 mx-0 mt-0 mb-1.5">{title}</h4>
          <p className="mt-0 mx-0 mb-2 leading-[1.2rem]">{subtitle}</p>
          <TextInput
            id="currentPass"
            name="currentPass"
            placeholder="-"
            onChange={(e) => setCurrentPassword(e.target.value)}
            value={currentPassword}
            label="Enter your current password"
          />

          <div
            className="flex items-center"
            data-tooltip-id="password-strength"
          >
            <div className="grow">
              <TextInput
                id="newPass"
                name="newPass"
                placeholder="-"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                label="Enter a new password"
              />
            </div>

            <Tip id="password-strength">
              <div className="instruction">
                <p>
                  <strong>Password Strength:</strong> {strength}
                </p>
                <div className="strength my-2 mx-0 w-full relative before:absolute before:top-0 before:left-0 before:w-full before:rounded before:h-2 before:bg-white before:content-['']">
                  <div
                    className="indicator rounded h-2 bg-blue relative transition-all duration-500 transition-[ease]"
                    style={{ width: `${score * 25}%` }}
                  />
                </div>
                <p>Use at least 12 characters</p>
                <p>Include at least 1 lowercase character</p>
                <p>Include at least 1 uppercase character</p>
                <p>Include at least 1 number or special character</p>
                <p>Password is case sensitive</p>
              </div>
            </Tip>
          </div>

          <TextInput
            id="newPass2"
            name="newPass2"
            placeholder="-"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            label="Re-enter new password"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
