const { join } = require('path');

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    join(
      __dirname,
      '{src,pages,components,app}/**/*!(*.stories|*.spec).{ts,tsx,html}'
    )
  ],
  theme: {
    extend: {
      colors: {
        midBlue: '#d0e0ef',
        blue: '#0249b3',
        darkBlue: '#003f9c',
        lightBlue: '#f0f8ff',
        white: '#ffffff',
        offWhite: '#f5f5f5',
        black: '#000000',
        gray: '#f5f5f5',
        midGray: '#a9a9a9',
        lightGray: '#e5e5e5',
        success: '#4dc672',
        red: '#ff0000',
      }
    },
  },
  plugins: [],
};
