/* eslint-disable import/named */
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { FiTrash, FiUsers } from 'react-icons/fi';
import { Link, useNavigate } from '@tanstack/react-router';
import { Tooltip } from 'react-tooltip';
import { CreateStudentInput, getFragmentData, StudentFragmentFragment } from '@board/graphql';
import { useUser } from '../../../context/AuthContext';
import { GqlCreateStudent, GqlDeleteStudent, GqlEditStudent, GqlReadStudent, GqlStudent } from '@board/resources';
import { toaster } from '../../../util/toast';
import { elements } from '../../../util/styles';
import { To } from '../../atoms/Page404';
import { IconType } from 'react-icons';
import PageHeader from '../../atoms/PageHeader';
import { Loader } from '../../atoms/Loader';
import { Button } from '@headlessui/react';
import { TextInput } from '../../atoms/TextInput';
import { PageLoader } from '../../atoms/PageLoader';
import SubmitButton from '../../atoms/SubmitButton';
import { DeepRequired } from '../../../util/deepNonNullable';
import { EmailInput } from '../Login/EmailInput';
import { ToggleSwitch } from '../../atoms/ToggleSwitch';

export type Account = Omit<StudentFragmentFragment, 'uuid'> & {
  uuid?: string;
};

interface Props {
  title: string;
  subtitle: string;
  buttonLink?: To;
  buttonText?: string;
  student?: StudentFragmentFragment;
  ButtonIcon?: IconType;
  onAfterAdd?: (uuid: string) => void;
}

type FormikValues = DeepRequired<CreateStudentInput>;

const CreateOrEditStudent = ({
  title,
  subtitle,
  buttonLink,
  buttonText = 'View More',
  ButtonIcon,
  student,
  onAfterAdd,
}: Props) => {
  const { user } = useUser();
  const canDelete = user.roleUuid === 'a6abac14-767a-4dea-8060-42ae2f8c2319';


  const navigate = useNavigate();
  const [deleteStudent, { loading: deleteLoading }] = useMutation(
    GqlDeleteStudent,
    {
      update: (cache) => {
        cache.evict({ id: `Student:${student?.uuid}` });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'indexStudents',
        });

        cache.gc();
      },
      onCompleted: () => {
        toaster.success(
          {
            title: 'Student deleted',
            text: 'The student has been deleted',
          },
          { autoClose: 2000 }
        );

        void navigate({
          to: '/students',
        });
      },
    }
  );

  const [addStudent, { loading: addLoading }] = useMutation(GqlCreateStudent, {
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'indexStudents',
      });

      cache.gc();
    },
  });

  const [editStudent, { loading: editLoading }] = useMutation(
    GqlEditStudent,
    {
      update: (cache, data) => {
        if (data.data?.editStudent) {
          cache.writeQuery({
            query: GqlReadStudent,
            data: {
              findStudent: data.data.editStudent,
            },
            variables: {
              uuid: data.data.editStudent.uuid,
            },
          });
        }
      },
    }
  );

  const loading = addLoading || editLoading;
  const initialValues = useMemo<FormikValues>(
    () => ({
        admissionDate: student?.admissionDate ?? '',
        doB: student?.doB ?? '',
        firstName: student?.firstName ?? '',
        gender: student?.gender ?? '',
        isDeleted: false,
        lastName: student?.lastName ?? '',
        pendingInvite: false,
        protected: false,
        registrationGroupDetails: {
            groupUID: '',
            joinDate: '',
            leftDate: null
        },
        wasLastActiveInCurrentSchool: false,
        year: student?.year ?? '',
        curriculumType: 0,
        email: student?.email ?? '',
        evisenseBandId: student?.evisenseBandId ?? '',
        evisenseBandName: student?.evisenseBandName ?? '',
        evisenseLastLogin: student?.evisenseLastLogin ?? '',
        guardians: 0,
        idFields: student?.idFields ?? [],
        isSelected: false,
        leavingDate: null,
        middleName: student?.middleName ?? '',
        nextYearGroupDetails: {
            groupUID: '',
            joinDate: '',
            leftDate: null
        },
        registrationGroup: student?.registrationGroup ?? '',
        software: {
          hasCSAccess: false,
          hasEviAccess: false,
          hasCsamAccess: false,
          showInCS: false,
          showInEvi: false,
        },
        upn: student?.upn ?? '',
        suspended: false,
    }),
    [student]
  );

  const studentUuid = student?.uuid;

  return (
    <main className="flex flex-col flex-grow p-5 relative overflow-y-auto bg-offWhite">
      <div className="flex flex-col">
        <PageHeader title={title} Icon={FiUsers}>
          <div className="flex flex-wrap items-center -mx-1">
            {!!studentUuid &&
              canDelete &&
              (deleteLoading ? (
                <Loader size="1.5rem" />
              ) : (
                <div className="px-1 mb-2 lg:mb-0">
                  <Button
                    type="button"
                    className={elements.button.tertiary}
                    data-tooltip-id="deleteStudent"
                    data-tooltip-content="Delete Student"
                    data-tooltip-place="bottom"
                    onClick={async () => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this student?'
                        )
                      )
                        await deleteStudent({
                          variables: {
                            uuid: studentUuid,
                          },
                        });
                    }}
                  >
                    <FiTrash size={20} />
                  </Button>
                  <Tooltip id="deleteStudent" />
                </div>
              ))}
            {!!buttonLink && (
              <div className="px-1 mb-2 lg:mb-0">
                <Link
                  to={buttonLink}
                  className={elements.button.tertiary}
                  data-tooltip-id={buttonLink}
                  data-tooltip-content={buttonText}
                  data-tooltip-place="bottom"
                >
                  {!!ButtonIcon && <ButtonIcon size={20} />}
                </Link>
                <Tooltip id={buttonLink} />
              </div>
            )}
          </div>
        </PageHeader>
        <p className="mb-5 text-sm">{subtitle}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, props) => {
            const data: CreateStudentInput = {
              ...values
            };

            if (!student?.uuid) {
              await addStudent({
                variables: {
                  input: data,
                },
                onCompleted: (d) => {
                  const s = getFragmentData(GqlStudent, d.createStudent);
                  onAfterAdd?.(s.uuid);
                },
              });

              props.resetForm();
            } else {
              await editStudent({
                variables: {
                  input: {
                    individualUID: student.uuid,
                    ...data,
                  },
                },
              });

              props.resetForm({
                values,
              });
            }

            toaster.success(
              {
                title: 'Success',
                text: 'Student details have been updated',
              },
              {
                autoClose: 5000,
              }
            );
          }}
        >
          <Form className="relative">
            <div className="bg-white rounded-md mb-5">
              <h2 className="font-bold text-lg text-black px-5 py-3 border-b border-lightGray">
                Personal Information
              </h2>
              <div className="flex flex-wrap -mx-2 items-center mb-5 px-5 pt-5 pb-2">
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <TextInput
                    name="firstName"
                    required
                    label="First Name"
                    placeholder="First Name"
                    id="firstName"
                    useFormik
                  />
                </div>
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    id="lastName"
                    useFormik
                  />
                </div>
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <EmailInput
                    name="email"
                    required
                    label="Email Address"
                    placeholder="Email Address"
                    id="email"
                    useFormik
                  />
                </div>
                <div className="w-full lg:w-1/2 px-2 mb-5">
                  <TextInput
                    name="upn"
                    required
                    label="UPN"
                    placeholder="UPN"
                    id="upn"
                    useFormik
                  />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md mb-5">
              <h2 className="font-bold text-lg text-black px-5 py-3 border-b border-lightGray">
                Software Access
              </h2>
              <div className="flex flex-wrap -mx-2 items-center mb-5 px-5 pt-5 pb-2">
                <div className="w-full lg:w-1/4 px-2 mb-5">
                  <Field name="software.hasCSAccess">
                    {(formik: FieldProps<FormikValues>) => (
                      <ToggleSwitch
                        id="hasCSAccess"
                        label='CS Access'
                        enabled={!!formik.field.value}
                        onClick={() => void formik.form.setFieldValue(formik.field.name, !formik.field.value)}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full lg:w-1/4 px-2 mb-5">
                  <Field name="software.hasEviAccess">
                    {(formik: FieldProps<FormikValues>) => (
                      <ToggleSwitch
                        id="hasEviAccess"
                        label='Evisense Access'
                        enabled={!!formik.field.value}
                        onClick={() => void formik.form.setFieldValue(formik.field.name, !formik.field.value)}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full lg:w-1/4 px-2 mb-5">
                  <Field name="software.hasCsamAccess">
                    {(formik: FieldProps<FormikValues>) => (
                      <ToggleSwitch
                        id="hasCsamAccess"
                        label='CSAM Access'
                        enabled={!!formik.field.value}
                        onClick={() => void formik.form.setFieldValue(formik.field.name, !formik.field.value)}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full lg:w-1/4 px-2 mb-5">
                  <Field name="software.showInCS">
                    {(formik: FieldProps<FormikValues>) => (
                      <ToggleSwitch
                        id="showInCS"
                        label='Show in CS'
                        enabled={!!formik.field.value}
                        onClick={() => void formik.form.setFieldValue(formik.field.name, !formik.field.value)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <SubmitButton />
            {loading && <PageLoader />}
          </Form>
        </Formik>
      </div>
    </main>
  );
};

export default CreateOrEditStudent;
