import { gql } from '@board/graphql';

export const GqlStudent = gql(`
    fragment StudentFragment on Student {
        uuid
        email
        firstName
        middleName
        lastName
        registrationGroup
        admissionDate
        doB
        evisenseBandId
        evisenseBandName
        evisenseLastLogin
        gender
        guardians
        idFields {
            baseIDName
            baseIDValue
            baseIdUid
            isPrimary
        }
        software {
            hasCSAccess
            hasCsamAccess
            hasEviAccess
            showInCs
            showInEvi
        }
        isSelected
        suspended
        upn
        year
        analytics
        deleted
        deletedDate
        leftDate
    }
`);


export const GqlIndexStudents = gql(`
    query IndexStudents($input: StudentFilterInput) {
        indexStudents(input: $input) {
            pagination {
                ...PaginationFragment
            }
            items {
                ...StudentFragment
            }
        }
    }
`);

export const GqlReadStudent = gql(`
    query ReadStudent($uuid: ID!) {
        findStudent(uuid: $uuid) {
            ...StudentFragment
        }
    }
`);

export const GqlCreateStudent = gql(`
    mutation CreateStudent($input: CreateStudentInput!) {
        createStudent(input: $input) {
            ...StudentFragment
        }
    }
`);

export const GqlEditStudent = gql(`
    mutation EditStudent($input: EditStudentInput!) {
        editStudent(input: $input) {
            ...StudentFragment
        }
    }
`);

export const GqlDeleteStudent = gql(`
    mutation DeleteStudent($uuid: ID!) {
        deleteStudent(uuid: $uuid)
    }
`);