import classNames from 'classnames';

import { useState } from 'react';
import { TextInput } from '../../../components/atoms/TextInput';
import { FiEye } from 'react-icons/fi';
import { Button } from '@headlessui/react';
import { elements } from '../../../util/styles';

interface Props {
  disabled?: boolean;
  id: string;
  label: string;
  name: string;
  onBlur?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  value?: string;
  preview?: boolean;
  forgottenLink?: string | undefined;
}

// TODO: add useFormik boolean as a prop like we do on Analytics project
const PasswordInput = ({
  disabled = false,
  id,
  label,
  name,
  onBlur,
  onChange,
  placeholder = 'Password',
  required = false,
  preview = false,
  value,
  forgottenLink = '',
}: Props) => {
  const [type, setType] = useState('password');

  const toggleType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <div>
      <div className="header inline-flex justify-between items-center w-full">
        <label htmlFor={id} className={elements.inputLabel}>
          {label}
        </label>
        {forgottenLink && (
          <a
            className="link leading-[0.875rem] text-xs no-underline transition-colors duration-500 ease hover:text-blue focus:text-blue focus:underline"
            href={forgottenLink}
            target="_blank"
          >
            Forgot password?
          </a>
        )}
      </div>
      <TextInput
        type="password"
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value ?? ''}
      />
      {!!preview && (
        <Button
          type="button"
          className={classNames(
            'preview cursor-pointer absolute bottom-3 right-4 transition-colors duration-500 ease text-lightGray',
            {
              'text-blue': type === 'text',
            }
          )}
          onClick={toggleType}
        >
          <FiEye size={14} />
        </Button>
      )}
    </div>
  );
};

export { PasswordInput };
