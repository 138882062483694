export const SecurityInformation = () => (
  <div>
    <h3 className="text-base">Public/Shared Computer</h3>
    <p className="text-[0.8125rem]">
      If you are logging into this site from a machine or account that is not
      secured to you personally, please choose this option as it will set the
      auto logout of the site to 20 minutes. This is a security precaution to
      help you limit unauthorised access to your information.
    </p>
    <h3 className="text-base">Private Computer</h3>
    <p className="text-[0.8125rem]">
      If you are logging in from your own machine, you can select the Private
      Computer option. This will increase the timeout to 60 minutes. Please be
      aware that if you leave your computer unattended at any point, it is good
      security practice to lock your computer to prevent unauthorised access.
    </p>
    <p className="text-[0.8125rem]">
      Please be aware it is your responsibility to ensure that the once you have
      logged in, you ensure that the system is not accessed by anyone not
      authorised to do so. The automatic logout is there to help protect you and
      your sensitive data.
    </p>
    <small>*timeouts count down from the last click on our site</small>
    <br />
    <br />
  </div>
);
