import { CatchBoundary, createFileRoute, Outlet } from '@tanstack/react-router';
import { Sidebar } from '../components/organisms/Login';
import { useEnvVars } from '../hooks/useEnvVars';
import Error404 from '../components/atoms/Page404';
import { useLogError } from '../hooks/useLogError';

export const Route = createFileRoute('/_unauthenticated')({
  component: LayoutComponent,
});

function LayoutComponent() {
  const {
    loginFeedUrl,
    privacyPolicyUrl,
    termsUrl
  } = useEnvVars();
  const logError = useLogError();
  return (
    <CatchBoundary
      onCatch={logError}
      getResetKey={() => 'unauthenticated'}
      errorComponent={() => <Error404 message='An error occurred' to='/' />}
    >
      <div className="loginGrid flex h-[100dvh] overflow-hidden">
        <Sidebar loginFeedUrl={loginFeedUrl} />
        <div className="items-center flex justify-center grow flex-col">
          <div className="inner flex flex-col grow w-full justify-center items-center">
            <Outlet />
          </div>
          <footer className="justify-self-end py-4 px-0">
            <ul className="my-0 -mx-2 p-0 list-none flex ">
              <li className="py-0 px-2 text-xs">
                <a
                  className="text-blue"
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="py-0 px-2 text-xs">
                <a
                  className="text-blue"
                  href={termsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    </CatchBoundary>
  );
}
