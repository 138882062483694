import { memo, useEffect, useState } from 'react';
import { Message as MessageType, RoleEnum } from '@board/graphql';
import Markdown from 'react-markdown';
import { Tooltip } from 'react-tooltip';

interface Props {
  readonly message: MessageType;
  readonly first: boolean;
  readonly last: boolean;
}

const Message = memo(
  ({ message, first, last }: Props) => {

    const isMyMessage = message.role === RoleEnum.user;

    const [since, setSince] = useState(new Date(message.createdAt).since());

    useEffect(() => {
      const interval = setInterval(() => {
        setSince(new Date(message.createdAt).since());
      }, 10000);

      return () => clearInterval(interval);
    }, [message.createdAt]);

    return (
      <time
        className={`flex mb-8 relative ${isMyMessage ? 'justify-end' : 'justify-start'}`}
        dateTime={new Date(message.createdAt).toISOString()}
      >
        <div className='relative'
          data-tooltip-id={`message-time-${message.uuid}`}
          data-tooltip-content={since}
        >
          <Markdown
            className={`${
              isMyMessage
                ? `bg-blue text-white border border-gray rounded-l-lg ${
                    first ? 'rounded-tr-lg' : ''
                  } ${last ? 'rounded-br-lg' : ''}`
                : `bg-lightBlue text-blue rounded-r-lg ${first ? 'rounded-tl-lg' : ''} ${
                    last ? 'rounded-bl-lg' : ''
                  }`
            } 
            text-sm 
            px-4 
            py-2.5 
            [&_*]:mb-2 
            [&_*:last-child]:mb-0 
            [&_p]:mb-4 
            [&_p:last-child]:mb-0 
            [&_ul]:list-disc 
            [&_ol]:list-decimal 
            [&_ul]:pl-4 
            [&_ol]:pl-4 
            [&_ul]:mb-4
            [&_ol]:mb-4
            [&_hr]:my-4 
            [&_hr]:text-midBlue
            [&_a]:underline
            [&_h1]:text-2xl
            [&_h2]:text-xl
            [&_h3]:text-lg
            [&_h4]:text-base
            [&_h5]:text-sm
            [&_h6]:text-xs
            [&_h1]:font-black
            [&_h2]:font-bold
            [&_h3]:font-semibold
            [&_h4]:font-semibold
            [&_h1]:mb-4
            [&_h2]:mb-4
            [&_h3]:mb-4
            [&_h4]:mb-4
            [&_h5]:mb-4
          `}
          >
            {message.text}
          </Markdown>
          <Tooltip id={`message-time-${message.uuid}`} />
        </div>
      </time>
    );
  }
);

export default Message;
