import { ReactElement } from 'react';

import { MdOutlinePrivacyTip } from 'react-icons/md';
import {
  FiInfo,
  FiLogOut,
  FiPenTool,
} from 'react-icons/fi';
import { LuClipboardList } from 'react-icons/lu';
import { GrLock } from 'react-icons/gr';
import { Link } from '@tanstack/react-router';
import { logout } from '../../../../../util/request';
import { useUser } from '../../../../../context/AuthContext';
import { useEnvVars } from '../../../../../hooks/useEnvVars';
import { UserAvatar } from '../../../../../components/molecules/UserAvatar';
import { useUserPhoto } from '../../../../../hooks/useUserPhoto';

export type Option =
  | 'logout'
  | 'news'
  | 'password'
  | 'profile';

export const Settings = ({
  setSelectedOption,
  close
}: {
  setSelectedOption: (option: Option) => void;
  close: () => void;
}): ReactElement => {
  const { privacyPolicyUrl, termsUrl } = useEnvVars();

  const { user } = useUser();

  const photo = useUserPhoto({ userUuid: user.uuid });

  return (
    <div className="settings bottom-0 left-0 my-0 mr-0 bg-white rounded h-fit w-[29.375rem] z-[1] shadow-[0_0_10px_rgba(0,0,0,0.25)]">
      <div className="header h-15 border-b border-b-gray flex items-center p-5 justify-between box-border">
        <img className="logo" src="/images/logo.svg" alt="my bsquared logo" />
      </div>

      <ul className="list list-none py-5 px-3 m-0 w-full flex flex-wrap box-border">
        <li className="item py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue text-black hover:text-blue rounded">
          <a
            href={privacyPolicyUrl}
            target="_blank"
            className="flex items-center"
            onClick={close}
          >
            <span className="icon mr-[0.8rem] flex">
              <MdOutlinePrivacyTip size={18} />
            </span>
            <span>Privacy Policy</span>
          </a>
        </li>
        <li
          className="item flex items-center text-black hover:text-blue py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue rounded"
          onClick={() => {
            setSelectedOption('password');
            close();
          }}
        >
          <span className="icon mr-[0.8rem] flex">
            <GrLock size={18} />
          </span>
          <span>Change Password</span>
        </li>
        <li className="item py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue text-black hover:text-blue rounded">
          <a href={termsUrl} target="_blank" className="flex items-center" onClick={close}>
            <span className="icon mr-[0.8rem] flex">
              <LuClipboardList size={18} />
            </span>
            <span>Terms of Use</span>
          </a>
        </li>
        <li
          className="item py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue hover:text-blue rounded flex items-center text-black"
          onClick={() => {
            setSelectedOption('profile');
            close();
          }}
        >
          <span className="icon mr-[0.8rem] flex">
            <FiPenTool size={18} />
          </span>
          <span>Edit Profile</span>
        </li>
        <li
          className="item py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue hover:text-blue rounded flex items-center text-black"
          onClick={() => {
            setSelectedOption('news');
            close();
          }}
        >
          <span className="icon mr-[0.8rem] flex">
            <FiInfo size={18} />
          </span>
          <span>News</span>
        </li>
        <li className="item py-2.5 px-3 w-1/2 text-[0.825rem] mb-2 box-border cursor-pointer hover:bg-lightBlue hover:text-blue text-black rounded">
          <Link
            to="/login"
            className="flex items-center"
            onClick={(e) => {
              e.preventDefault();
              logout();
              close();
            }}
          >
            <span className="icon mr-[0.8rem] flex">
              <FiLogOut size={18} />
            </span>
            <span>Logout</span>
          </Link>
        </li>
      </ul>

      <div className="footer flex justify-between w-[calc(100%-4rem] border-t border-solid border-t-gray h-[6.25rem] w-full pt-6 pr-6 pb-5 pl-5 items-center box-border">
        <div className="flex items-center">
          <div className="image mr-4">
            <UserAvatar src={photo} name={[user.firstName, user.lastName].filter(Boolean).join(' ')} size="2.875rem" />
          </div>
          <div className="info">
            <p className="p-0 m-0 text-blue text-sm leading-[1.4rem] font-medium">
              Hello, {user.firstName}
            </p>
            <p className="p-0 m-0 text-black text-xs leading-[1.4rem]">
              {user.roleName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
