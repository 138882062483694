import { Slider } from '../../../atoms/Slider';
import classNames from 'classnames';
import { elements, getColor } from '../../../../util/styles';
import {
  FiCrop,
  FiEdit,
  FiMinus,
  FiPlus,
  FiRotateCw,
  FiTrash,
} from 'react-icons/fi';
import { Button, Button as HButton } from '@headlessui/react';

interface Props {
  onRotate: () => void;
  onCrop: () => void;
  onBlur: () => void;
  stage?: string;
  onTrash: () => void;
  onCancel: () => void;
  blurSize: number;
  onSave: () => void;
  setBlurDegree: (value: number) => void;
  blurDegree: number;
  setBlurSize: (value: number) => void;
}

export const Controls = ({
  onRotate,
  onCrop,
  onBlur,
  stage,
  onTrash,
  onCancel,
  blurSize,
  onSave,
  setBlurDegree,
  blurDegree,
  setBlurSize,
}: Props) => (
  <div className="right flex flex-col justify-between grow p-2.5 bg-white rounded-br-md">
    <div className="actions px-5">
      <HButton
        type="button"
        onClick={onRotate}
        className={classNames(
          'action flex items-center cursor-pointer mb-2.5 rounded p-[0.313rem] realtive opacity-100 transition-opacity duration-500 ease text-sm text-black',
          {
            'opacity-50 pointer-events-none': !!stage && stage !== 'rotate',
          }
        )}
      >
        <div className="box-container w-[1.875rem] h-[1.875rem] rounded-full bg-lightBlue flex items-center justify-center mr-2.5">
          <div className="icon mb-0.5 text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem] mab-0.5">
            <FiRotateCw size={18} />
          </div>
        </div>
        <span className="py-1 px-0 grow flex ">Rotate</span>
      </HButton>
      <HButton
        type="button"
        onClick={onCrop}
        className={classNames(
          'action flex items-center cursor-pointer mb-2.5 rounded p-[0.313rem] realtive opacity-100 transition-opacity duration-500 ease text-sm text-black',
          {
            'bg-lightBlue': stage === 'crop',
            'opacity-50 pointer-events-none': !!stage && stage !== 'crop',
          }
        )}
      >
        <div
          className={classNames(
            'box-container w-[1.875rem] h-[1.875rem] rounded-full bg-lightBlue flex items-center justify-center mr-2.5',
            {
              'bg-blue': stage === 'crop',
            }
          )}
        >
          <div
            className={classNames(
              'icon text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem] mr-0.5',
              {
                'text-white': stage === 'crop',
              }
            )}
          >
            <FiCrop size={18} />
          </div>
        </div>
        <span className="py-1 px-0 grow flex">Crop</span>
      </HButton>
      <div
        className={classNames(
          'action flex items-center cursor-pointer mb-2.5 rounded p-[0.313rem] realtive opacity-100 transition-opacity duration-500 ease text-sm text-black',
          {
            'bg-lightBlue': stage === 'blur',
            'opacity-50 pointer-events-none': !!stage && stage !== 'blur',
          }
        )}
      >
        <HButton
          type="button"
          onClick={onBlur}
          className={classNames(
            'box-container w-[1.875rem] h-[1.875rem] rounded-full bg-lightBlue flex items-center justify-center mr-2.5',
            {
              'bg-blue': stage === 'blur',
            }
          )}
        >
          <div
            className={classNames(
              'icon text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem]',
              {
                'text-white': stage === 'blur',
              }
            )}
          >
            <FiEdit size={18} />
          </div>
        </HButton>
        <HButton type="button" className="py-1 px-0 grow" onClick={onBlur}>
          Blur
        </HButton>
        {stage === 'blur' && (
          <HButton
            type="button"
            className="trash absolute right-[0.625rem] text-lightBlue"
            onClick={onTrash}
          >
            <div className="icon text-blue flex items-center justify-center w-[0.875rem] h-[0.875rem] small">
              <FiTrash size={14} />
            </div>
          </HButton>
        )}
      </div>
      {stage === 'blur' && (
        <div className="blur-action h-[6.25rem] flex flex-col justify-between pb5">
          <div className="blur-option mb-4">
            <span className="text-xs py-1 px-0 grow flex">Blur degree</span>
            <div className="flex items-center">
              <div className="slider flex items-center grow justify-between mt-[0.625ren] mx-[0.625ren] mb-0 relative">
                <div className="button w-[1.563rem] h-[1.563rem] rounded-full border-[0.063rem] solid border-lightBlue flex justify-center items-center text-blue p-1">
                  <HButton
                    type="button"
                    onClick={() => setBlurDegree(blurDegree - 1)}
                    className="icon mr-0.5 text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem]"
                  >
                    <FiMinus size={18} />
                  </HButton>
                </div>
                <Slider
                  setValue={setBlurDegree}
                  position={20}
                  barColor={getColor('gray')}
                  valueColor={getColor('blue')}
                  circleColor={getColor('blue')}
                  width="105px"
                  value={blurDegree}
                />
                <div className="button w-[1.563rem] h-[1.563rem] rounded-full border-[0.063rem] solid border-lightBlue flex justify-center items-center text-blue p-1">
                  <HButton
                    type="button"
                    onClick={() => setBlurDegree(blurDegree + 1)}
                    className="icon text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem] mr-0.5"
                  >
                    <FiPlus size={18} />
                  </HButton>
                </div>
              </div>
              <span className="text-xs py-1 px-0 grow">{blurDegree}%</span>
            </div>
          </div>
          <div className="blur-option">
            <span className="text-xs py-1 px-0 grow">Size</span>
            <div className="flex items-center">
              <div className="slider flex items-center grow justify-between mt-[0.625ren] mx-[0.625ren] mb-0 relative">
                <div className="button w-[1.563rem] h-[1.563rem] rounded-full border-[0.063rem] solid border-lightBlue flex justify-center items-center text-blue p-1">
                  <HButton
                    type="button"
                    onClick={() => setBlurSize(blurSize - 10)}
                    className="icon text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem] mr-0.5"
                  >
                    <FiMinus size={18} />
                  </HButton>
                </div>
                <Slider
                  setValue={setBlurSize}
                  position={20}
                  barColor={getColor('gray')}
                  valueColor={getColor('blue')}
                  circleColor={getColor('blue')}
                  width="105px"
                  value={blurSize}
                />
                <div className="button w-[1.563rem] h-[1.563rem] rounded-full border-[0.063rem] solid border-lightBlue flex justify-center items-center text-blue p-1">
                  <HButton
                    type="button"
                    onClick={() => setBlurSize(blurSize + 10)}
                    className="icon text-blue flex items-center justify-center w-[1.125rem] h-[1.125rem] mr-0.5"
                  >
                    <FiPlus size={18} />
                  </HButton>
                </div>
              </div>
              <span className="text-xs py-1 px-0 grow">{blurSize}px</span>
            </div>
          </div>
        </div>
      )}
    </div>
    <div className="flex justify-center items-center">
      <Button type="button" onClick={onCancel} className={elements.button.secondary} style={{width: '6rem'}}>
        <span>Cancel</span>
      </Button>
      <Button
        type="button"
        style={{
          width: stage ? '8rem' : '6.25rem',
        }}
        className={elements.button.primary}
        onClick={onSave}
      >
        <span>{stage ? 'Save Changes' : 'Save'}</span>
      </Button>
    </div>
  </div>
);
