/* eslint-disable @typescript-eslint/only-throw-error */

const handleError = (req: Response) => {
  if (!req.ok) {
    throw req;
  }
};

export const request = async ({
  path,
  baseUrl,
  token,
  isBearer = true,
  opts,
}: {
  path: string;
  baseUrl: string;
  token: string | null;
  isBearer?: boolean;
  opts?: RequestInit & {
    query?: Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/sort-type-constituents
    json?: boolean | string | string[] | Record<string, unknown>;
  };
}) => {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  headers = {
    ...headers,
    ...(opts?.headers ?? {}),
  };
  if (token) {
    headers = {
      ...headers,
      Authorization: `${isBearer ? 'Bearer ' : ''}${token}`,
    };
  }
  let url = `${baseUrl}${path}`;
  const method = opts?.method?.toUpperCase();
  if (opts?.query) {
    url = `${url}?${new URLSearchParams(opts.query).toString()}`;
  }
  if (opts?.json && (method === 'POST' || method === 'PUT')) {
    opts.body = JSON.stringify(opts.json);
  }
  const req = await fetch(url, {
    ...(opts ?? {}),
    headers,
  });

  handleError(req);
  
  return req;
};
