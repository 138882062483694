/* eslint-disable import/named */
import { theme } from '../../../util/styles';
import { ReactNode } from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

interface Props {
  text?: ReactNode;
  children?: ReactNode;
  position?: PlacesType;
  width?: string;
  id: string;
}

export const Tip = ({
  text = '',
  children,
  position = 'right',
  width,
  id,
}: Props) => (
  <Tooltip
    place={position}
    style={{
      width,
      backgroundColor: theme('colors.granite'),
      fontSize: '0.875rem',
    }}
    opacity={1}
    id={id}
    className="[&_*]:text-white [&_h2]:mt-2 [&_h2]:font-medium text-sm [&_h2]:text-lg z-[5] flex-wrap text-center leading-[1.0625rem]"
  >
    {children ?? text}
  </Tooltip>
);
