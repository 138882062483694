import { useBackgroundQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import DataLayout, { FilterOption, LinkType, TableColumn } from '../../atoms/DataLayout';
import { FiPlus, FiUserPlus } from 'react-icons/fi';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { getFragmentData, StudentFilterFunctionInput, StudentFragmentFragment, StudentStatusEnum, studentStatuses } from '@board/graphql';
import { GqlIndexStudents, GqlPagination, GqlStudent } from '@board/resources';
import { SelectInput } from '../../molecules/SelectInput';
import { TextInput } from '../../atoms/TextInput';
import { elements } from '../../../util/styles';

enum TableColumnEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  registrationGroup = 'registrationGroup',
  dateOfBirth = 'dateOfBirth',
  upn = 'upn',
  actions = 'actions',
}

enum FilterOptionEnum {
  status = 'status',
  search = 'search',
  group = 'group',
}

const defaultFilters = {
  term: null,
  groupUuid: null,
  status: null,   
};

const links: LinkType[] = [
  {
    to: '/students/add',
    label: `Add Student`,
    Icon: FiPlus,
  },
];

const groups: Array<{value: string; label: string}> = [];

const StudentList = () => {
  const [filters, setFilters] =
    useState<Pick<StudentFilterFunctionInput, 'groupUuid' | 'status' | 'term'>>(defaultFilters);

  const [page, setPage] = useState(1);
  const [query] = useBackgroundQuery(GqlIndexStudents, {
    variables: {
      input: {
        filters: {
          ...filters,
        },
        orderBy: null,
        pagination: {
          page,
          perPage: 20,
        },
      },
    },
  });

  const [term, setTerm] = useState<string | null>(null);
  const [groupUuid, setGroupUuid] = useState<string | null>(null);
  const [status, setStatus] = useState<StudentStatusEnum | null>(null);
  const currentFilters = useMemo(
    () => ({
      term,
        groupUuid,
        status,
    }),
    [term, groupUuid, status]
  );

  const applyFilters = useCallback(() => {
    setFilters({
      term,
    });
  }, [term]);

  const resetFilters = useCallback(() => {
    setTerm(null);
  }, []);

  const filterOptions = useMemo<FilterOption[]>(
    () => [
      {
        key: FilterOptionEnum.search,
        label: 'Search',
        Component: ({ term: item }: { term?: string }) => (
          <TextInput
            type="text"
            placeholder="Search by Student Name..."
            name="search"
            id="search"
            value={item}
            onChange={(e) => setTerm(e.currentTarget.value)}
          />
        ),
      },
      {
        key: FilterOptionEnum.group,
        label: 'Group',
        Component: () => (
            <SelectInput
                placeholder="Filter by Group"
                name="group"
                options={groups}
                onToggle={i => setGroupUuid(i.value)}
                value={groupUuid ?? undefined}
            />
        ),
      },
      {
        key: FilterOptionEnum.status,
        label: 'Status',
        Component: () => (
            <SelectInput
                placeholder="Filter by Status"
                name="status"
                options={studentStatuses}
                onToggle={i => setStatus(i.value)}
                value={status ?? undefined}
            />
        ),
      },
    ],
    [status, groupUuid]
  );

  const tableColumns = useMemo<Array<TableColumn<StudentFragmentFragment>>>(
    () => [
      {
        key: TableColumnEnum.firstName,
        label: 'Name',
        handler: (item: StudentFragmentFragment) => item.firstName,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.lastName,
        label: 'Email',
        handler: (item: StudentFragmentFragment) => item.lastName,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.registrationGroup,
        label: 'Registration Group',
        handler: (item: StudentFragmentFragment) => item.registrationGroup,
        style: { width: '20%' },
      },
      {
        key: TableColumnEnum.dateOfBirth,
        label: 'Date of Birth',
        handler: (item: StudentFragmentFragment) => item.doB ? new Date(item.doB).toNiceFormat() : '',
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.upn,
        label: 'UPN',
        handler: (item: StudentFragmentFragment) => item.upn,
        style: { width: '15%' },
      },
      {
        key: TableColumnEnum.actions,
        label: '',
        handler: (item: StudentFragmentFragment) => (
          <Link
            className={classNames(
              elements.button.secondary,
              'inline-flex whitespace-nowrap px-4'
            )}
            to={item.uuid}
          >
            View
          </Link>
        ),
        style: { width: '10%' },
      },
    ],
    []
  );

  return (
    <DataLayout
      Icon={FiUserPlus}
      title="Students"
      subtitle="Manage your students below"
      setPage={setPage}
      tableColumns={tableColumns}
      filterOptions={filterOptions}
      onReset={resetFilters}
      onConfirm={applyFilters}
      currentFilters={currentFilters}
      baseFilters={defaultFilters}
      links={links}
      query={query}
      extractItems={(data) => data.indexStudents.items.map(i => getFragmentData(GqlStudent, i))}
      extractPagination={(data) => getFragmentData(GqlPagination, data.indexStudents.pagination)}
    />
  );
};

export default StudentList;
