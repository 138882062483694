import { ReactNode, useMemo } from 'react';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Button as HButton,
  Button,
} from '@headlessui/react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';
import { Field } from 'formik';
import { Link, useRouter } from '@tanstack/react-router';

export interface Item {
  value: number | string;
  label: ReactNode;
  displayName?: string;
  onClick?: () => void;
  isInternalLink?: boolean;
  isExternalLink?: boolean;
  keepOpenOnClick?: boolean;
}

const Toggle = () => (
  <div className="toggle  w-4 h-4 border border-solid rounded-sm border-inherit flex items-center justify-center py-0 px-[0.1875rem]  before:w-full before:border-t-2 before:mt-[1px] before:border-inherit before:content-[''] ml-2">
    {/* comment so no empty */}
  </div>
);


const isMobile = () => {
  if (typeof window === 'undefined') return false;

  const userAgent =
    navigator.userAgent ||
    (
      window as unknown as {
        opera: string;
      }
    ).opera;

  return (
    /android|bb\d+|meego.+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent
    ) ||
    navigator.maxTouchPoints > 0 ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgent.substring(0, 4)
    )
  );
};

export interface SelectProps<T extends Item, Y extends string> {
  label?: ReactNode;
  placeholder?: ReactNode;
  options: T[];
  value?: Array<T['value']> | T['value'];
  onToggle?: (item: T, name: Y) => void;
  onSet?: (items: T[], name: Y) => void;
  buttonClassNames?: string;
  arrowClassNames?: string;
  inputClassNames?: string;
  name: Y;
  required?: boolean;
  disabled?: boolean;
}

function DropdownInner<T extends Item, Y extends string>({
  label,
  name,
  placeholder = 'Choose from the list',
  options,
  value,
  onToggle,
  onSet,
  buttonClassNames,
  arrowClassNames = 'border border-gray border-l-0 text-dark h-11',
  inputClassNames = classNames(
    'border border-gray h-11 flex items-center rounded-md outline-none focus:border-dark focus:bg-dark/5 min-w-12 max-w-full',
    'border-r-0 rounded-r-none grow pl-3 min-w-0'
  ),
  required,
  disabled,
}: SelectProps<T, Y>) {
  const multiple = Array.isArray(value);

  const activeValues = useMemo(
    () => (multiple ? value.map(String) : value ? [String(value)] : []),
    [value, multiple]
  );

  const values = useMemo(() => options.map((o) => String(o.value)), [options]);

  const activeObjects = useMemo<Item[]>(
    () =>
      activeValues
        .map((selectedValue) =>
          options.find(
            ({ value: newValue }) => String(newValue) === String(selectedValue)
          )
        )
        .sort((a, b) => {
          const aIndex = a ? values.indexOf(String(a.value)) : 0;
          const bIndex = b ? values.indexOf(String(b.value)) : 0;
          return aIndex - bIndex;
        })
        .filter(Boolean) as Item[],
    [activeValues, options, values]
  );

  const isMobileDevice = isMobile();

  const router = useRouter();

  return (
    <>
      {!!label && (
        <label htmlFor={name} className="text-sm mb-1.5 block text-darkGray">
          {label}
        </label>
      )}
      <Menu as="div" className="flex grow relative">
        <MenuButton
          disabled={disabled}
          className={classNames(
            'flex items-center justify-between grow group relative text-sm w-full',
            buttonClassNames ?? ''
          )}
          onClick={(e) => {
            if (isMobileDevice) {
              e.preventDefault();

              e.stopPropagation();
            }
          }}
        >
          <div className={inputClassNames}>
            <span
              className={`truncate leading-[14px] ${
                disabled || options.length === 0 ? 'text-gray' : ''
              }`}
            >
              {activeObjects.length > 0
                ? activeObjects.length > 2
                  ? `${String(
                      // eslint-disable-next-line @typescript-eslint/no-base-to-string
                      activeObjects[0].displayName ?? activeObjects[0].label
                    )} and ${activeObjects.length - 1} more`
                  // eslint-disable-next-line @typescript-eslint/no-base-to-string
                  : activeObjects
                      // eslint-disable-next-line @typescript-eslint/promise-function-async
                      .map((a) => a.displayName ?? a.label)
                      .join(', ')
                : placeholder}
            </span>
          </div>
          <div
            className={classNames(
              'p-2 flex items-center justify-center rounded-r-md text-lightGray',
              arrowClassNames
            )}
          >
            <FiChevronDown
              className="h-5 w-5 transition-all group-data-[open]:rotate-180"
              aria-hidden="true"
              size={20}
            />
          </div>
          {isMobileDevice && (
            <select
              name={name}
              multiple={multiple}
              value={activeValues}
              onChange={(e) => {
                const item = options.find((i) => i.value === e.target.value);
                if (item?.isInternalLink) {
                  router.history.push(item.value as string);
                } else if (item?.isExternalLink) {
                  window.location.href = item.value as string;
                } else if (item?.onClick) {
                  item.onClick();
                } else if (onToggle && item) {
                  onToggle(item, name);
                }
              }}
              className="appearance-none absolute h-full w-full opacity-0"
              required={required}
            >
              <option value="" disabled>
                Select an option
              </option>
              {options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={
                    Array.isArray(value)
                      ? value.includes(String(option.value))
                      : value === option.value
                  }
                >
                  {option.label}
                </option>
              ))}
            </select>
          )}
        </MenuButton>
        <MenuItems
          anchor={{
            to: 'bottom start',
            gap: 12,
          }}
          className="rounded bg-white min-w-[--button-width] shadow-xl z-50"
        >
          <div className="overflow-y-auto overflow-x-hidden w-full py-2.5 px-1.5">
            {multiple && (
              <MenuItem as="div" className="flex justify-end mb-2">
                {({ close }) => (
                  <Button
                    type="button"
                    className={classNames(
                      'control border-lightGray flex cursor-pointer items-center cursor-pointer text-blue border-blue h-auto'
                    )}
                    onClick={() => {
                      onSet?.(options, name);
                      close();
                    }}
                  >
                    <span className="text-sm">Select all</span>
                    <Toggle />
                  </Button>
                )}
              </MenuItem>
            )}
            {options.map((item) => (
              <MenuItem key={item.value}>
                {({ close }) => (
                  <>
                    {item.isExternalLink ? (
                      <a
                        href={item.value as string}
                        className={classNames(
                          activeValues.includes(String(item.value))
                            ? 'bg-babyBlue text-blue'
                            : '',
                          'w-full group/checkbox p-2.5 rounded text-sm flex-grow text-left hover:bg-babyBlue hover:text-blue flex items-center last:mb-0 disabled:text-gray'
                        )}
                        onClick={() => {
                          if (!item.keepOpenOnClick) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                      >
                        <span className="leading-relaxed whitespace-nowrap">
                          {item.label}
                        </span>
                      </a>
                    ) : item.isInternalLink ? (
                      <Link
                        to={item.value as string}
                        onClick={() => {
                          if (!item.keepOpenOnClick) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          }
                        }}
                        className={classNames(
                          activeValues.includes(String(item.value))
                            ? 'bg-babyBlue text-blue'
                            : '',
                          'w-full group/checkbox p-2.5 rounded text-sm flex-grow text-left hover:bg-babyBlue hover:text-blue flex items-center last:mb-0 disabled:text-gray'
                        )}
                      >
                        <span className="leading-relaxed whitespace-nowrap">
                          {item.label}
                        </span>
                      </Link>
                    ) : (
                      <HButton
                        type="button"
                        onClick={() => {
                          if (
                            !item.keepOpenOnClick ||
                            (value && value === item.value)
                          ) {
                            close();
                          }
                          if (item.onClick) {
                            item.onClick();
                          } else if (onToggle) {
                            onToggle(item, name);
                          }
                        }}
                        className={classNames(
                          activeValues.includes(String(item.value))
                            ? 'bg-babyBlue text-blue'
                            : '',
                          'w-full group/checkbox p-2.5 rounded text-sm flex-grow text-left hover:enabled:bg-babyBlue hover:enabled:text-blue flex items-center justify-between last:mb-0 disabled:text-gray'
                        )}
                      >
                        <>
                          <span className="leading-relaxed whitespace-nowrap mr-2">
                            {item.label}
                          </span>
                          {activeValues.includes(String(item.value)) && (
                            <FiCheck size={16} />
                          )}
                        </>
                      </HButton>
                    )}
                  </>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </>
  );
}

export function SelectInput<T extends Item, Y extends string>(
  props: SelectProps<T, Y> & {
    useFormik?: boolean;
  }
) {
  if (!props.useFormik) {
    return <DropdownInner {...props} />;
  }

  return (
    <Field
      name={props.name}
      as="div"
      component={({
        field,
      }: {
        field: {
          name: string;
          value: string;
          onChange: (v: {
            target: {
              value: Array<number | string> | number | string;
              name: string;
            };
          }) => void;
        };
      }) => (
        <DropdownInner
          {...props}
          name={field.name}
          value={field.value}
          onToggle={(item) => {
            field.onChange({
              target: {
                value: item.value,
                name: field.name,
              },
            });

            props.onToggle?.(item, props.name);
          }}
          onSet={(items) => {
            field.onChange({
              target: {
                value: items.map((i) => i.value),
                name: field.name,
              },
            });

            props.onSet?.(items, props.name);
          }}
        />
      )}
    />
  );
}
