import { createFileRoute } from '@tanstack/react-router';
import { DefaultHead } from '../../components/atoms/DefaultHead';
import { Form } from '../../components/organisms/Login';
import { useEnvVars } from '../../hooks/useEnvVars';

export const Route = createFileRoute('/_unauthenticated/login')({
  component: RouteComponent,
});

function RouteComponent() {
  const {
    loginErrorUrl,
    softwareId,
  } = useEnvVars();
  return (
    <>
      <DefaultHead title="Sign In" />
      <Form
        loginErrorUrl={loginErrorUrl ?? ''}
        softwareId={softwareId}
      />
    </>
  );
}
