import { createFileRoute, notFound } from '@tanstack/react-router';
import { PageLoader } from '../../../components/atoms/PageLoader';
import Messages from '../../../components/organisms/Messages';
import { Suspense } from 'react';
import { queryClient } from '../../../context/QueryContext';
import { EnvVars } from '../../../util/request';
import { buildLogError } from '../../../hooks/useLogError';
import { buildApolloClient } from '../../../context/ApolloContext';
import { GqlConversation, GqlFindConversation } from '@board/resources';
import { getFragmentData } from '@board/graphql';

export const Route = createFileRoute('/_authenticated/conversations/$uuid')({
  component: RouteComponent,
  loader: async ({ params }) => {
    const envVars = queryClient.getQueryData<EnvVars>(['envVars']);

    // eslint-disable-next-line @typescript-eslint/only-throw-error
    if (!envVars) throw notFound();

    const logError = buildLogError({
        apiUrl: envVars.apiUrl,
        baseApiUrl: envVars.baseApiUrl,
        softwareId: envVars.softwareId
    });

    const apolloClient = buildApolloClient({
        apiUrl: envVars.apiUrl,
        logError
    });

    const { data } = await apolloClient.query({
        query: GqlFindConversation,
        variables: {
            uuid: params.uuid
        }
    });

    const fragment = getFragmentData(GqlConversation, data.findConversation);
    
    if (!fragment.uuid) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw notFound();
    }
  }
});

function RouteComponent() {
    const {
        uuid
    } = Route.useParams();
    return (
      <Suspense fallback={<PageLoader />}>
          <Messages uuid={uuid} />
      </Suspense>
    );
  }
  