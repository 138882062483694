import { gql } from '@board/graphql';

export const GqlPagination = gql(`
  fragment PaginationFragment on PaginationResponse {
    total
    perPage
    currentPage
    lastPage
    from
    to
  }
`);
