/* eslint-disable import/named */
import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { getFragmentData, MeQuery } from '@board/graphql';
import { GqlMeQuery, GqlUser } from '@board/resources';
import { CookieChangeListener } from 'universal-cookie';
import { jsCookie } from '../util/cookie';
import { ConfirmModal } from '../components/molecules/ConfirmModal';

export interface AuthContext {
  user?: MeQuery['me'];
  firstName?: string;
  baseToken?: string;
  accessToken?: string;
}

export const writeAccessToken = (token: string) => {
  jsCookie.set('x-access-token', token);
};

export const clearAccessToken = (redirectUrl?: string) => {
  jsCookie.remove('x-access-token');
  if (redirectUrl) {
    localStorage.setItem('redirectUrl', redirectUrl);
  }
};

const getApiTokenFromSearchParams = () =>
  new URLSearchParams(window.location.search).get('token');

const getAccessTokenFromStorage = () =>
  jsCookie.get('x-access-token') as string | undefined;

export const getAccessToken: () => string | undefined = () =>
  getApiTokenFromSearchParams() ?? getAccessTokenFromStorage();

export const getBaseToken = () => jsCookie.get('x-base-token') as string | null;

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [accessToken, setAccessToken] = useState<string | undefined>(getAccessToken());

  const { data, refetch } = useSuspenseQuery(GqlMeQuery, {
    skip: !accessToken,
  });

  const prevToken = useRef<string | undefined>(accessToken);

  useEffect(() => {
    const apiTokenFromSearchParams = getApiTokenFromSearchParams();
    const apiTokenFromStorage = getAccessTokenFromStorage();
    if (
      apiTokenFromSearchParams &&
      apiTokenFromSearchParams !== apiTokenFromStorage
    ) {
      writeAccessToken(apiTokenFromSearchParams);
    }
    const onCookieChange: CookieChangeListener = (e) => {
      if (e.name === 'x-access-token') {
        setAccessToken(e.value ? String(e.value) : undefined);
      }
    };

    jsCookie.addChangeListener(onCookieChange);
    return () => {
      jsCookie.removeChangeListener(onCookieChange);
    };
  }, []);

  useEffect(() => {
    if (prevToken.current && prevToken.current !== accessToken) {
      void refetch();
    }
    prevToken.current = accessToken;
  }, [accessToken, refetch]);

  const user = data?.me ? getFragmentData(GqlUser, data.me) : undefined;
  const firstName = user?.firstName;

  return (
    <AuthContext.Provider
      value={{
        user,
        firstName,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export interface UserContext {
  user: MeQuery['me'];
  setConfirmModalMessage: (message: ConfirmModalProps | null) => void;
}

const UserContext = createContext<UserContext>({} as UserContext);

export interface ConfirmModalProps {
  mounted: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  onDecline?: () => void;
  declineText?: string;
  title?: string;
  content: string;
}

export function UserProvider({ children }: { children: ReactNode }) {
  const { data } = useSuspenseQuery(GqlMeQuery);

  const user = getFragmentData(GqlUser, data.me);

  const [confirmModalMessage, setConfirmModalMessage] =
    useState<ConfirmModalProps | null>(null);

  return (
    <UserContext.Provider value={{ user, setConfirmModalMessage }}>
      {children}
      <ConfirmModal
        {...(confirmModalMessage ?? { mounted: false, content: '' })}
      />
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);

  return context;
}
