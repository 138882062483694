import { useState, useEffect } from 'react';
import { Dots } from '../../../../components/atoms/Dots';
import { fetchRssFeed, NewsItemType } from '../../../../util/rss';

export const Sidebar = ({ loginFeedUrl }: { loginFeedUrl: string }) => {
  const [index, setIndex] = useState(0);
  const [feed, setFeed] = useState<NewsItemType[]>([]);
  const count = feed.length || 0;
  useEffect(() => {
    const timer = setInterval(() => {
      const newIndex = index + 1 === count ? 0 : index + 1;
      setIndex(newIndex);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [setIndex, index, count]);

  useEffect(() => {
    void (async () => {
      if (loginFeedUrl) {
        setFeed(await fetchRssFeed(loginFeedUrl, 4));
      }
    })();
  }, [loginFeedUrl]);

  const translateX = (index * -100) / count;
  return (
    <aside className="sidebar bg-blue relative w-80 flex flex-col justify-between text-white p-8 bg-cover text-white">
      <div>
        <div className="mb-16">
          <div className="flex items-center">
            <h3 className="text-2xl leading-6 m-0 font-normal text-white">
              My BSquared
            </h3>
          </div>
        </div>
        <div className="content">
          {!!count && (
            <div className="overflow-hidden">
              <div
                className="flex -mx-7 mb-5 transition-transform"
                style={{
                  transform: `translateX(${translateX}%)`,
                  width: `calc(${count * 100}% + 3.5rem)`,
                }}
              >
                {feed.map((item, i) => (
                  <div className="slide w-full px-7" key={i}>
                    <h4
                      className="mb-2 text-white text-lg font-normal"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <div
                      className="text-sm [&_a]:text-white [&_a]:underline"
                      dangerouslySetInnerHTML={{ __html: item.summary }}
                    />

                    <a
                      href={item.link}
                      className="underline text-sm text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  </div>
                ))}
              </div>
              <Dots count={count} index={index} setIndex={setIndex} />
            </div>
          )}
        </div>
      </div>
      <footer className="text-xs">
        ©2004 - {new Date().getFullYear()} BSquared software licensed. All
        rights reserved
      </footer>
    </aside>
  );
};
