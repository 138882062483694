import { Modal } from '../../../../../../molecules/Modal';
import { useEffect, useState } from 'react';
import { NewsItem } from './NewsItem';
import { fetchRssFeed } from '../../../../../../../util/rss';

interface NewsItem {
  title?: string;
  isoDate?: string;
  content?: string;
}

export const NewsModal = ({
  display,
  newsFeedUrl,
  onClose,
}: {
  display: boolean;
  newsFeedUrl: string;
  onClose: () => void;
}) => {
  const [rssFeeds, setRssFeeds] = useState<NewsItem[]>([]);
  useEffect(() => {
    if (!newsFeedUrl) return;
    void fetchRssFeed(newsFeedUrl).then((items) => {
      setRssFeeds(
        items.map((item) => ({
          id: item.id,
          title: item.title,
          isoDate: item.isoDate,
          content: item.summary,
          link: item.link,
        }))
      );
    });
  }, [newsFeedUrl]);
  return (
    <Modal width="50rem" mounted={display} title="News" onClose={onClose}>
      <div className="p-6">
        {rssFeeds.map((item) => (
          <NewsItem key={item.isoDate} item={item} />
        ))}
      </div>
    </Modal>
  );
};
