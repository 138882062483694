import { ConversationOrderByEnum } from './graphql';
import { ErrorLevel } from './graphql';
import { GenderEnum } from './graphql';
import { RoleEnum } from './graphql';
import { StudentOrderByEnum } from './graphql';
import { StudentStatusEnum } from './graphql';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { StrictTypeOrmIndividual as StrictTypeOrmIndividualModel } from '@board/typeorm';
import { Conversation as ConversationModel, ConversationMessage as ConversationMessageModel } from '@prisma/client';
import { BcasUserInterface as BcasUserInterfaceModel, InvocationContextInterface } from '../../';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnumResolverSignature<T, AllowedValues = any> = { [key in keyof T]?: AllowedValues };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
};

export type Conversation = {
  __typename?: 'Conversation';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  messages: Array<Message>;
  nextActions: Array<ConversationAction>;
  programUuid: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

export type ConversationAction = {
  __typename?: 'ConversationAction';
  label: Scalars['String']['output'];
  properties: Array<ConversationActionProperty>;
};

export type ConversationActionProperty = {
  __typename?: 'ConversationActionProperty';
  key: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ConversationFilterFunctionInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConversationFilterInput = {
  filters?: InputMaybe<ConversationFilterFunctionInput>;
  orderBy?: InputMaybe<ConversationOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export { ConversationOrderByEnum };

export type ConversationOrderByInput = {
  order?: InputMaybe<ConversationOrderByEnum>;
};

export type ConversationPage = {
  __typename?: 'ConversationPage';
  items: Array<Conversation>;
  pagination: PaginationResponse;
};

export type CreateStudentInput = {
  admissionDate: Scalars['String']['input'];
  curriculumType?: InputMaybe<Scalars['Int']['input']>;
  doB: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  evisenseBandId?: InputMaybe<Scalars['String']['input']>;
  evisenseBandName?: InputMaybe<Scalars['String']['input']>;
  evisenseLastLogin?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  guardians?: InputMaybe<Scalars['Int']['input']>;
  idFields?: InputMaybe<Array<InputMaybe<IdBaseValueInput>>>;
  isDeleted: Scalars['Boolean']['input'];
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  leavingDate?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nextYearGroupDetails?: InputMaybe<GroupToIndividualDetailsInput>;
  pendingInvite: Scalars['Boolean']['input'];
  protected: Scalars['Boolean']['input'];
  registrationGroup?: InputMaybe<Scalars['String']['input']>;
  registrationGroupDetails: GroupToIndividualDetailsInput;
  software?: InputMaybe<SoftwareInput>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  upn?: InputMaybe<Scalars['String']['input']>;
  wasLastActiveInCurrentSchool: Scalars['Boolean']['input'];
  year: Scalars['String']['input'];
};

export type EditConversationInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['ID']['input'];
};

export type EditStudentInput = {
  admissionDate?: InputMaybe<Scalars['String']['input']>;
  curriculumType?: InputMaybe<Scalars['Int']['input']>;
  doB?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  evisenseBandId?: InputMaybe<Scalars['String']['input']>;
  evisenseBandName?: InputMaybe<Scalars['String']['input']>;
  evisenseLastLogin?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  guardians?: InputMaybe<Scalars['Int']['input']>;
  idFields?: InputMaybe<Array<InputMaybe<IdBaseValueInput>>>;
  individualUID: Scalars['ID']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  leavingDate?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nextYearGroupDetails?: InputMaybe<GroupToIndividualDetailsInput>;
  pendingInvite?: InputMaybe<Scalars['Boolean']['input']>;
  protected?: InputMaybe<Scalars['Boolean']['input']>;
  registrationGroup?: InputMaybe<Scalars['String']['input']>;
  registrationGroupDetails: GroupToIndividualDetailsInput;
  software?: InputMaybe<SoftwareInput>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  upn?: InputMaybe<Scalars['String']['input']>;
  wasLastActiveInCurrentSchool?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export { ErrorLevel };

export { GenderEnum };

export type GroupToIndividualDetailsInput = {
  groupUID: Scalars['String']['input'];
  joinDate: Scalars['String']['input'];
  leftDate?: InputMaybe<Scalars['String']['input']>;
};

export type IdBaseValueInput = {
  baseIDName?: InputMaybe<Scalars['String']['input']>;
  baseIDValue?: InputMaybe<Scalars['String']['input']>;
  baseIdUid?: InputMaybe<Scalars['String']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IndividualSoftwareAccess = {
  __typename?: 'IndividualSoftwareAccess';
  hasCSAccess: Scalars['Boolean']['output'];
  hasCsamAccess: Scalars['Boolean']['output'];
  hasEviAccess: Scalars['Boolean']['output'];
  showInCs: Scalars['Boolean']['output'];
  showInEvi: Scalars['Boolean']['output'];
};

export type LogErrorInput = {
  errorLevel: ErrorLevel;
  message: Scalars['String']['input'];
  schoolUuid?: InputMaybe<Scalars['ID']['input']>;
  screen: Scalars['String']['input'];
  softwareId: Scalars['Int']['input'];
  stack?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  programMode: Scalars['Int']['input'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  accessToken: Scalars['String']['output'];
  baseToken: Scalars['String']['output'];
  dueToPolicyChange: Scalars['Boolean']['output'];
  isForcePasswordChange: Scalars['Boolean']['output'];
  refreshToken: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  conversationUuid: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  role: RoleEnum;
  text: Maybe<Scalars['String']['output']>;
  toolCallId: Maybe<Scalars['String']['output']>;
  toolCalls: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createStudent: Student;
  deleteConversation: Scalars['Boolean']['output'];
  deleteStudent: Scalars['Boolean']['output'];
  editConversation: Conversation;
  editStudent: Student;
  logError: Scalars['Boolean']['output'];
  login: LoginToken;
  sendMessage: Conversation;
};


export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};


export type MutationDeleteConversationArgs = {
  uuid: Scalars['ID']['input'];
};


export type MutationDeleteStudentArgs = {
  uuid: Scalars['ID']['input'];
};


export type MutationEditConversationArgs = {
  input: EditConversationInput;
};


export type MutationEditStudentArgs = {
  input: EditStudentInput;
};


export type MutationLogErrorArgs = {
  input: LogErrorInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type PaginationInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};

export type PaginationResponse = {
  __typename?: 'PaginationResponse';
  currentPage: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  findConversation: Conversation;
  findStudent: Student;
  getConversationActions: Array<ConversationAction>;
  indexConversations: ConversationPage;
  indexStudents: StudentPage;
  me: User;
};


export type QueryFindConversationArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryFindStudentArgs = {
  uuid: Scalars['ID']['input'];
};


export type QueryGetConversationActionsArgs = {
  conversationUuid: Scalars['ID']['input'];
  messageId: InputMaybe<Scalars['ID']['input']>;
};


export type QueryIndexConversationsArgs = {
  input: InputMaybe<ConversationFilterInput>;
};


export type QueryIndexStudentsArgs = {
  input: InputMaybe<StudentFilterInput>;
};

export { RoleEnum };

export type SendMessageInput = {
  conversationUuid?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type SoftwareInput = {
  hasCSAccess?: InputMaybe<Scalars['Boolean']['input']>;
  hasCsamAccess?: InputMaybe<Scalars['Boolean']['input']>;
  hasEviAccess?: InputMaybe<Scalars['Boolean']['input']>;
  showInCS?: InputMaybe<Scalars['Boolean']['input']>;
  showInEvi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Student = {
  __typename?: 'Student';
  admissionDate: Maybe<Scalars['DateTime']['output']>;
  analytics: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  deletedDate: Maybe<Scalars['DateTime']['output']>;
  doB: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  evisenseBandId: Scalars['ID']['output'];
  evisenseBandName: Scalars['String']['output'];
  evisenseLastLogin: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  gender: Maybe<GenderEnum>;
  guardians: Scalars['Int']['output'];
  idFields: Array<StudentIdField>;
  isSelected: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  leftDate: Maybe<Scalars['DateTime']['output']>;
  middleName: Scalars['String']['output'];
  registrationGroup: Scalars['String']['output'];
  software: IndividualSoftwareAccess;
  suspended: Scalars['Boolean']['output'];
  upn: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
  year: Scalars['String']['output'];
};

export type StudentFilterFunctionInput = {
  groupUuid?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<StudentStatusEnum>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type StudentFilterInput = {
  filters?: InputMaybe<StudentFilterFunctionInput>;
  orderBy?: InputMaybe<StudentOrderByInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type StudentIdField = {
  __typename?: 'StudentIdField';
  baseIDName: Scalars['String']['output'];
  baseIDValue: Scalars['String']['output'];
  baseIdUid: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
};

export { StudentOrderByEnum };

export type StudentOrderByInput = {
  order?: InputMaybe<StudentOrderByEnum>;
};

export type StudentPage = {
  __typename?: 'StudentPage';
  items: Array<Student>;
  pagination: PaginationResponse;
};

export { StudentStatusEnum };

export type User = {
  __typename?: 'User';
  emailAddress: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  roleName: Maybe<Scalars['String']['output']>;
  roleUuid: Scalars['ID']['output'];
  schoolName: Scalars['String']['output'];
  schoolUuid: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Conversation: ResolverTypeWrapper<ConversationModel>;
  ConversationAction: ResolverTypeWrapper<ConversationAction>;
  ConversationActionProperty: ResolverTypeWrapper<ConversationActionProperty>;
  ConversationFilterFunctionInput: ConversationFilterFunctionInput;
  ConversationFilterInput: ConversationFilterInput;
  ConversationOrderByEnum: ConversationOrderByEnum;
  ConversationOrderByInput: ConversationOrderByInput;
  ConversationPage: ResolverTypeWrapper<Omit<ConversationPage, 'items'> & { items: Array<ResolversTypes['Conversation']> }>;
  CreateStudentInput: CreateStudentInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  EditConversationInput: EditConversationInput;
  EditStudentInput: EditStudentInput;
  ErrorLevel: ErrorLevel;
  GenderEnum: GenderEnum;
  GroupToIndividualDetailsInput: GroupToIndividualDetailsInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IdBaseValueInput: IdBaseValueInput;
  IndividualSoftwareAccess: ResolverTypeWrapper<IndividualSoftwareAccess>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  LogErrorInput: LogErrorInput;
  LoginInput: LoginInput;
  LoginToken: ResolverTypeWrapper<LoginToken>;
  Message: ResolverTypeWrapper<ConversationMessageModel>;
  Mutation: ResolverTypeWrapper<{}>;
  PaginationInput: PaginationInput;
  PaginationResponse: ResolverTypeWrapper<PaginationResponse>;
  Query: ResolverTypeWrapper<{}>;
  RoleEnum: RoleEnum;
  SendMessageInput: SendMessageInput;
  SoftwareInput: SoftwareInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Student: ResolverTypeWrapper<StrictTypeOrmIndividualModel>;
  StudentFilterFunctionInput: StudentFilterFunctionInput;
  StudentFilterInput: StudentFilterInput;
  StudentIdField: ResolverTypeWrapper<StudentIdField>;
  StudentOrderByEnum: StudentOrderByEnum;
  StudentOrderByInput: StudentOrderByInput;
  StudentPage: ResolverTypeWrapper<Omit<StudentPage, 'items'> & { items: Array<ResolversTypes['Student']> }>;
  StudentStatusEnum: StudentStatusEnum;
  User: ResolverTypeWrapper<BcasUserInterfaceModel>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean']['output'];
  Conversation: ConversationModel;
  ConversationAction: ConversationAction;
  ConversationActionProperty: ConversationActionProperty;
  ConversationFilterFunctionInput: ConversationFilterFunctionInput;
  ConversationFilterInput: ConversationFilterInput;
  ConversationOrderByInput: ConversationOrderByInput;
  ConversationPage: Omit<ConversationPage, 'items'> & { items: Array<ResolversParentTypes['Conversation']> };
  CreateStudentInput: CreateStudentInput;
  DateTime: Scalars['DateTime']['output'];
  EditConversationInput: EditConversationInput;
  EditStudentInput: EditStudentInput;
  GroupToIndividualDetailsInput: GroupToIndividualDetailsInput;
  ID: Scalars['ID']['output'];
  IdBaseValueInput: IdBaseValueInput;
  IndividualSoftwareAccess: IndividualSoftwareAccess;
  Int: Scalars['Int']['output'];
  LogErrorInput: LogErrorInput;
  LoginInput: LoginInput;
  LoginToken: LoginToken;
  Message: ConversationMessageModel;
  Mutation: {};
  PaginationInput: PaginationInput;
  PaginationResponse: PaginationResponse;
  Query: {};
  SendMessageInput: SendMessageInput;
  SoftwareInput: SoftwareInput;
  String: Scalars['String']['output'];
  Student: StrictTypeOrmIndividualModel;
  StudentFilterFunctionInput: StudentFilterFunctionInput;
  StudentFilterInput: StudentFilterInput;
  StudentIdField: StudentIdField;
  StudentOrderByInput: StudentOrderByInput;
  StudentPage: Omit<StudentPage, 'items'> & { items: Array<ResolversParentTypes['Student']> };
  User: BcasUserInterfaceModel;
};

export type ConversationResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['Conversation'] = ResolversParentTypes['Conversation']> = {
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  messages: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType>;
  nextActions: Resolver<Array<ResolversTypes['ConversationAction']>, ParentType, ContextType>;
  programUuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationActionResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['ConversationAction'] = ResolversParentTypes['ConversationAction']> = {
  label: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties: Resolver<Array<ResolversTypes['ConversationActionProperty']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationActionPropertyResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['ConversationActionProperty'] = ResolversParentTypes['ConversationActionProperty']> = {
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  required: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationOrderByEnumResolvers = EnumResolverSignature<{ createdAtAsc: any, createdAtDesc: any }, ResolversTypes['ConversationOrderByEnum']>;

export type ConversationPageResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['ConversationPage'] = ResolversParentTypes['ConversationPage']> = {
  items: Resolver<Array<ResolversTypes['Conversation']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ErrorLevelResolvers = EnumResolverSignature<{ fatal: any, information: any }, ResolversTypes['ErrorLevel']>;

export type GenderEnumResolvers = EnumResolverSignature<{ f: any, m: any }, ResolversTypes['GenderEnum']>;

export type IndividualSoftwareAccessResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['IndividualSoftwareAccess'] = ResolversParentTypes['IndividualSoftwareAccess']> = {
  hasCSAccess: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCsamAccess: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasEviAccess: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showInCs: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showInEvi: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginTokenResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['LoginToken'] = ResolversParentTypes['LoginToken']> = {
  accessToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueToPolicyChange: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isForcePasswordChange: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  refreshToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = {
  conversationUuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  role: Resolver<ResolversTypes['RoleEnum'], ParentType, ContextType>;
  text: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toolCallId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toolCalls: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createStudent: Resolver<ResolversTypes['Student'], ParentType, ContextType, RequireFields<MutationCreateStudentArgs, 'input'>>;
  deleteConversation: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteConversationArgs, 'uuid'>>;
  deleteStudent: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteStudentArgs, 'uuid'>>;
  editConversation: Resolver<ResolversTypes['Conversation'], ParentType, ContextType, RequireFields<MutationEditConversationArgs, 'input'>>;
  editStudent: Resolver<ResolversTypes['Student'], ParentType, ContextType, RequireFields<MutationEditStudentArgs, 'input'>>;
  logError: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLogErrorArgs, 'input'>>;
  login: Resolver<ResolversTypes['LoginToken'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'input'>>;
  sendMessage: Resolver<ResolversTypes['Conversation'], ParentType, ContextType, RequireFields<MutationSendMessageArgs, 'input'>>;
};

export type PaginationResponseResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['PaginationResponse'] = ResolversParentTypes['PaginationResponse']> = {
  currentPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  page: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  findConversation: Resolver<ResolversTypes['Conversation'], ParentType, ContextType, RequireFields<QueryFindConversationArgs, 'uuid'>>;
  findStudent: Resolver<ResolversTypes['Student'], ParentType, ContextType, RequireFields<QueryFindStudentArgs, 'uuid'>>;
  getConversationActions: Resolver<Array<ResolversTypes['ConversationAction']>, ParentType, ContextType, RequireFields<QueryGetConversationActionsArgs, 'conversationUuid'>>;
  indexConversations: Resolver<ResolversTypes['ConversationPage'], ParentType, ContextType, Partial<QueryIndexConversationsArgs>>;
  indexStudents: Resolver<ResolversTypes['StudentPage'], ParentType, ContextType, Partial<QueryIndexStudentsArgs>>;
  me: Resolver<ResolversTypes['User'], ParentType, ContextType>;
};

export type RoleEnumResolvers = EnumResolverSignature<{ assistant: any, system: any, tool: any, user: any }, ResolversTypes['RoleEnum']>;

export type StudentResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['Student'] = ResolversParentTypes['Student']> = {
  admissionDate: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  analytics: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deletedDate: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  doB: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  evisenseBandId: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  evisenseBandName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  evisenseLastLogin: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender: Resolver<Maybe<ResolversTypes['GenderEnum']>, ParentType, ContextType>;
  guardians: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  idFields: Resolver<Array<ResolversTypes['StudentIdField']>, ParentType, ContextType>;
  isSelected: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leftDate: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  middleName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  registrationGroup: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  software: Resolver<ResolversTypes['IndividualSoftwareAccess'], ParentType, ContextType>;
  suspended: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  upn: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  year: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudentIdFieldResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['StudentIdField'] = ResolversParentTypes['StudentIdField']> = {
  baseIDName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseIDValue: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseIdUid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimary: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudentOrderByEnumResolvers = EnumResolverSignature<{ dobAsc: any, dobDesc: any, firstNameAsc: any, firstNameDesc: any, lastNameAsc: any, lastNameDesc: any, stampAsc: any, stampDesc: any }, ResolversTypes['StudentOrderByEnum']>;

export type StudentPageResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['StudentPage'] = ResolversParentTypes['StudentPage']> = {
  items: Resolver<Array<ResolversTypes['Student']>, ParentType, ContextType>;
  pagination: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudentStatusEnumResolvers = EnumResolverSignature<{ active: any, future: any, historic: any, notInGroup: any, pending: any }, ResolversTypes['StudentStatusEnum']>;

export type UserResolvers<ContextType = InvocationContextInterface, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  emailAddress: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleUuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  schoolName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schoolUuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = InvocationContextInterface> = {
  Conversation: ConversationResolvers<ContextType>;
  ConversationAction: ConversationActionResolvers<ContextType>;
  ConversationActionProperty: ConversationActionPropertyResolvers<ContextType>;
  ConversationOrderByEnum: ConversationOrderByEnumResolvers;
  ConversationPage: ConversationPageResolvers<ContextType>;
  DateTime: GraphQLScalarType;
  ErrorLevel: ErrorLevelResolvers;
  GenderEnum: GenderEnumResolvers;
  IndividualSoftwareAccess: IndividualSoftwareAccessResolvers<ContextType>;
  LoginToken: LoginTokenResolvers<ContextType>;
  Message: MessageResolvers<ContextType>;
  Mutation: MutationResolvers<ContextType>;
  PaginationResponse: PaginationResponseResolvers<ContextType>;
  Query: QueryResolvers<ContextType>;
  RoleEnum: RoleEnumResolvers;
  Student: StudentResolvers<ContextType>;
  StudentIdField: StudentIdFieldResolvers<ContextType>;
  StudentOrderByEnum: StudentOrderByEnumResolvers;
  StudentPage: StudentPageResolvers<ContextType>;
  StudentStatusEnum: StudentStatusEnumResolvers;
  User: UserResolvers<ContextType>;
};


export type MessageFragmentFragment = { __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string };

export type ConversationActionFragmentFragment = { __typename?: 'ConversationAction', label: string, properties: Array<{ __typename?: 'ConversationActionProperty', key: string, type: string, required: boolean }> };

export type ConversationFragmentFragment = { __typename?: 'Conversation', uuid: string, title: string, createdAt: string, updatedAt: string, deletedAt: string | null, messages: Array<{ __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string }> };

export type IndexConversationsQueryVariables = Exact<{
  input: InputMaybe<ConversationFilterInput>;
}>;


export type IndexConversationsQuery = { __typename?: 'Query', indexConversations: { __typename?: 'ConversationPage', pagination: { __typename?: 'PaginationResponse', total: number, perPage: number, currentPage: number, lastPage: number, from: number, to: number }, items: Array<{ __typename?: 'Conversation', uuid: string, title: string, createdAt: string, updatedAt: string, deletedAt: string | null, messages: Array<{ __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string }> }> } };

export type FindConversationQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type FindConversationQuery = { __typename?: 'Query', findConversation: { __typename?: 'Conversation', uuid: string, title: string, createdAt: string, updatedAt: string, deletedAt: string | null, messages: Array<{ __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string }> } };

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage: { __typename?: 'Conversation', uuid: string, title: string, createdAt: string, updatedAt: string, deletedAt: string | null, messages: Array<{ __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string }> } };

export type EditConversationMutationVariables = Exact<{
  input: EditConversationInput;
}>;


export type EditConversationMutation = { __typename?: 'Mutation', editConversation: { __typename?: 'Conversation', uuid: string, title: string, createdAt: string, updatedAt: string, deletedAt: string | null, messages: Array<{ __typename?: 'Message', uuid: string, text: string | null, role: RoleEnum, toolCallId: string | null, toolCalls: string | null, conversationUuid: string, createdAt: string }> } };

export type DeleteConversationMutationVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type DeleteConversationMutation = { __typename?: 'Mutation', deleteConversation: boolean };

export type GetConversationActionsQueryVariables = Exact<{
  conversationUuid: Scalars['ID']['input'];
  messageId: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetConversationActionsQuery = { __typename?: 'Query', getConversationActions: Array<{ __typename?: 'ConversationAction', label: string, properties: Array<{ __typename?: 'ConversationActionProperty', key: string, type: string, required: boolean }> }> };

export type PaginationFragmentFragment = { __typename?: 'PaginationResponse', total: number, perPage: number, currentPage: number, lastPage: number, from: number, to: number };

export type StudentFragmentFragment = { __typename?: 'Student', uuid: string, email: string | null, firstName: string, middleName: string, lastName: string | null, registrationGroup: string, admissionDate: string | null, doB: string | null, evisenseBandId: string, evisenseBandName: string, evisenseLastLogin: string | null, gender: GenderEnum | null, guardians: number, isSelected: boolean, suspended: boolean, upn: string, year: string, analytics: boolean, deleted: boolean, deletedDate: string | null, leftDate: string | null, idFields: Array<{ __typename?: 'StudentIdField', baseIDName: string, baseIDValue: string, baseIdUid: string, isPrimary: boolean }>, software: { __typename?: 'IndividualSoftwareAccess', hasCSAccess: boolean, hasCsamAccess: boolean, hasEviAccess: boolean, showInCs: boolean, showInEvi: boolean } };

export type IndexStudentsQueryVariables = Exact<{
  input: InputMaybe<StudentFilterInput>;
}>;


export type IndexStudentsQuery = { __typename?: 'Query', indexStudents: { __typename?: 'StudentPage', pagination: { __typename?: 'PaginationResponse', total: number, perPage: number, currentPage: number, lastPage: number, from: number, to: number }, items: Array<{ __typename?: 'Student', uuid: string, email: string | null, firstName: string, middleName: string, lastName: string | null, registrationGroup: string, admissionDate: string | null, doB: string | null, evisenseBandId: string, evisenseBandName: string, evisenseLastLogin: string | null, gender: GenderEnum | null, guardians: number, isSelected: boolean, suspended: boolean, upn: string, year: string, analytics: boolean, deleted: boolean, deletedDate: string | null, leftDate: string | null, idFields: Array<{ __typename?: 'StudentIdField', baseIDName: string, baseIDValue: string, baseIdUid: string, isPrimary: boolean }>, software: { __typename?: 'IndividualSoftwareAccess', hasCSAccess: boolean, hasCsamAccess: boolean, hasEviAccess: boolean, showInCs: boolean, showInEvi: boolean } }> } };

export type ReadStudentQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type ReadStudentQuery = { __typename?: 'Query', findStudent: { __typename?: 'Student', uuid: string, email: string | null, firstName: string, middleName: string, lastName: string | null, registrationGroup: string, admissionDate: string | null, doB: string | null, evisenseBandId: string, evisenseBandName: string, evisenseLastLogin: string | null, gender: GenderEnum | null, guardians: number, isSelected: boolean, suspended: boolean, upn: string, year: string, analytics: boolean, deleted: boolean, deletedDate: string | null, leftDate: string | null, idFields: Array<{ __typename?: 'StudentIdField', baseIDName: string, baseIDValue: string, baseIdUid: string, isPrimary: boolean }>, software: { __typename?: 'IndividualSoftwareAccess', hasCSAccess: boolean, hasCsamAccess: boolean, hasEviAccess: boolean, showInCs: boolean, showInEvi: boolean } } };

export type CreateStudentMutationVariables = Exact<{
  input: CreateStudentInput;
}>;


export type CreateStudentMutation = { __typename?: 'Mutation', createStudent: { __typename?: 'Student', uuid: string, email: string | null, firstName: string, middleName: string, lastName: string | null, registrationGroup: string, admissionDate: string | null, doB: string | null, evisenseBandId: string, evisenseBandName: string, evisenseLastLogin: string | null, gender: GenderEnum | null, guardians: number, isSelected: boolean, suspended: boolean, upn: string, year: string, analytics: boolean, deleted: boolean, deletedDate: string | null, leftDate: string | null, idFields: Array<{ __typename?: 'StudentIdField', baseIDName: string, baseIDValue: string, baseIdUid: string, isPrimary: boolean }>, software: { __typename?: 'IndividualSoftwareAccess', hasCSAccess: boolean, hasCsamAccess: boolean, hasEviAccess: boolean, showInCs: boolean, showInEvi: boolean } } };

export type EditStudentMutationVariables = Exact<{
  input: EditStudentInput;
}>;


export type EditStudentMutation = { __typename?: 'Mutation', editStudent: { __typename?: 'Student', uuid: string, email: string | null, firstName: string, middleName: string, lastName: string | null, registrationGroup: string, admissionDate: string | null, doB: string | null, evisenseBandId: string, evisenseBandName: string, evisenseLastLogin: string | null, gender: GenderEnum | null, guardians: number, isSelected: boolean, suspended: boolean, upn: string, year: string, analytics: boolean, deleted: boolean, deletedDate: string | null, leftDate: string | null, idFields: Array<{ __typename?: 'StudentIdField', baseIDName: string, baseIDValue: string, baseIdUid: string, isPrimary: boolean }>, software: { __typename?: 'IndividualSoftwareAccess', hasCSAccess: boolean, hasCsamAccess: boolean, hasEviAccess: boolean, showInCs: boolean, showInEvi: boolean } } };

export type DeleteStudentMutationVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type DeleteStudentMutation = { __typename?: 'Mutation', deleteStudent: boolean };

export type UserFragment = { __typename?: 'User', uuid: string, schoolUuid: string, emailAddress: string, firstName: string, lastName: string | null, schoolName: string, roleUuid: string, roleName: string | null, title: string | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginToken', accessToken: string, baseToken: string, refreshToken: string, isForcePasswordChange: boolean, dueToPolicyChange: boolean } };

export type LogErrorMutationVariables = Exact<{
  input: LogErrorInput;
}>;


export type LogErrorMutation = { __typename?: 'Mutation', logError: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', uuid: string, schoolUuid: string, emailAddress: string, firstName: string, lastName: string | null, schoolName: string, roleUuid: string, roleName: string | null, title: string | null } };


  import gql from "graphql-tag";
  export const typeDefs = gql`schema {
  query: Query
  mutation: Mutation
}

type Conversation {
  createdAt: DateTime!
  deletedAt: DateTime
  messages: [Message!]!
  nextActions: [ConversationAction!]!
  programUuid: ID!
  title: String!
  updatedAt: DateTime!
  uuid: ID!
}

type ConversationAction {
  label: String!
  properties: [ConversationActionProperty!]!
}

type ConversationActionProperty {
  key: String!
  required: Boolean!
  type: String!
}

input ConversationFilterFunctionInput {
  isDeleted: Boolean
}

input ConversationFilterInput {
  filters: ConversationFilterFunctionInput
  orderBy: ConversationOrderByInput
  pagination: PaginationInput
}

enum ConversationOrderByEnum {
  createdAtAsc
  createdAtDesc
}

input ConversationOrderByInput {
  order: ConversationOrderByEnum
}

type ConversationPage {
  items: [Conversation!]!
  pagination: PaginationResponse!
}

input CreateStudentInput {
  admissionDate: String!
  curriculumType: Int
  doB: String!
  email: String
  evisenseBandId: String
  evisenseBandName: String
  evisenseLastLogin: String
  firstName: String!
  gender: String!
  guardians: Int
  idFields: [IdBaseValueInput]
  isDeleted: Boolean!
  isSelected: Boolean
  lastName: String!
  leavingDate: String
  middleName: String
  nextYearGroupDetails: GroupToIndividualDetailsInput
  pendingInvite: Boolean!
  protected: Boolean!
  registrationGroup: String
  registrationGroupDetails: GroupToIndividualDetailsInput!
  software: SoftwareInput
  suspended: Boolean
  upn: String
  wasLastActiveInCurrentSchool: Boolean!
  year: String!
}

scalar DateTime

input EditConversationInput {
  title: String
  uuid: ID!
}

input EditStudentInput {
  admissionDate: String
  curriculumType: Int
  doB: String
  email: String
  evisenseBandId: String
  evisenseBandName: String
  evisenseLastLogin: String
  firstName: String
  gender: String
  guardians: Int
  idFields: [IdBaseValueInput]
  individualUID: ID!
  isDeleted: Boolean
  isSelected: Boolean
  lastName: String
  leavingDate: String
  middleName: String
  nextYearGroupDetails: GroupToIndividualDetailsInput
  pendingInvite: Boolean
  protected: Boolean
  registrationGroup: String
  registrationGroupDetails: GroupToIndividualDetailsInput!
  software: SoftwareInput
  suspended: Boolean
  upn: String
  wasLastActiveInCurrentSchool: Boolean
  year: String
}

enum ErrorLevel {
  fatal
  information
}

enum GenderEnum {
  f
  m
}

input GroupToIndividualDetailsInput {
  groupUID: String!
  joinDate: String!
  leftDate: String
}

input IdBaseValueInput {
  baseIDName: String
  baseIDValue: String
  baseIdUid: String
  isPrimary: Boolean
}

type IndividualSoftwareAccess {
  hasCSAccess: Boolean!
  hasCsamAccess: Boolean!
  hasEviAccess: Boolean!
  showInCs: Boolean!
  showInEvi: Boolean!
}

input LogErrorInput {
  errorLevel: ErrorLevel!
  message: String!
  schoolUuid: ID
  screen: String!
  softwareId: Int!
  stack: String
  userUuid: ID
}

input LoginInput {
  email: String!
  password: String!
  programMode: Int!
}

type LoginToken {
  accessToken: String!
  baseToken: String!
  dueToPolicyChange: Boolean!
  isForcePasswordChange: Boolean!
  refreshToken: String!
}

type Message {
  conversationUuid: ID!
  createdAt: DateTime!
  role: RoleEnum!
  text: String
  toolCallId: String
  toolCalls: String
  uuid: ID!
}

type Mutation {
  createStudent(input: CreateStudentInput!): Student!
  deleteConversation(uuid: ID!): Boolean!
  deleteStudent(uuid: ID!): Boolean!
  editConversation(input: EditConversationInput!): Conversation!
  editStudent(input: EditStudentInput!): Student!
  logError(input: LogErrorInput!): Boolean!
  login(input: LoginInput!): LoginToken!
  sendMessage(input: SendMessageInput!): Conversation!
}

input PaginationInput {
  offset: Int
  page: Int!
  perPage: Int!
}

type PaginationResponse {
  currentPage: Int!
  from: Int!
  lastPage: Int!
  page: Int!
  perPage: Int!
  to: Int!
  total: Int!
}

type Query {
  findConversation(uuid: ID!): Conversation!
  findStudent(uuid: ID!): Student!
  getConversationActions(conversationUuid: ID!, messageId: ID): [ConversationAction!]!
  indexConversations(input: ConversationFilterInput): ConversationPage!
  indexStudents(input: StudentFilterInput): StudentPage!
  me: User!
}

enum RoleEnum {
  assistant
  system
  tool
  user
}

input SendMessageInput {
  conversationUuid: ID
  text: String!
}

input SoftwareInput {
  hasCSAccess: Boolean
  hasCsamAccess: Boolean
  hasEviAccess: Boolean
  showInCS: Boolean
  showInEvi: Boolean
}

type Student {
  admissionDate: DateTime
  analytics: Boolean!
  deleted: Boolean!
  deletedDate: DateTime
  doB: DateTime
  email: String
  evisenseBandId: ID!
  evisenseBandName: String!
  evisenseLastLogin: DateTime
  firstName: String!
  gender: GenderEnum
  guardians: Int!
  idFields: [StudentIdField!]!
  isSelected: Boolean!
  lastName: String
  leftDate: DateTime
  middleName: String!
  registrationGroup: String!
  software: IndividualSoftwareAccess!
  suspended: Boolean!
  upn: String!
  uuid: ID!
  year: String!
}

input StudentFilterFunctionInput {
  groupUuid: ID
  status: StudentStatusEnum
  term: String
}

input StudentFilterInput {
  filters: StudentFilterFunctionInput
  orderBy: StudentOrderByInput
  pagination: PaginationInput
}

type StudentIdField {
  baseIDName: String!
  baseIDValue: String!
  baseIdUid: ID!
  isPrimary: Boolean!
}

enum StudentOrderByEnum {
  dobAsc
  dobDesc
  firstNameAsc
  firstNameDesc
  lastNameAsc
  lastNameDesc
  stampAsc
  stampDesc
}

input StudentOrderByInput {
  order: StudentOrderByEnum
}

type StudentPage {
  items: [Student!]!
  pagination: PaginationResponse!
}

enum StudentStatusEnum {
  active
  future
  historic
  notInGroup
  pending
}

type User {
  emailAddress: String!
  firstName: String!
  lastName: String
  roleName: String
  roleUuid: ID!
  schoolName: String!
  schoolUuid: ID!
  title: String
  uuid: ID!
}`;
