/* eslint-disable import/named */
import { Fragment } from 'react';
import { EmptyState } from '../../../components/atoms/EmptyState';
import { DefaultHead } from '../DefaultHead';
import { FileRoutesByPath, useNavigate } from '@tanstack/react-router';
import { Button } from '@headlessui/react';
import { elements } from '../../../util/styles';
import classNames from 'classnames';

interface Props {
  message?: string;
  to: To;
}

export type To = FileRoutesByPath[keyof FileRoutesByPath]['fullPath'];

const Error404 = ({ message, to }: Props) => {
  const navigate = useNavigate();

  return <Fragment>
    <DefaultHead title="404 Not Found" />
    <div className="flex items-center flex-col justify-center h-[100dvh] w-screen">
      <EmptyState img="/images/404.svg" hideText={!message} text={message} />
      <Button
        type="button"
        className={classNames(elements.button.primary, 'mt-4')}
        onClick={() => void navigate({ to })}
      >
        Go Back
      </Button>
    </div>
  </Fragment>;
};

export default Error404;
