import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface Props {
  size?: string;
  message?: string;
  className?: string;
}

export const Loader = ({
  size = '2.5rem',
  message,
  className = 'text-blue',
}: Props) => {
  const [dots, setDots] = useState('');
  useEffect(() => {
    if (message) {
      const interval = setInterval(() => {
        setDots((d) => {
          const newDots = `${d}.`;
          return newDots === '...' ? '' : newDots;
        });
      }, 250);
      return () => {
        clearInterval(interval);
      };
    }
  }, [message]);
  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <svg
        className="animate-spin"
        viewBox="0 0 24 24"
        style={{
          width: size,
          height: size,
        }}
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
          fill="transparent"
        />
        <circle cx="12" cy="12" r="8" fill="transparent" />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      {!!message && (
        <p>
          {message}
          {dots}
        </p>
      )}
    </div>
  );
};
