export type DATE = `${number}-${string}-${string}`;

declare global {
  interface Date {
    toNiceFormat: (fullMonthLabel?: boolean) => string;
    toDateFormat: () => DATE;
    toNiceTimeFormat: (use24Hour?: boolean) => string;
    toNiceDateTimeFormat: (
      use24Hour?: boolean,
      fullMonthLabel?: boolean
    ) => string;
    getMonthLabel: () => string;
    getFullMonthLabel: () => string;
    getFirstDayOfWeek: () => Date;
    getLastDayOfWeek: () => Date;
    daysSince: (d: Date) => number;
    getLastDayOfMonth: () => Date;
    addDays: (d: number) => Date;
    since: () => string;
  }
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const fullMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const pad = (n: number) => String('00' + n.toString()).slice(-2);

Date.prototype.toDateFormat = function () {
  return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(
    this.getDate()
  )}`;
};

Date.prototype.toNiceTimeFormat = function (use24Hour?: boolean) {
  const amPm = this.getHours() >= 12 ? 'pm' : 'am';
  return `${pad(this.getHours() % (use24Hour ? 24 : 12))}:${pad(
    this.getMinutes()
  )}${!use24Hour ? amPm : ''}`;
};

Date.prototype.toNiceFormat = function (fullMonthLabel?: boolean) {
  return `${this.getDate()} ${
    fullMonthLabel ? this.getFullMonthLabel() : this.getMonthLabel()
  } ${this.getFullYear()}`;
};

Date.prototype.toNiceDateTimeFormat = function (
  use24Hour?: boolean,
  fullMonthLabel?: boolean
) {
  return `${this.toNiceFormat(fullMonthLabel)} at ${this.toNiceTimeFormat(
    use24Hour
  )}`;
};

Date.prototype.getMonthLabel = function () {
  return months[this.getMonth()];
};

Date.prototype.getFullMonthLabel = function () {
  return fullMonths[this.getMonth()];
};

Date.prototype.getFirstDayOfWeek = function () {
  return new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate() - this.getDay()
  );
};

Date.prototype.getLastDayOfWeek = function () {
  return new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate() - this.getDay() + 6
  );
};

Date.prototype.daysSince = function (date: Date) {
  return Math.round(
    Math.abs(this.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
  );
};

Date.prototype.getLastDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.since = function () {
  const seconds = Math.floor((new Date().getTime() - this.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 0) {
    return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  }
  if (seconds < 30) {
    return 'Just Now';
  }
  return `${Math.floor(seconds)} seconds ago`;
};
