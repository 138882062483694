import { prepareFile } from '../../../../util/request';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface BlurCoordinate {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  blurSize: number;
}

const generateCirclePoints = (
  cx: number,
  cy: number,
  radius: number,
  numPoints: number
): string => {
  const points: string[] = [];
  for (let i = 0; i < numPoints; i++) {
    const theta = (2 * Math.PI * i) / numPoints;
    const x = cx + radius * Math.cos(theta);
    const y = cy + radius * Math.sin(theta);
    points.push(`${x},${y}`);
  }
  return points.join(' ');
};

function svgToBase64(svgElement: SVGElement) {
  const svg = new XMLSerializer().serializeToString(svgElement);
  const base64 = window.btoa(decodeURIComponent(encodeURIComponent(svg)));
  return 'data:image/svg+xml;charset=utf-8;base64,' + base64;
}
function createSvg(
  src: string,
  width: number,
  height: number,
  points: string[],
  stdDeviation: number
) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', width.toString());
  svg.setAttribute('height', height.toString());
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('preserveAspectRatio', 'xMidYMid slice');
  svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

  if (points.length) {
    const clipPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'clipPath'
    );
    clipPath.setAttribute('id', 'mask');
    points.forEach((point) => {
      const polygon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'polygon'
      );
      polygon.setAttribute('points', point);
      clipPath.appendChild(polygon);
    });
    svg.appendChild(clipPath);

    const filter = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'filter'
    );
    filter.setAttribute('id', 'blur');
    const feGaussianBlur = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'feGaussianBlur'
    );
    feGaussianBlur.setAttribute('in', 'SourceGraphic');
    feGaussianBlur.setAttribute('stdDeviation', stdDeviation.toString());
    filter.appendChild(feGaussianBlur);
    svg.appendChild(filter);
  }

  const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
  image.setAttribute('height', height.toString());
  image.setAttribute('width', width.toString());
  svg.appendChild(image);
  image.setAttribute('href', src);

  if (points.length) {
    const image2 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'image'
    );
    image2.setAttribute('clip-path', 'url(#mask)');
    image2.setAttribute('filter', 'url(#blur)');
    image2.setAttribute('height', height.toString());
    image2.setAttribute('width', width.toString());
    svg.appendChild(image2);
    image2.setAttribute('href', src);
  }

  return svg;
}

export interface BlurredImageRef {
  onSave: () => string;
}

interface Props {
  src: string;
  blurCoordinates: BlurCoordinate[];
  setBlurCoordinates: Dispatch<SetStateAction<BlurCoordinate[]>>;
  blurDegree: number;
  blurSize: number;
}

export const BlurredImage = forwardRef<BlurredImageRef, Props>(
  ({ src, blurCoordinates, setBlurCoordinates, blurDegree, blurSize }, ref) => {
    const [b64, setB64] = useState<string | undefined>();
    useImperativeHandle(
      ref,
      () => ({
        onSave() {
          return b64 ?? src;
        },
      }),
      [b64, src]
    );
    const imageRef = useRef<HTMLImageElement>(null);
    const [dimensions, setDimensions] = useState<{
      width: number;
      height: number;
    }>();

    // Refs to cache the start and current coordinates
    const startCoordinatesRef = useRef<BlurCoordinate | null>(null);

    const blurPoints = useMemo(
      () =>
        blurCoordinates.map(({ x0, y0, x1, y1, blurSize: blSize }) => {
          const cx = (x0 + x1) / 2;
          const cy = (y0 + y1) / 2;

          const radius =
            Math.min(Math.abs(x1 - x0), Math.abs(y1 - y0)) / 2 + blSize / 2;

          const numPoints = 25; // Adjust this value as needed

          return generateCirclePoints(cx, cy, radius, numPoints);
        }),
      [blurCoordinates]
    );

    useEffect(() => {
      void fetch(src).then(async (res) => {
        const blob = await res.blob();
        const base64 = await prepareFile(blob);
        setB64(base64);
        if (imageRef.current) {
          imageRef.current.onload = function () {
            const width = imageRef.current?.naturalWidth ?? 0;
            const height = imageRef.current?.naturalHeight ?? 0;
            setDimensions({
              width,
              height,
            });
            this.onload = null;
          };
        }
      });
    }, [src]);

    useEffect(() => {
      if (blurPoints.length && imageRef.current) {
        const width = imageRef.current.naturalWidth;
        const height = imageRef.current.naturalHeight;
        const svg = createSvg(
          imageRef.current.src,
          width,
          height,
          blurPoints,
          blurDegree
        );
        const svgData = svgToBase64(svg);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        const image = new Image();
        image.onload = () => {
          context?.drawImage(image, 0, 0, canvas.width, canvas.height);
          setB64(canvas.toDataURL('image/png', 1));
        };
        image.src = svgData;
      }
    }, [blurPoints, blurDegree]);

    const [listenToDrag, setListenToDrag] = useState(false);

    const onMouseDown = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (imageRef.current) {
          setListenToDrag(true);

          const { width } = imageRef.current;
          const { height } = imageRef.current;

          const imageRect = imageRef.current.getBoundingClientRect();
          const imageX = event.clientX - imageRect.left;
          const imageY = event.clientY - imageRect.top;
          const bestX = imageX < 0 ? 0 : imageX > width ? width : imageX;
          const bestY = imageY < 0 ? 0 : imageY > height ? height : imageY;

          // Cache the initial coordinates
          startCoordinatesRef.current = {
            x0: bestX,
            y0: bestY,
            x1: bestX,
            y1: bestY,
            blurSize,
          };
        }
      },
      [blurSize]
    );

    const onMouseMove = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (listenToDrag && startCoordinatesRef.current && imageRef.current) {
          const { width } = imageRef.current;
          const { height } = imageRef.current;

          const imageRect = imageRef.current.getBoundingClientRect();
          const imageX = event.clientX - imageRect.left;
          const imageY = event.clientY - imageRect.top;

          const bestX = imageX < 0 ? 0 : imageX > width ? width : imageX;
          const bestY = imageY < 0 ? 0 : imageY > height ? height : imageY;

          // Update the current coordinates but don't trigger state change yet
          startCoordinatesRef.current.x1 = bestX;
          startCoordinatesRef.current.y1 = bestY;
        }
      },
      [listenToDrag]
    );

    const onMouseUp = useCallback(() => {
      setListenToDrag(false);

      if (startCoordinatesRef.current && imageRef.current) {
        const { width } = imageRef.current;

        const scale = (dimensions?.width ?? width) / width;

        // Finalize and scale the coordinates
        const finalCoordinates: BlurCoordinate = {
          x0: startCoordinatesRef.current.x0 * scale,
          y0: startCoordinatesRef.current.y0 * scale,
          x1: startCoordinatesRef.current.x1 * scale,
          y1: startCoordinatesRef.current.y1 * scale,
          blurSize: startCoordinatesRef.current.blurSize * scale,
        };

        // Update the state with final coordinates
        setBlurCoordinates((coords) => [...coords, finalCoordinates]);

        // Clear the cached coordinates
        startCoordinatesRef.current = null;
      }
    }, [dimensions, setBlurCoordinates]);

    return (
      <div
        className="w-full cursorable"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <img
          src={b64}
          className="select-none"
          ref={imageRef}
          draggable={false}
          alt="blurred"
        />
      </div>
    );
  }
);
