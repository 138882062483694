import classNames from 'classnames';

interface Props {
  padding?: boolean;
  children: React.ReactNode;
  scroll?: boolean;
}

export const ModalBody = ({ padding, children, scroll = true }: Props) => (
  <>
    <div
      className={classNames('flex flex-col grow', {
        'py-6 px-6': padding,
        scroll,
      })}
    >
      {children}
    </div>
  </>
);
