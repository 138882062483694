import { Avatar } from '../../atoms/Avatar';
import { AvatarWithLabel } from './AvatarWithLabel';
import { getColor } from '../../../util/styles';
import { FiUsers } from 'react-icons/fi';

interface Props {
  name?: string;
  size?: string;
  count?: number;
  onClick?: () => void;
}

export const UserAvatarWithoutSrc = ({
  name,
  size,
  count,
  onClick,
  white,
}: Props & {
  white?: boolean;
}) => (
  <AvatarWithLabel
    size={size}
    bgColor={getColor('darkBlue')}
    Icon={!name ? FiUsers : undefined}
    label={
      name
        ? name
            .split(' ')
            .map((part) => part[0])
            .join('')
            .slice(0, 3)
        : count
          ? `${count} St.`
          : ''
    }
    onClick={onClick}
    white={white}
  />
);

export const UserAvatar = ({ src, name, size, count, onClick }: Props & {
  src?: string;
}) =>
    src ? (
      <Avatar
        src={
          src.startsWith('data:image') ||
          src.startsWith('https://') ||
          src.startsWith('http://')
            ? src
            : `data:image/png;base64, ${src}`
        }
        size={size}
        onClick={onClick}
      />
    ) : (
      <UserAvatarWithoutSrc
        name={name}
        size={size}
        count={count}
        onClick={onClick}
      />
    );
