import { useSuspenseQuery } from '@apollo/client';
import ChatView from './ChatView';
import { GqlConversation, GqlIndexConversations, GqlMessage } from '@board/resources';
import { getFragmentData, RoleEnum } from '@board/graphql';
import { Link } from '@tanstack/react-router';
import { Suspense } from 'react';
import { Loader } from '../../atoms/Loader';
import { FiEdit } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';

const List = ({
  uuid
}: {
  uuid?: string;
}) => {
  const { data } = useSuspenseQuery(GqlIndexConversations);

  return (
    <>
      {data.indexConversations.items.map((c) => {
        const chat = getFragmentData(GqlConversation, c);
        const firstMessage = chat.messages.map(m => getFragmentData(GqlMessage, m)).find(m => m.role === RoleEnum.assistant || m.role === RoleEnum.user);
        return (
          <Link to="/conversations/$uuid" params={{
            uuid: chat.uuid
          }} key={chat.uuid} className={`flex border-b border-gray p-4 ${
            uuid === chat.uuid ? 'bg-lightBlue text-blue' : ''
          }`}>
            <div className="flex grow justify-between items-center">
              <div className="flex flex-col grow">
                <h4
                  className={`text-base font-semibold`}
                >
                  {chat.title}
                </h4>
                {firstMessage && (
                  <>
                    <p
                      className={`text-sm`}
                    >
                      {firstMessage.role === RoleEnum.user && <span>you: </span>}
                      {formatOverviewMessage(
                        firstMessage.text ?? ''
                      )}
                      <span className="font-normal text-midGray">
                        {' '}
                        •{' '}
                        {new Date(
                          chat.updatedAt
                        ).toNiceDateTimeFormat()}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};

function formatOverviewMessage(text: string): string {
  const normalized = text.trim();
  const newLine = normalized.indexOf('\n');
  if (newLine === -1) return normalized;

  return `${normalized.substr(0, newLine)}...`;
}

const Messages = ({ uuid }: { uuid?: string }) => (
  <div className="w-full flex grow">
    <div className="flex flex-col overflow-y-auto w-[20rem]">
      <div className='p-4 border-b border-gray flex justify-between'>
        <h5 className='font-semibold'>Conversations</h5>
        <Link to="/conversations" data-tooltip-id='new-conversation'>
          <FiEdit size={20} />
          <Tooltip id='new-conversation' place='right'>
            Start a new conversation
          </Tooltip>
        </Link>
      </div>
      <Suspense fallback={<Loader className='py-4 text-blue' />}>
        <List uuid={uuid} /> 
      </Suspense>
    </div>
    <div
      className="flex flex-col grow border-l border-gray basis-0"
    >
      <ChatView
        uuid={uuid}
      />
    </div>
  </div>
);

export default Messages;

