import jsCookie from 'js-cookie';
import localForage from 'localforage';
import { cache } from './cache';
import { queryClient } from '../context/QueryContext';

export interface EnvVars {
  apiUrl: string;
  baseApiUrl: string;
  loginFeedUrl: string;
  newsFeedUrl: string;
  termsUrl: string;
  privacyPolicyUrl: string;
  supportUrl: string;
  geoRegion?: string;
  geoPlacename?: string;
  loginErrorUrl?: string;
  timeoutMins: number;
  beta: boolean;
  softwareId: number;
}

export const paramsToObject = (entries: IterableIterator<[string, string]>) => {
  const result: Record<string, string | undefined> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const redirect = (url: string) => {
  (
    window as unknown as {
      location: string;
    }
  ).location = url;
};

export const logout = (redirectUrl = '') => {
  localStorage.clear();
  jsCookie.remove('x-base-token');
  jsCookie.remove('x-access-token');
  jsCookie.remove('x-refresh-token');
  void queryClient.clear();
  void cache.reset().finally(() => {
    cache.gc();
  });
  void localForage.clear().finally(() => {
    if (redirectUrl) {
      localStorage.setItem('redirect', redirectUrl);
    }
    redirect('/login');
  });
};

export const fetchEnvVars = async () =>
  (await (await fetch('/config/vars')).json()) as unknown as EnvVars;

export const prepareBase64 = async (base64: string): Promise<Blob> => {
  const res = await fetch(base64);
  const blob = await res.blob();
  return blob;
};

export const prepareFile = async (item: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item);

    reader.onload = () => {
      const newFileBase = reader.result;
      resolve(newFileBase as string);
    };

    reader.onerror = (e) => {
      reject(new Error('Error reading file'));
    };
  });

