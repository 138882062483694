import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Loader } from '../../../components/atoms/Loader';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Button as HButton,
} from '@headlessui/react';
import { ReactTag } from '@headlessui/react/dist/types';
import { elements } from '../../../util/styles';

export * from './ModalBody';

interface Props {
  onClose?: (e: boolean) => void;
  closeOnBackdropClick?: boolean;
  negativeButton?: string;
  negativeButtonWidth?: string;
  onNegative?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  positiveButton?: string;
  positiveButtonWidth?: string;
  positiveButtonDisabled?: boolean;
  onPositive?: (e: React.FormEvent) => void;
  secondaryButton?: string;
  secondaryButtonWidth?: string;
  secondaryButtonDisabled?: boolean;
  negativeButtonDisabled?: boolean;
  onSecondary?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onInitial?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  initialButton?: string;
  initialButtonWidth?: string;
  title?: string;
  children: ReactNode;
  BottomLeft?: () => React.JSX.Element;
  onBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  footerBorder?: string;
  footerAlign?: string;
  footerBgColor?: string;
  footerPadding?: string;
  footerRounded?: boolean;
  breadcrumbs?: string[];
  breadcrumbActive?: string;
  onBreadcrumb?: (b: string) => void;
  onScroll?: (e: React.UIEvent<HTMLFormElement>) => void;
  loading?: boolean;
  grow?: boolean;
  showScrollbar?: boolean;
  mounted: boolean;
  as?: ReactTag;
  width?: string;
}

export const Modal = (props: Props) => (
  <Dialog
    open={props.mounted}
    className="relative z-10 focus:outline-none"
    onClose={props.closeOnBackdropClick && props.onClose ? props.onClose : (() => null)}
    transition
  >
    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
      <DialogBackdrop className="fixed inset-0 bg-black/30" transition />
      <DialogPanel
        transition
        as={props.as ?? 'form'}
        className={classNames(
          'rounded max-h-full shadow-lg bg-white max-w-[90vw] flex flex-col backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0',
          {
            'grow': props.grow,
          }
        )}
        style={{
          width: props.width ?? '40rem',
        }}
        onScroll={props.onScroll}
        onSubmit={
          (props.as ?? 'form') !== 'div'
            ? (e: React.FormEvent) => {
                e.preventDefault();
                props.onPositive?.(e);
              }
            : undefined
        }
      >
        <div className="head py-3.5 px-6 border-b border-b-solid border-b-gray flex items-center justify-between">
          <div className="title flex items-center justify-center">
            {!!props.onBack && (
              <HButton
                type="button"
                className="back cursor-pointer mr-[0.313rem] flex items-center justify-center text-black"
                onClick={props.onBack}
              >
                <FiChevronLeft size={20} />
              </HButton>
            )}
            <h2 className="m-0 font-normal text-lg">{props.title}</h2>
            {!!props.breadcrumbs?.length && (
              <div className="flex items-center">
                {props.breadcrumbs.map((item, index) => (
                  <div
                    key={item}
                    className="breadcrumbs flex items-center justify-center mr-3 last:mr-0"
                  >
                    <h2
                      onClick={() => props.onBreadcrumb?.(item)}
                      className={classNames(
                        'breadcrumb m-0 font-normal text-lg cursor-pointer',
                        {
                          'text-black': props.breadcrumbActive === item,
                          'text-milkyGray': props.breadcrumbActive !== item,
                        }
                      )}
                    >
                      {item}
                    </h2>
                    {index !== (props.breadcrumbs?.length ?? 0) - 1 && (
                      <div className="icon w-3.5 h-[0.4375rem] text-milkyGray my-2.5 ml-3 flex items-center justify-center">
                        <FiChevronRight size={20} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {!!props.onClose && (
            <HButton
              type="button"
              onClick={() => props.onClose?.(false)}
              className="close flex cursor-pointer text-milkyGray"
            >
              <IoMdClose size={24} />
            </HButton>
          )}
        </div>
        <div
          className="body grow flex flex-col overflow-y-auto scrollbar-thin"
          style={{
            scrollbarWidth: props.showScrollbar ? 'thin' : undefined,
          }}
        >
          {props.mounted && props.children}
        </div>
        <div
          className={classNames(
            'foot border border-gray justify-end flex empty:hidden rounded-b',
            {
              rounded: props.footerRounded,
            }
          )}
          style={{
            padding: props.footerPadding ?? '1rem 1.5rem',
            backgroundColor: props.footerBgColor,
            justifyContent: props.footerAlign ?? 'flex-end',
            borderWidth: props.footerBorder ?? 1,
          }}
        >
          {!!props.initialButton && (
            <div className="initial justify-start">
              <div className="ml-3">
                <Button
                  type="button"
                  onClick={props.onInitial}
                  className={elements.button.secondary}
                  style={{
                    width: props.initialButtonWidth ?? '6rem',
                  }}
                >
                  {props.initialButton}
                </Button>
              </div>
            </div>
          )}
          {!!props.BottomLeft && <props.BottomLeft />}
          {(!!props.negativeButton || !!props.positiveButton) &&
            !props.loading && (
              <div className="actions flex">
                {!!props.negativeButton && (
                  <div className="ml-3">
                    <Button
                      type="button"
                      onClick={
                        props.onNegative ?? (() => props.onClose?.(false))
                      }
                      className={props.negativeButtonDisabled ? elements.button.disabled : elements.button.tertiary}
                      style={{
                        width: props.negativeButtonWidth ?? '5rem',
                      }}
                      disabled={!!props.negativeButtonDisabled}
                    >
                      {props.negativeButton}
                    </Button>
                  </div>
                )}
                {!!props.secondaryButton && (
                  <div className="ml-3">
                    <Button
                      type="button"
                      onClick={
                        props.onSecondary ?? (() => props.onClose?.(false))
                      }
                      className={props.secondaryButtonDisabled ? elements.button.disabled : elements.button.secondary}
                      style={{
                        width: props.secondaryButtonWidth ?? '8rem',
                      }}
                      disabled={!!props.secondaryButtonDisabled}
                    >
                      Back
                    </Button>
                  </div>
                )}
                {!!props.positiveButton && (
                  <div className="ml-3">
                    <Button
                      type="submit"
                      onClick={
                        props.as === 'div' ? props.onPositive : undefined
                      }
                      className={props.positiveButtonDisabled ? elements.button.disabled : elements.button.primary}
                      style={{
                        width: props.positiveButtonWidth ?? '8rem',
                      }}
                      disabled={!!props.positiveButtonDisabled}
                    >
                      {props.positiveButton}
                    </Button>
                  </div>
                )}
              </div>
            )}
        </div>
        {!!props.loading && (
          <div className="relative flex items-center justify-center">
            <Loader />
          </div>
        )}
      </DialogPanel>
    </div>
  </Dialog>
);
