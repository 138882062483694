import classNames from 'classnames';

interface Props {
  src: string;
  size?: string;
  alt?: string;
  onClick?: () => void;
}

export const Avatar = ({ src, size = '3rem', alt, onClick }: Props) => (
  <div
    onClick={onClick}
    style={{ width: size, height: size }}
    className={classNames('avatar shrink-0 rounded-full', {
      clickable: !!onClick,
      'cursor-pointer': !!onClick,
    })}
  >
    <img
      src={src}
      className="w-full h-full rounded-full object-cover object-center"
      alt={alt}
    />
  </div>
);
