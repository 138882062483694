import { Modal, ModalBody } from "../../../molecules/Modal";
import { FaLaptop, FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";


const ModalInner = () => (
    <ModalBody padding>
        <p className="mb-2">Please contact our support team via email or phone. You can also book a 15-minute Q&A meeting with one of our team.</p>
        <p className="mb-12">Our support hours are Monday to Friday, 9am to 5pm.</p>
        <div className="flex items-center justify-between -mx-4">
            <div className="px-4 w-1/3">
                <a href="mailto:support@bsquared.co.uk" className="btn btn-primary flex flex-col items-center text-center" target="_blank">
                    <IoMail size={36} />
                    <h5 className="text-base mt-3">Send us an email</h5>
                    <span className="text-sm">support@bsquared.co.uk</span>
                </a>
            </div>
            <div className="px-4 w-1/3">
                <a href="https://calendly.com/b-squared-support/15-minute-support-session" className="btn btn-primary flex flex-col items-center text-center" target="_blank">
                    <FaLaptop size={36} />
                    <h5 className="text-base mt-3">Book a 15 minute support session</h5>
                    <span className="text-sm">Click here to book a time</span>
                </a>
            </div>
            <div className="px-4 w-1/3">
                <a href="tel:+441252870133" className="btn btn-primary flex flex-col items-center text-center" target="_blank">
                    <FaPhone size={36} />
                    <h5 className="text-base mt-3">Call us</h5>
                    <span className="text-sm">01252 870133</span>
                </a>
            </div>
        </div>
    </ModalBody>
);

interface Props {
    mounted: boolean;
    onClose: () => void;
}

export const ContactModal = ({
    mounted,
    onClose
}: Props) => (
    <Modal
        mounted={mounted}
        title="Contact Support"
        onClose={onClose}
        width="60rem"
    >
        <ModalInner />
    </Modal>
);