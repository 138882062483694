import { z } from 'zod';

export interface LoginRequestParams {
  grant_type: 'password' | 'refresh_token';
  username: string;
  password: string;
  loginToSchoolGuid?: string;
  accessToken?: string;
  programMode?: number;
}

export const LoginResponseSchema = z.object({
  access_token: z.string(),
  token_type: z.string(),
  expires_in: z.number(),
  replaced_existing_session: z.string(),
  is_force_change_password: z.string(),
  due_to_password_policy: z.string(),
  is_multi_school_account: z.string(),
  '.issued': z.string(),
  '.expires': z.string(),
});

export const SessionInfoSchema = z.object({
  userUID: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  title: z.string().nullable().optional(),
  email: z.string(),
  photo: z.string().nullable(),
  roleUID: z.string(),
  roleGroupUID: z.string(),
  roleName: z.string().nullable(),
  schoolUID: z.string(),
  schoolName: z.string(),
  schoolLogo: z.string().nullable(),
  userHasConnectingStepsAccess: z.boolean(),
  userHasMyBSquaredAccess: z.boolean(),
  isSchoolLicensedConnectingSteps: z.boolean(),
  activeRoles: z.array(z.object({
    roleGuid: z.string(),
    roleName: z.string(),
  })),
  schoolHasFrameworks: z.boolean(),
  guardianCanSeeLinkedAssessments: z.boolean(),
  user_level: z.number().optional(),
  beta: z.boolean().optional(),
  countryCode: z.string().optional(),
  subCountry: z.string().optional(),
  isSafeguardingLead: z.boolean().optional(),
  protectedStudentsUIDs: z.array(z.string()).optional(),
});

export type SessionInfo = z.infer<typeof SessionInfoSchema>;

export interface BsqErrorLogParams {
  softwareId?: number;
  errorId?: number;
  errorLevel: string;
  iisServerName: string;
  schoolUid?: string;
  individualUid?: string;
  screenUid?: string;
  apiName: string;
  message?: string;
  stack_Trace?: string;
}

export interface SetUserPasswordParams {
  email: string;
  password: string;
  newPassword: string;
}

export interface SetUserDetailsParams {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  password: string;
}

export interface DataCalculateParams {
  IndividualGuid?: string;
  SchoolGuid: string;
  AllData: boolean;
  ReuseStudentFrameworkDates: boolean;
}

export const SetUserPasswordSchema = z.object({
  hasNameChangeFailed: z.boolean(),
  hasChangePasswordFailed: z.boolean(),
  hasChangeEmailFailed: z.boolean(),
  isOldPasswordCorrect: z.boolean(),
  isNewPasswordFormatValid: z.boolean(),
  isNewEmailInUse: z.boolean(),
  isNewEmailFormatValid: z.boolean(),
  isAdminAndNotAllowedToChangeEmail: z.boolean(),
});

export const SetUserDetailsResponseSchema = z.object({
  incorrectPasswordError: z.boolean(),
  newEmailInUseError: z.boolean(),
  newEmailInvalidError: z.boolean(),
  adminNotAllowedToChangeEmailError: z.boolean(),
});

export const IndividualThumbnailSchema = z
  .array(
    z.object({
      individualUID: z.string(),
      thumbnail: z.string(),
    })
  )
  .nullable();
