/* eslint-disable @typescript-eslint/only-throw-error */
import * as React from 'react';
import { Outlet, createRootRouteWithContext, redirect } from '@tanstack/react-router';
import { PageLoader } from '../components/atoms/PageLoader';
import Error404 from '../components/atoms/Page404';

const openRoutes = ['/login'];
const skippedRoutes: string[] = [];

export const Route = createRootRouteWithContext<{ isAuthenticated: boolean }>()({
  beforeLoad: ({ location, context }) => {
    if (!skippedRoutes.includes(location.pathname)) {
      if (context.isAuthenticated) {
        if (openRoutes.includes(location.pathname)) {
          throw redirect({
            to: '/',
          });
        }
      } else {
        if (!openRoutes.includes(location.pathname)) {
          throw redirect({
            to: '/login',
          });
        }
      }
    }
  },
  component: () => <React.Suspense fallback={<PageLoader />}><Outlet /></React.Suspense>,
  notFoundComponent: () => <Error404 to="/" />,
});
