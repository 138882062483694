import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { PageLoader } from './components/atoms/PageLoader';
import { AuthProvider, useAuth } from './context/AuthContext';
import { useEffect } from 'react';
import { useLogError } from './hooks/useLogError';

const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: false,
  },
  defaultPendingMs: 0,
  defaultGcTime: 0,
  defaultPendingComponent: PageLoader,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  // eslint-disable-next-line
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const {
    accessToken,
    user
  } = useAuth();

  const logError = useLogError();

  useEffect(() => {

    const onUnhandleRejection = (event: PromiseRejectionEvent) => {
      void (async () => {
        const error =
            event.reason instanceof Error
              ? event.reason
              : event.reason instanceof Response
                ? new Error(await event.reason.text())
                : new Error('Unknown error');
        logError(error);
      })();
    };

    window.addEventListener('unhandledrejection', onUnhandleRejection);

    return () => {
      window.removeEventListener('unhandledrejection', onUnhandleRejection);
    };
  }, [logError]);

  return (
    <RouterProvider
      defaultPreload="intent"
      context={{ isAuthenticated: !!accessToken && !!user?.uuid }}
      router={router}
    />
  );
};

export const ProtectedApp = () => (
  <AuthProvider>
    <InnerApp />
  </AuthProvider>
);
