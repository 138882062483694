import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchEnvVars } from "../util/request";

export const useEnvVars = () => {
    const { data } = useSuspenseQuery({
      queryKey: ['envVars'],
      queryFn: async () => {
        const vars = await fetchEnvVars();
  
        return vars;
      },
    });
  
    return data;
  };
  