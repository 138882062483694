import { toast } from 'react-toastify';
import { Id, ToastProps } from 'react-toastify/dist/types';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@headlessui/react';

interface MsgProps {
  title: string;
  text?: string;
  onClick?: () => void;
}

export const Msg = ({ title, text, onClick }: MsgProps) => (
  <Button type="button" onClick={onClick} className="text-center w-full">
    <p className="text-white text-base">
      <strong>{title}:</strong> {text}
    </p>
  </Button>
);

export const toaster = (
  myProps: MsgProps,
  toastProps: Partial<ToastProps>
): Id => toast(<Msg {...myProps} />, { ...toastProps });

toaster.success = (myProps: MsgProps, toastProps: Partial<ToastProps>): Id =>
  toast.success(<Msg {...myProps} />, { ...toastProps });

toaster.error = (myProps: MsgProps, toastProps: Partial<ToastProps>): Id =>
  toast.error(<Msg {...myProps} />, { ...toastProps });

toaster.warn = (myProps: MsgProps, toastProps: Partial<ToastProps>): Id =>
  toast.warn(<Msg {...myProps} />, { ...toastProps });

toaster.dimiss = () => toast.dismiss();