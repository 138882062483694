import {
  BsqErrorLogParams,
  DataCalculateParams,
  IndividualThumbnailSchema,
  LoginRequestParams,
  LoginResponseSchema,
  SessionInfoSchema,
  SetUserDetailsParams,
  SetUserDetailsResponseSchema,
  SetUserPasswordParams,
  SetUserPasswordSchema,
} from './schema';
import { request } from './util';

interface BaseArgs {
  baseUrl: string;
  token: string | null;
}

// Endpoint: /login
export const login = async (params: LoginRequestParams, args: BaseArgs) => {
  const formData = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    formData.append(
      key,
      value as unknown as string
    );
  }

  const req = await request({
    path: '/login',
    opts: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    },
    baseUrl: args.baseUrl,
    token: null
  });

  const json = (await req.json()) as Record<string, unknown>;
  const result = LoginResponseSchema.parse(json);

  return result;
};

// Endpoint: /api/Evisense/getSessionInfo
export const fetchSessionInfo = async (args: BaseArgs) => {
  const req = await request({
    path: '/api/Evisense/getSessionInfo',
    ...args
  });

  const json = (await req.json()) as Record<string, unknown>;

  const result = SessionInfoSchema.parse(json);

  return result;
};

// Endpoint: /api/Evisense/bsqErrorLogging
export const bsqErrorLog = async (log: BsqErrorLogParams, args: BaseArgs) => {
  const req = await request({
    path: '/api/Evisense/bsqErrorLogging',
    opts: {
      method: 'POST',
      json: {
        ...log,
      },
    },
    isBearer: false,
    ...args
  });

  return req.status;
};

// Endpoint: /api/Account/isAlive
export const isAlive = async (args: BaseArgs): Promise<string> => {
  const req = await request({
    path: '/api/Account/isAlive',
    ...args
  });

  return req.json() as unknown as string;
};



// Endpoint: /api/Evisense/setUserPassword
export const setUserPassword = async (
  userParams: SetUserPasswordParams,
  args: BaseArgs
) => {
  const req = await request({
    path: '/api/Evisense/setUserPassword',
    opts: {
      method: 'POST',
      json: {
        ...userParams,
      },
    },
    ...args
  });

  const json = (await req.json()) as Record<string, unknown>;

  const result = SetUserPasswordSchema.parse(json);

  return result;
};

// Endpoint: /api/Evisense/setUserDetails
export const setUserDetails = async (
  userParams: SetUserDetailsParams,
  args: BaseArgs
) => {
  const req = await request({
    path: '/api/Evisense/setUserDetails',
    opts: {
      method: 'POST',
      json: { ...userParams },
    },
    ...args
  });

  const json = (await req.json()) as Record<string, unknown>;

  const result = SetUserDetailsResponseSchema.parse(json);

  return result;
};

// Endpoint: /api/Evisense/addUserPhoto
export const addUserPhoto = async (base64Photo: string, args: BaseArgs) => {
  await request({
    path: '/api/Evisense/addUserPhoto',
    opts: {
      method: 'POST',
      json: {
        base64Photo,
      },
    },
    ...args
  });
};

export const fetchIndividualThumbnail = async (
  individualUIDs: string[],
  args: BaseArgs
) => {
  const req = await request({
    path: '/api/Evisense/getIndividualThumbnail',
    opts: {
      method: 'POST',
      json: {
        individualUIDs,
      },
    },
    ...args
  });

  const json = (await req.json()) as Record<string, unknown>;

  const result = IndividualThumbnailSchema.parse(json);

  return result;
};

export const sendDataCalculateRequest = async(requestParams: DataCalculateParams, args: BaseArgs) => {
  const path = `/api/dataCalculate/${requestParams.IndividualGuid ? 'add' : 'addSchool'}`;

  const req = await request({
    path,
    opts: {
      method: 'POST',
      json: { ...requestParams },
    },
    ...args
  });

  return req.ok;
};

export const getSchoolStartDateFromCS = async (
  schoolUid: string,
  referenceDate: Date,
  args: BaseArgs,
) => {
  const req = await request({
    path: '/api/School/getSchoolStartDate',
    opts: {
      method: 'GET',
      query: {
        schoolUid,
        referenceDate: referenceDate.toLocaleDateString('en-US'),
      }
    },
    ...args,
  });

  if (req.ok) {
    const result = await req.json() as string | null;

    return result ? new Date(result) : null;
  }

  throw new Error(`Failed to fetch school start date: ${await req.text()}`);
};
