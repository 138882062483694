/* eslint-disable import/named */
import { CSSProperties, ReactNode, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { cache } from '../util/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ToastContainer } from 'react-toastify';
import { toaster } from '../util/toast';
import { clearAccessToken, getAccessToken } from './AuthContext';
import { useEnvVars } from '../hooks/useEnvVars';
import { omitUndefined } from '../util/deepNonNullable';
import jsCookie from 'js-cookie';
import { useLogError } from '../hooks/useLogError';
import { GraphQLFormattedError } from 'graphql';
import { getColor } from '../util/styles';

export const buildApolloClient = ({
  apiUrl,
  logError
}: {
  apiUrl: string;
  logError: (error: Error | GraphQLFormattedError) => void;
}) => {

  const httpLink = new BatchHttpLink({
    uri: `${apiUrl}/api/graphql`,
    batchInterval: 100,
  });

  const authLink = new ApolloLink((operation, forward) => {

    const accessToken = getAccessToken();
  
    operation.setContext({
      headers: omitUndefined({
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined
      }),
    });
  
    return forward(operation).map((response) => {
      const context = operation.getContext();
  
      try {
        // eslint-disable-next-line
        if (context.response.headers) {
          // eslint-disable-next-line
          const accessToken: string | undefined = context.response.headers.get('x-access-token');
          // eslint-disable-next-line
          const baseToken: string | undefined = context.response.headers.get(
            'x-base-token',
          );
          if (accessToken) {
            localStorage.setItem('x-access-token', accessToken);
          }
          if (baseToken) {
            jsCookie.set('x-base-token', baseToken);
          }
        }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        // do nothing
      }
  
      return response;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // Apollo Client will handle GraphQL errors in its default way
      // No need to provide custom logic here
      graphQLErrors.map((error) => {
        if (error.extensions?.statusCode === 401) {
          toaster.error(
            {
              title: 'Not Authenticated',
              text: error.message,
            },
            { autoClose: 2000 }
          );
  
          clearAccessToken();
  
          window.location.href = '/login';
        } else if (error.extensions?.statusCode !== 404) {
          toaster.error(
            {
              title: 'Error',
              text: error.message,
            },
            { autoClose: 2000 }
          );
          logError(error);
        }
      });
    }
    if (networkError) {
      // Handle network errors, including 401 and 403
      toaster.error(
        {
          title: 'Network Error',
          text: networkError.message,
        },
        { autoClose: 2000 }
      );
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache,
  });

  return apolloClient;

};

export function GqlProvider({ children }: { children: ReactNode }) {
  const envVars = useEnvVars();
  
  const logError = useLogError();
  
  const apolloClient = useMemo(() => buildApolloClient({ apiUrl: envVars.apiUrl, logError }), [envVars.apiUrl, logError]);

  return (
    <ApolloProvider client={apolloClient}>
      {children}
      <ToastContainer
          stacked
          newestOnTop
          style={
            {
              '--toastify-color-success': getColor('success'),
              '--toastify-color-error': getColor('error'),
              '--toastify-color-warning': getColor('warning'),
              '--toastify-toast-top': 0,
              '--toastify-toast-right': 'auto',
              '--toastify-toast-width': '100vw',
              '--toastify-toast-min-height': '2.5rem',
              padding: 0,
            } as CSSProperties
          }
          theme="colored"
        />
    </ApolloProvider>
  );
}

type TypenameType = Record<string, unknown> & { __typename?: string };

export function cleanTypeName<T extends TypenameType>(
  obj: T
): Omit<T, '__typename'> {
  const { __typename, ...newObj } = obj;

  return newObj;
}

type WithUuid = Record<string, unknown> & { uuid: string | null };

export function cleanUuid<T extends WithUuid>(obj: T): Omit<T, 'uuid'> {
  const { uuid, ...newObj } = obj;

  return newObj;
}
