export const BsquaredIcon = ({ size = 16 }: { size: number }) => (
  <svg
    width={size}
    height={0.5 * size}
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2.4186" height="2.4186" fill="#5A5A5B" />
    <rect x="2.69763" width="2.4186" height="2.4186" fill="#CDD3DD" />
    <rect y="2.79102" width="2.4186" height="2.4186" fill="#5A5A5B" />
    <rect
      x="2.69763"
      y="2.79102"
      width="2.4186"
      height="2.4186"
      fill="#5A5A5B"
    />
    <rect y="5.58105" width="2.4186" height="2.4186" fill="#5A5A5B" />
    <rect
      x="2.69763"
      y="5.58105"
      width="2.4186"
      height="2.4186"
      fill="#5A5A5B"
    />
    <rect
      x="5.39539"
      y="2.79102"
      width="2.4186"
      height="2.4186"
      fill="#5A5A5B"
    />
    <rect
      x="8.09302"
      y="2.79102"
      width="2.4186"
      height="2.4186"
      fill="#CDD3DD"
    />
    <rect
      x="10.7906"
      y="2.79102"
      width="2.4186"
      height="2.4186"
      fill="#CDD3DD"
    />
    <rect
      x="13.5814"
      y="2.79102"
      width="2.4186"
      height="2.4186"
      fill="#CDD3DD"
    />
    <rect
      x="5.39539"
      y="5.58105"
      width="2.4186"
      height="2.4186"
      fill="#CDD3DD"
    />
    <rect
      x="8.09302"
      y="5.58105"
      width="2.4186"
      height="2.4186"
      fill="#CDD3DD"
    />
  </svg>
);
