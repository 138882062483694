import { createFileRoute } from '@tanstack/react-router';
import { useUser } from '../../context/AuthContext';

export const Route = createFileRoute('/_authenticated/')({
  component: RouteComponent,
});

function RouteComponent() {
  const {user} = useUser();
  return (
    <div>
      <h1>Hey {user.firstName}</h1>
    </div>
  );
}
