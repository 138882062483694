import { CatchBoundary, createFileRoute, Outlet } from '@tanstack/react-router';
import { UserProvider } from '../context/AuthContext';
import Error404 from '../components/atoms/Page404';
import { useLogError } from '../hooks/useLogError';
import { SideNav } from '../components/organisms/SideNav';

export const Route = createFileRoute('/_authenticated')({
  component: RouteComponent,
});

function RouteComponent() {
  const logError = useLogError();
  return (
    <CatchBoundary
      onCatch={logError}
      getResetKey={() => 'unauthenticated'}
      errorComponent={() => <Error404 message='An error occurred' to="/" />}
    >
      <UserProvider>
        <div
          id="app"
          className="h-[100dvh] overflow-hidden flex flex-row flex-wrap"
        >
          <SideNav />
          <div className="w-[calc(100vw-5rem)] h-full flex grow relative">
            <Outlet />
          </div>
        </div>
      </UserProvider>
    </CatchBoundary>
  );
}
