/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UnauthenticatedImport } from './routes/_unauthenticated'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as UnauthenticatedLoginImport } from './routes/_unauthenticated/login'
import { Route as AuthenticatedStudentsIndexImport } from './routes/_authenticated/students/index'
import { Route as AuthenticatedConversationsIndexImport } from './routes/_authenticated/conversations/index'
import { Route as AuthenticatedStudentsAddImport } from './routes/_authenticated/students/add'
import { Route as AuthenticatedStudentsUuidImport } from './routes/_authenticated/students/$uuid'
import { Route as AuthenticatedConversationsUuidImport } from './routes/_authenticated/conversations/$uuid'

// Create/Update Routes

const UnauthenticatedRoute = UnauthenticatedImport.update({
  id: '/_unauthenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const UnauthenticatedLoginRoute = UnauthenticatedLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const AuthenticatedStudentsIndexRoute = AuthenticatedStudentsIndexImport.update(
  {
    id: '/students/',
    path: '/students/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedConversationsIndexRoute =
  AuthenticatedConversationsIndexImport.update({
    id: '/conversations/',
    path: '/conversations/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedStudentsAddRoute = AuthenticatedStudentsAddImport.update({
  id: '/students/add',
  path: '/students/add',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedStudentsUuidRoute = AuthenticatedStudentsUuidImport.update({
  id: '/students/$uuid',
  path: '/students/$uuid',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedConversationsUuidRoute =
  AuthenticatedConversationsUuidImport.update({
    id: '/conversations/$uuid',
    path: '/conversations/$uuid',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_unauthenticated': {
      id: '/_unauthenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UnauthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_unauthenticated/login': {
      id: '/_unauthenticated/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof UnauthenticatedLoginImport
      parentRoute: typeof UnauthenticatedImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/conversations/$uuid': {
      id: '/_authenticated/conversations/$uuid'
      path: '/conversations/$uuid'
      fullPath: '/conversations/$uuid'
      preLoaderRoute: typeof AuthenticatedConversationsUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/students/$uuid': {
      id: '/_authenticated/students/$uuid'
      path: '/students/$uuid'
      fullPath: '/students/$uuid'
      preLoaderRoute: typeof AuthenticatedStudentsUuidImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/students/add': {
      id: '/_authenticated/students/add'
      path: '/students/add'
      fullPath: '/students/add'
      preLoaderRoute: typeof AuthenticatedStudentsAddImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/conversations/': {
      id: '/_authenticated/conversations/'
      path: '/conversations'
      fullPath: '/conversations'
      preLoaderRoute: typeof AuthenticatedConversationsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/students/': {
      id: '/_authenticated/students/'
      path: '/students'
      fullPath: '/students'
      preLoaderRoute: typeof AuthenticatedStudentsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedConversationsUuidRoute: typeof AuthenticatedConversationsUuidRoute
  AuthenticatedStudentsUuidRoute: typeof AuthenticatedStudentsUuidRoute
  AuthenticatedStudentsAddRoute: typeof AuthenticatedStudentsAddRoute
  AuthenticatedConversationsIndexRoute: typeof AuthenticatedConversationsIndexRoute
  AuthenticatedStudentsIndexRoute: typeof AuthenticatedStudentsIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedConversationsUuidRoute: AuthenticatedConversationsUuidRoute,
  AuthenticatedStudentsUuidRoute: AuthenticatedStudentsUuidRoute,
  AuthenticatedStudentsAddRoute: AuthenticatedStudentsAddRoute,
  AuthenticatedConversationsIndexRoute: AuthenticatedConversationsIndexRoute,
  AuthenticatedStudentsIndexRoute: AuthenticatedStudentsIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface UnauthenticatedRouteChildren {
  UnauthenticatedLoginRoute: typeof UnauthenticatedLoginRoute
}

const UnauthenticatedRouteChildren: UnauthenticatedRouteChildren = {
  UnauthenticatedLoginRoute: UnauthenticatedLoginRoute,
}

const UnauthenticatedRouteWithChildren = UnauthenticatedRoute._addFileChildren(
  UnauthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof UnauthenticatedRouteWithChildren
  '/login': typeof UnauthenticatedLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/conversations/$uuid': typeof AuthenticatedConversationsUuidRoute
  '/students/$uuid': typeof AuthenticatedStudentsUuidRoute
  '/students/add': typeof AuthenticatedStudentsAddRoute
  '/conversations': typeof AuthenticatedConversationsIndexRoute
  '/students': typeof AuthenticatedStudentsIndexRoute
}

export interface FileRoutesByTo {
  '': typeof UnauthenticatedRouteWithChildren
  '/login': typeof UnauthenticatedLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/conversations/$uuid': typeof AuthenticatedConversationsUuidRoute
  '/students/$uuid': typeof AuthenticatedStudentsUuidRoute
  '/students/add': typeof AuthenticatedStudentsAddRoute
  '/conversations': typeof AuthenticatedConversationsIndexRoute
  '/students': typeof AuthenticatedStudentsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_unauthenticated': typeof UnauthenticatedRouteWithChildren
  '/_unauthenticated/login': typeof UnauthenticatedLoginRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/conversations/$uuid': typeof AuthenticatedConversationsUuidRoute
  '/_authenticated/students/$uuid': typeof AuthenticatedStudentsUuidRoute
  '/_authenticated/students/add': typeof AuthenticatedStudentsAddRoute
  '/_authenticated/conversations/': typeof AuthenticatedConversationsIndexRoute
  '/_authenticated/students/': typeof AuthenticatedStudentsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/'
    | '/conversations/$uuid'
    | '/students/$uuid'
    | '/students/add'
    | '/conversations'
    | '/students'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/login'
    | '/'
    | '/conversations/$uuid'
    | '/students/$uuid'
    | '/students/add'
    | '/conversations'
    | '/students'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_unauthenticated'
    | '/_unauthenticated/login'
    | '/_authenticated/'
    | '/_authenticated/conversations/$uuid'
    | '/_authenticated/students/$uuid'
    | '/_authenticated/students/add'
    | '/_authenticated/conversations/'
    | '/_authenticated/students/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  UnauthenticatedRoute: typeof UnauthenticatedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  UnauthenticatedRoute: UnauthenticatedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_unauthenticated"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/",
        "/_authenticated/conversations/$uuid",
        "/_authenticated/students/$uuid",
        "/_authenticated/students/add",
        "/_authenticated/conversations/",
        "/_authenticated/students/"
      ]
    },
    "/_unauthenticated": {
      "filePath": "_unauthenticated.tsx",
      "children": [
        "/_unauthenticated/login"
      ]
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login.tsx",
      "parent": "/_unauthenticated"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/conversations/$uuid": {
      "filePath": "_authenticated/conversations/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/students/$uuid": {
      "filePath": "_authenticated/students/$uuid.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/students/add": {
      "filePath": "_authenticated/students/add.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/conversations/": {
      "filePath": "_authenticated/conversations/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/students/": {
      "filePath": "_authenticated/students/index.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
