import React, {
  ChangeEvent,
  FormEventHandler,
  useState,
} from 'react';
import { Modal } from '../../../../components/molecules/Modal';
import { EmailInput } from '../EmailInput';
import { PasswordInput } from '../../../../components/molecules/PasswordInput';
import { SecurityInformation } from '../SecurityInformation';
import { useNavigate } from '@tanstack/react-router';
import { useMutation } from '@apollo/client';
import { GqlLoginMutation } from '@board/resources';
import { writeAccessToken } from '../../../../context/AuthContext';
import jsCookie from 'js-cookie';
import { toaster } from '../../../../util/toast';
import { Button } from '@headlessui/react';
import { elements } from '../../../../util/styles';
import classNames from 'classnames';

// TODO: Implement Formik like we have done on login page on Analytics project
export const Form = ({
  loginErrorUrl,
  softwareId,
}: {
  loginErrorUrl: string;
  softwareId: number;
}) => {
  // Form State
  const [formState, updateFormState] = useState({
    errorMessage: '',
    emailAddress: '',
    password: '',
    errorUrl: '',
    displayModal: false,
    isLoading: false,
  });

  const navigate = useNavigate();
  const [login] = useMutation(GqlLoginMutation, {
    onCompleted: (data) => {
      const {
        accessToken,
        baseToken,
        isForcePasswordChange,
        dueToPolicyChange,
      } = data.login;

      jsCookie.set('x-base-token', baseToken);
      writeAccessToken(accessToken);

      toaster.success(
        {
          title: 'Welcome Back!',
          text: 'You have successfully logged in.',
        },
        { autoClose: 5000 }
      );

      if (isForcePasswordChange) {
        localStorage.setItem('force-password-change', JSON.stringify({
          dueToPolicy: dueToPolicyChange,
        }));
      }

      updateFormState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));

      setTimeout(() => void navigate({
        to: '/'
      }), 5000);
      
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onError: (e) => updateFormState((prevState) => ({
      ...prevState,
      errorMessage: e.message,
      errorUrl: loginErrorUrl,
      isLoading: false,
    }))
  });

  // Form submission handler
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const userDetails = {
      username: formState.emailAddress.toLowerCase(),
      password: formState.password,
    };
    updateFormState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    void login({
      variables: {
        input: {
          email: userDetails.username,
          password: userDetails.password,
          programMode: softwareId,
        },
      },
    });
  };

  // Handle change method to update form state with values added by the user
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateFormState((prevState) => ({
      ...prevState,
      errorMessage: '',
      [name]: value,
    }));
  };

  const {
    emailAddress,
    errorMessage,
    errorUrl,
    password,
    displayModal,
    isLoading,
  } = formState;

  return (
    <>
      <div className="box-container max-w-[20rem] w-full">
        <h1 className="title my-0 mr-0 mb-[0.8125rem] leading-9">Sign in</h1>

        <form className="form" onSubmit={handleSubmit} method="post">
          {!!errorMessage && (
            <p className="error text-error text-[0.8125rem]">
              <span>
                {errorMessage}{' '}
                {errorUrl && (
                  <a
                    className="text-error text-[0.8125rem] font-bold"
                    href={errorUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {errorUrl}
                  </a>
                )}
              </span>
            </p>
          )}

          <fieldset className="border-none p-0 mb-[1.625rem]">
            <EmailInput
              id="signInEmail"
              label="E-mail"
              name="emailAddress"
              onChange={handleChange}
              placeholder="Please enter your e-mail address"
              required={true}
              value={emailAddress}
            />

            <PasswordInput
              id="signInPassword"
              label="Password"
              name="password"
              onChange={handleChange}
              required={true}
              value={password}
              forgottenLink='/forgotpassword'
            />
          </fieldset>

          <Button
            type="submit"
            disabled={isLoading}
            className={classNames(elements.button.primary, 'w-full')}
          >
            Sign in
          </Button>
        </form>
      </div>

      <Modal
        title="Sign in security"
        positiveButton="Ok"
        onClose={() =>
          updateFormState((prevState) => ({
            ...prevState,
            displayModal: false,
          }))
        }
        mounted={displayModal}
      >
        <SecurityInformation />
      </Modal>
    </>
  );
};
