import { useState, useCallback, useEffect } from 'react';

interface Props {
  blurSize?: number;
  blurDegree?: number;
}

export const Cursor = ({ blurSize = 16, blurDegree = 1 }: Props) => {
  const [state, updateState] = useState({
    x: 0,
    y: 0,
    display: false,
  });
  const setState = useCallback(
    (props: typeof state) => updateState({ ...state, ...props }),
    [state]
  );
  const handleMouseMove = useCallback<EventListener>(
    (event) => {
      const x =
        (
          event as unknown as {
            clientX: number;
          }
        ).clientX -
        blurSize / 2;
      const y =
        (
          event as unknown as {
            clientY: number;
          }
        ).clientY -
        blurSize / 2;
      const display = !!document.querySelector('.cursorable:hover');
      setState({ x, y, display });
    },
    [blurSize, setState]
  );
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  });
  const { x, y, display } = state;
  return !!x && !!y && !!display ? (
    <div
      className="cursor fixed pointer-events-none z-[6] border border-white rounded-full overflow-hidden"
      style={{ top: y, left: x, width: blurSize, height: blurSize }}
    >
      <span
        className="block w-full h-full"
        style={{
          backdropFilter: `blur(${(blurDegree * 5) / 100}px)`,
        }}
      />
    </div>
  ) : null;
};
