import { gql } from "@board/graphql";

export const GqlMessage = gql(`
    fragment MessageFragment on Message {
        uuid
        text
        role
        toolCallId
        toolCalls
        conversationUuid
        createdAt
    }
`);

export const GqlNextAction = gql(`
    fragment ConversationActionFragment on ConversationAction {
        label
        properties {
            key
            type
            required
        }
    }
`);

export const GqlConversation = gql(`
    fragment ConversationFragment on Conversation {
        uuid
        title
        createdAt
        updatedAt
        deletedAt
        messages {
            ...MessageFragment
        }
    }
`);


export const GqlIndexConversations = gql(`
    query IndexConversations($input: ConversationFilterInput) {
        indexConversations(input: $input) {
            pagination {
                ...PaginationFragment
            }
            items {
                ...ConversationFragment
            }
        }
    }
`);

export const GqlFindConversation = gql(`
    query FindConversation($uuid: ID!) {
        findConversation(uuid: $uuid) {
            ...ConversationFragment
        }
    }
`);

export const GqlSendMessage = gql(`
    mutation SendMessage($input: SendMessageInput!) {
        sendMessage(input: $input) {
            ...ConversationFragment
        }
    }
`);

export const GqlEditConversation = gql(`
    mutation EditConversation($input: EditConversationInput!) {
        editConversation(input: $input) {
            ...ConversationFragment
        }
    }
`);

export const GqlDeleteConversation = gql(`
    mutation DeleteConversation($uuid: ID!) {
        deleteConversation(uuid: $uuid)
    }
`); 

export const GqlGetConversationActions = gql(`
    query GetConversationActions($conversationUuid: ID!, $messageId: ID) {
        getConversationActions(conversationUuid: $conversationUuid, messageId: $messageId) {
            ...ConversationActionFragment
        }
    }
`);