import { gql } from '@board/graphql';


export const GqlUser = gql(`
  fragment User on User {
    uuid
    schoolUuid
    emailAddress
    firstName
    lastName
    schoolName
    roleUuid
    roleName
    title
  }
`);

export const GqlLoginMutation = gql(`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      baseToken
      refreshToken
      isForcePasswordChange
      dueToPolicyChange
    }
  }
`);


export const GqlLogErrorMutation = gql(`
  mutation LogError($input: LogErrorInput!) {
    logError(input: $input)
  }
`);

export const GqlMeQuery = gql(`
  query Me {
    me {
      ...User
    }
  }
`);
